import * as React from "react";
import {observer} from "mobx-react";
import {autorun} from "mobx";
import SendmailDialog from "../emailing/SendmailDialog";

@observer
export default class DocumentGenerationSendmailHandler extends React.Component {

    constructor(props) {
        super(props);
        autorun(async() => {
            let mailEditStore = this.props.mailEditStore;
            let bookingId = mailEditStore.getValue("bookingId");
            let documentGeneratorId = mailEditStore.getValue("documentGeneratorId");

            if(bookingId != null && documentGeneratorId != null) {
                let promise = await fetch(
                    "generation/?bookingId="+bookingId+"&documentGeneratorId="+documentGeneratorId
                );
                let response = await promise.json();

                let {subject, body} = response;

                mailEditStore.modifyField("mailContent", body);
                mailEditStore.modifyField("subject", subject);

                let documentGenerator = this.props.stores.documentGenerationStore.getModelById(documentGeneratorId);
                let booking = this.props.stores.bookingsStore.getModelById(bookingId);

                //Take adress named in document generator. If null, take adress of tenant
                let recipientAdress = documentGenerator.attributes.mailRecipient != null ? documentGenerator.attributes.mailRecipient : booking.attributes.email;
                mailEditStore.modifyField("adress", recipientAdress);

                let apartmentId = this.props.editStore.getValue("apartmentFK");
                const apartment = this.props.stores.apartmentsStore.getModelById(apartmentId);
                let mailAccountId = apartment.attributes.mailAccountFK;
                mailEditStore.modifyField("mailAccountId", mailAccountId);
            }
        });
    }


    render() {
        return (
            <SendmailDialog
                stores={this.props.stores}
                editStore={this.props.mailEditStore}
            />
        );
    }
}