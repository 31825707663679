import * as React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

const styles = {
    root: {
        backgroundImage: (props) => props.blocked ? "repeating-linear-gradient(45deg, #e8e8e8 0px, #e8e8e8 10px, transparent 10px, transparent 20px, #e8e8e8 20px)" : null
    },
    disabled: {
        color: (props) => props.error ? "inherit" : "black"
    },
    notchedOutline: {
        borderColor: (props) => props.edit && !props.permanentInputMode ? "green" : ""
    }
};

class TextFieldControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            inFocus: false
        };

        this.inputRef = React.createRef();

        this.applyChange = this.applyChange.bind(this);
        this.onInteraction = this.onInteraction.bind(this);
    }

    componentDidMount() {
        let value = this.props.value;

        if(this.props.initialRegisterDefaultValue) {
            value = this.props.initialValue;
            this.props.onValueChange(value);
        }

        if(typeof this.props.inputFormatter === "function")
            value = this.props.inputFormatter(value);

        if(value === null || value === undefined)
            value = "";

        this.setState({value: value});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value !== prevProps.value) {
            if(!this.props.applyOnBlur || !this.state.inFocus) {
                let value = this.props.value;

                if(typeof this.props.inputFormatter === "function")
                    value = this.props.inputFormatter(value);

                if(value == null)
                    value = "";

                this.setState({
                    value: value
                });
            }
        }

        if(this.props.edit !== prevProps.edit)
            this.inputRef.current.disabled = !this.props.edit;
    }

    applyChange(value, blurEvent) {
        let outputValue = value;
        if(typeof this.props.outputFormatter === "function")
            outputValue = this.props.outputFormatter(value);

        let save = this.props.applyOnBlur && blurEvent || !this.props.applyOnBlur;

        let formattedValue;
        if(save)
            formattedValue = typeof this.props.inputFormatter === "function" ? this.props.inputFormatter(outputValue) : outputValue;

        let stateValue = save ? formattedValue : value;
        this.setState({value: stateValue});

        if(typeof this.props.onValueChange === "function" && save)
            this.props.onValueChange(outputValue);
    }

    onInteraction() {
        let interactionCallback = this.props.onInteraction;
        if(typeof interactionCallback === "function")
            interactionCallback();
    }

    render() {
        const { classes } = this.props;
        let inEdit = this.props.edit;
        return (
            <form onSubmit={(event) => {
                event.preventDefault();
            }} >
                <Tooltip title={this.props.tooltip || ""}>
                    <TextField
                        label={this.props.title}
                        margin="normal"
                        variant="outlined"
                        value={this.state.value}
                        onChange={(event) => {
                            this.applyChange(event.target.value, false);
                        }}
                        onFocus={() => this.setState({inFocus: true})}
                        onBlur={(event) => {
                            this.applyChange(event.target.value, true);
                            this.onInteraction();
                            this.setState({
                                inFocus: false
                            });
                        }}
                        InputProps={{
                            readOnly: !inEdit,
                            endAdornment: this.props.adornment ? <InputAdornment position="end">{this.props.adornment}</InputAdornment> : null,
                            startAdornment: this.props.startAdornment ? <InputAdornment position="start">{this.props.startAdornment}</InputAdornment> : null,
                            classes: {
                                root: classes.root,
                                disabled: classes.disabled,
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                        fullWidth
                        inputRef={this.inputRef}
                        error={this.props.error}
                        helperText={this.props.helperText}
                        multiline={this.props.multiline}
                        minRows={this.props.multiline ? 2 : 1}
                        maxRows={this.props.multiline ? Infinity : 1}
                    />
                </Tooltip>
            </form>
        );
    }

}

export default withStyles(styles)(TextFieldControl);