import {observer} from "mobx-react";
import React from "react";
import Grid from "@material-ui/core/Grid";
import BlockageValidationObject from "../blockageDetailsPage/BlockageValidationObject";
import {BlockageDetailsCard} from "../blockageDetailsPage/BlockageDetailsPage";
import RESTCreateMemorizer from "../memorizer/RESTCreateMemorizer";
import BlockageAutofillObject from "../autofillObjects/BlockageAutofillObject";
import {gotoPage} from "../reactUtils";

@observer
export default class BlockageCreationSheet extends React.Component {

    constructor(props) {
        super(props);
        props.editStore.reset();
        props.editStore.memorizer = new RESTCreateMemorizer("blockage", blockage => gotoPage("/#/blockage/" + blockage.attributes.id));
        props.editStore.validatorObject = new BlockageValidationObject(this.props.editStore);
        props.editStore.autofillObject = new BlockageAutofillObject(this.props.editStore, this.props.stores);
        props.editStore.simpleCollectionStore = this.props.stores.blockagesStore;
        props.editStore.modelId = -1;
        props.editStore.startEditing();
    }

    render() {
        let editStore = this.props.editStore;
        let stores = this.props.stores;
        const formEditFieldsBasicProps = {
            stores: stores,
            editStore: editStore,
            tableName: "blockages",
            permanentInputMode: true,
            errorOnlyAfterInteraction: true
        };

        return (
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <BlockageDetailsCard {...formEditFieldsBasicProps} />
                </Grid>
            </Grid>
        );
    }
}