import moment from "moment";
import {autorun} from "mobx";
import AutofillObject from "./AutofillObject";

export default class BuchungAutofillObject extends AutofillObject{

    getConstant(constantName) {
        let apartmentId = this.editStore.getValue("apartmentFK");
        let configStore = this.stores.apartmentConfigsStore;
        return configStore.getConfigValue(apartmentId, constantName);
    }

    doAutofill(modifiedField, constantsUpdated = false) {
        //Anzahlung
        if(modifiedField === "mietpreis" || modifiedField === "sofortigeRestzahlung" || constantsUpdated) {
            if(this.getConstant("prozentAnzahlung") != null) {
                let mietpreis = this.getValue("mietpreis");
                let sofortigeRestzahlung = this.getValue("sofortigeRestzahlung");
                let anzahlung = sofortigeRestzahlung ? 0 : this.getConstant("prozentAnzahlung") * mietpreis;
                if(!isNaN(anzahlung))
                    this.setValue("anzahlung", anzahlung);
            }
        }

        //Touristensteuer
        if(modifiedField === "anreise" || modifiedField === "abreise" || modifiedField === "anzahlPersonen" || constantsUpdated) {
            if(this.getConstant("touristensteuerProPersonProNacht") != null) {
                let anreise = moment(this.getValue("anreise"));
                let abreise = moment(this.getValue("abreise"));
                let anzahlPersonen = this.getValue("anzahlPersonen");
                if (anreise.isValid() && abreise.isValid() && anzahlPersonen >= 0) {
                    let touristensteuer = this.getConstant("touristensteuerProPersonProNacht") * abreise.diff(anreise, "days") * anzahlPersonen;
                    if(!isNaN(touristensteuer))
                        this.setValue("touristensteuer", touristensteuer);
                }
            }
        }

        //Provision
        //Code für anteilige Provision
        /*if(modifiedField === "mietpreis" || constantsUpdated) {
            if(this.getConstant("provisionVerwalter") != null) {
                let kaution = this.getConstant("provisionVerwalter") * this.getValue("mietpreis");
                if(!isNaN(kaution))
                    this.setValue("provision", kaution);
            } else {
                this.setValue("provision", null);
            }
        }*/

        //Sofortige Restzahlung
        if(modifiedField === "anreise") {
            let anreise = moment(this.getValue("anreise"));
            let today = moment().startOf("day");
            this.setValue("sofortigeRestzahlung", anreise.diff(today, "weeks") <= 6);
        }

        //Abreise
        if(modifiedField === "anreise") {
            if(this.getValue("abreise") == null) {
                let anreise = this.getValue("anreise");
                let anreiseMoment = anreise !== null ? moment(anreise) : null;
                if(anreiseMoment !== null)
                    this.setValue("abreise", anreiseMoment.format("YYYY-MM-DD"));
            }
        }

        //extendByOneDayForCleaning
        if(modifiedField === "abreise") {
            const abreise = moment(this.getValue("abreise"));
            const weekday = abreise.weekday();
            const sunday = 6;
            this.setValue("extendOneDayForCleaning", weekday === sunday);
        }

        //Apartment
        if(modifiedField === "apartmentFK") {
            let simpleCopyConstants= [["endreinigung", "endreinigung"], ["kaution", "kaution"], ["provision", "provisionVerwalter"]];
            simpleCopyConstants.forEach(element => this.setValue(element[0], this.getConstant(element[1])));
            this.doAutofill(null, true);
        }

    }

}