import React from "react";
import SelectEditControl from "./baseControls/SelectEditControl";
import {observer} from "mobx-react";

@observer
export default class GenderSelectionControl extends React.Component {
    render() {
        let selectableValues = [
            ["Herr", "Herr"],
            ["Frau", "Frau"]
        ];
        return (
            <SelectEditControl {...this.props} selectableValues={selectableValues} />
        );
    }
}