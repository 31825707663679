export function arrayRemove(array, pred) {
    let findRes = array.find(elem => pred(elem));
    let index = array.indexOf(findRes);
    if(index >= 0)
        array = array.splice(index, 1);
}

export function argMax(array) {
    return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

export function argMaxPredicate(array, predicate) {
    const predArr = array.map(elem => predicate(elem));
    const maxIndex = argMax(predArr);
    return array[maxIndex];
}