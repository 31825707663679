import React from "react";

export default class ControlButtonPair extends React.Component {
    render() {
        return (
            <div>
                <div style={{ display: 'inline-flex', width: "100%" }}>
                    <div style={{width: "100%"}}>
                        {this.props.control}
                    </div>
                    <div style={{ alignSelf: 'center', float: "right", width: "auto", marginLeft: "5px" }}>
                        {this.props.button}
                    </div>
                </div>
            </div>
        );
    }
}