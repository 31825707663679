import {computed, observable, toJS} from "mobx";
import store from "./store";

export default class StoresManager {
    _stores = {};

    constructor() {
        this.registerStore("manager", this);
    }

    registerStore(storeName, store) {
        if(!this._stores[storeName])
            this._stores[storeName] = store;
    }

    unregisterStore(storeName) {
        if(this._stores[storeName])
            delete this._stores[storeName];
    }

   reloadStores(storeNames = null) {
        if(storeNames === null)
            storeNames = Object.keys(this._stores);
        storeNames.forEach(storeName => {
            const store = storeName !== "manager" ? this.stores[storeName] : null;
            if(store) {
                console.log(store);
                store.reload();
            }

        });
    }

    get stores() {
        return this._stores;
    }

}