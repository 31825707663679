import React from "react";
import {formatEuroValue, reactBackslashNToLineBreak} from "../reactUtils";
import {
    getAbreiseMoment,
    getAnreiseMoment,
    getAnzahlTage,
    getAnzahlungBisMoment,
    getKommentar,
    getMietpreis,
    getRestzahlungBisMoment,
    getZahlungsstatusText,
    isAnzahlungErhalten,
    isRestzahlungErhalten
} from "../buchungUtils";
import BuchungOverviewCard, {createData, getFristRowString} from "./buchungOverviewCard";
import {observer} from "mobx-react";
import moment from "moment";
import QuickActionMenu, {createQuickAction} from "../detailsPage/QuickActionMenu";
import EditStore from "../store/EditStore";
import BookingMemorizer from "../memorizer/BookingMemorizer";
import BuchungValidationObject from "../validationObjects/buchungValidationObject";

@observer
export default class ActiveBuchungOverviewCard extends React.Component {

    constructor(props) {
        super(props);

        this.editStore = new EditStore();
        this.editStore.simpleCollectionStore = props.stores.bookingsStore;
        this.editStore.memorizer = new BookingMemorizer();

        this.getQuickActions = this.getQuickActions.bind(this);
    }

    getQuickActions() {
        let booking = this.props.buchung;
        let actions = [];
        if(!booking.attributes.anzahlungErhalten)
            actions.push(createQuickAction("Anzahlung ist bezahlt", editStore => editStore.modifyField("anzahlungErhalten", true)));
        if(!booking.attributes.restzahlungErhalten)
            actions.push(createQuickAction("Restzahlung ist bezahlt", editStore => editStore.modifyField("restzahlungErhalten", true)));
        if(!booking.attributes.verwalterBestaetigung)
            actions.push(createQuickAction("Verwalter ist informiert", editStore => editStore.modifyField("verwalterBestaetigung", true)));
        const today = moment().startOf("day");
        if(moment(booking.attributes.abreise).isBefore(today) && !booking.attributes.verwalterBezahlt)
            actions.push(createQuickAction("Verwalter ist bezahlt", editStore => editStore.modifyField("verwalterBezahlt", true)));
        return actions;
    }

    render() {
        let buchung = this.props.buchung;
        let buchungAttr = buchung.attributes;

        let apartmentsStore = this.props.stores.apartmentsStore;
        let apartment = apartmentsStore.allItems.find(apartment => apartment.attributes.id === buchungAttr.apartmentFK);
        let apartmentName = apartment != null ? apartment.attributes.name : "Wird geladen...";

        const dataRows = [
            createData(
                "Ferienwohnung",
                apartmentName,
                true
            ),
            createData(
                "Reisezeitraum",
                getAnreiseMoment(buchung).format("L") + " - " + getAbreiseMoment(buchung).format("L") + " (" + getAnzahlTage(buchung) + " Tage)",
                true
            ),
            createData(
                "Mietpreis",
                formatEuroValue(getMietpreis(buchung)),
                true
            ),
            createData(
                "Anzahlung",
                formatEuroValue(buchung.attributes.anzahlung),
                !buchung.attributes.anzahlungErhalten || !buchung.attributes.restzahlungErhalten
            ),
            createData(
                "Restzahlung",
                formatEuroValue(buchung.attributes.finalPaymentTotal),
                !buchung.attributes.anzahlungErhalten || !buchung.attributes.restzahlungErhalten
            ),
            createData(
                "Gesamtpreis",
                formatEuroValue(buchung.attributes.totalPriceInclDeposit),
                !buchung.attributes.anzahlungErhalten || !buchung.attributes.restzahlungErhalten
            ),
            createData(
                "Anzahlungsfrist",
                getFristRowString(getAnzahlungBisMoment(buchung)),
                !isAnzahlungErhalten(buchung)
            ),
            createData(
                "Restzahlungsfrist",
                getFristRowString(getRestzahlungBisMoment(buchung)),
                !isRestzahlungErhalten(buchung)
            ),
            createData(
                "Bezahlung",
                getZahlungsstatusText(buchung),
                true
            ),
            createData(
                "Kaution zu erstatten bis",
                getFristRowString(getAbreiseMoment(buchung).add(2, "week")),
                getAbreiseMoment(buchung).isBefore(moment().startOf("day")) && !buchung.attributes.kautionErstattet
            ),
            createData(
                "Sonderwünsche Verwalter",
                reactBackslashNToLineBreak(buchung.attributes.custodianSpecialRequest),
                buchung.attributes.custodianSpecialRequest !== "" && getAbreiseMoment(buchung).isAfter(moment().startOf("day"))
            ),
            createData(
              "Anzahl Personen",
              buchung.attributes.anzahlPersonen,
                moment().startOf("day") > getAnreiseMoment(buchung).endOf("year")
            ),
            createData(
                "Verwalter bezahlt",
                buchung.attributes.verwalterBezahlt ? "ja" : "nein",
                moment().startOf("day") > getAbreiseMoment(buchung) && !buchung.attributes.verwalterBezahlt
            ),
            createData(
                "Kommentar",
                reactBackslashNToLineBreak(getKommentar(buchung)),
                getKommentar(buchung) !== ""
            )
        ];

        return (
            <BuchungOverviewCard
                background="#FAFAFA"
                buchung={this.props.buchung}
                dataRows={dataRows}
                actions={<QuickActionMenu stores={this.props.stores} booking={buchung} items={this.getQuickActions()} editStore={this.editStore}/>}
                {...this.props}
            />
        );
    }
}