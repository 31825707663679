import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import EditStore from "../store/EditStore";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class QuickActionMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            dialogOpen: false,
            dialogConfirmAction: () => null,
            dialogActionTitle: ""
        };

        this.onButtonClick = this.onButtonClick.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onButtonClick(event) {
        let items = this.props.items || [];
        let noActions = items.length <= 0;
        if(noActions)
            return;

        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    }

    onClose(event) {
        this.setState({
            open: false
        });
    }

    onAction(object) {
        let editStore = this.props.editStore;
        this.setState({
            dialogOpen: true,
            dialogActionTitle: object.title,
            dialogConfirmAction: function (editStore, booking, closeCallback) {
                return () => {
                    editStore.modelId = booking.attributes.id;
                    editStore.startEditing();
                    object.action(editStore);
                    editStore.stopEditingAndSave();
                    closeCallback();
                };
            }(editStore, this.props.booking, this.onClose)
        });
    }

    render() {
        let items = this.props.items || [];
        let noActions = items.length <= 0;
        return (
            <React.Fragment>
                <IconButton
                    onClick={this.onButtonClick}
                    >
                    <Tooltip title={noActions ? "Keine Schnellaktionen verfügbar" : "Schnellaktionen"}>
                        <Icon>{noActions ? "done" : "flash_on"}</Icon>
                    </Tooltip>
                </IconButton>
                <Menu
                    keepMounted
                    open={this.state.open}
                    onClose={this.onClose}
                    anchorEl={this.state.anchorEl}
                >
                    {
                        items.map(item => <MenuItem key={item.title} onClick={() => this.onAction(item)}>{item.title}</MenuItem>)
                    }
                </Menu>
                <ConfirmationDialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({dialogOpen: false, open: false})}
                    onConfirm={() => this.state.dialogConfirmAction()}
                    quickActionTitle={this.state.dialogActionTitle}
                />
            </React.Fragment>
        );
    }
}

class ConfirmationDialog extends React.Component {
    render() {
        let open = this.props.open;
        let onClose = this.props.onClose;
        let onConfirm = this.props.onConfirm;
        let quickActionTitle = this.props.quickActionTitle;
        return (
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>{"Schnellaktion bestätigen"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Bitte bestätige diese Schnellaktion:  {quickActionTitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={() => {onConfirm(); onClose();}} color="primary" autoFocus>
                        Aktion durchführen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export function createQuickAction(title, action) {
    let obj = {
        title,
        action
    };
    return obj;
}