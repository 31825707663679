import React from "react";
import {observer} from "mobx-react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";

const switchStyles = {

};

@observer
class SwitchControl extends React.Component{

    constructor(props) {
        super(props);
        this.onInteraction = this.onInteraction.bind(this);
    }

    componentDidMount() {
        let value = this.props.value;
        if(this.props.initialRegisterDefaultValue)
            this.props.onValueChange(false);
    }

    onInteraction() {
        let interactionCallback = this.props.onInteraction;
        if(typeof interactionCallback === "function")
            interactionCallback();
    }

    render() {
        let {classes} = this.props;
        let value = this.props.value == null ? false : this.props.value;

        let inEdit = this.props.edit;

        return (
            <Tooltip title={this.props.tooltip || ""}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={value}
                            onChange={(event) => {
                                if(typeof this.props.onValueChange === "function")
                                    this.props.onValueChange(event.target.checked);
                                this.onInteraction();
                            }}
                            inputProps={{
                                readOnly: !inEdit,
                                classes: {
                                    disabled: classes.disabled
                                }
                            }}
                            color={this.props.color ?? "primary"}
                        />
                    }
                    style={{
                        height: this.props.height
                    }}
                    label={this.props.title}
                />
            </Tooltip>
        );
    }
}

export default withStyles(switchStyles)(SwitchControl);