import React from "react";
import SelectEditControl from "./baseControls/SelectEditControl";
import {observer} from "mobx-react";
import {compareAlphabetically} from "../JSStringUtils";

@observer
export default class SeasonsSelectionControl extends React.Component {
    render() {
        let stores = this.props.stores;
        let bookingsStore = stores.bookingsStore;
        let seasons = bookingsStore.allSeasons;
        let selectableValues = [];
        seasons.forEach(season => selectableValues.push([season, season]));
        selectableValues.sort((a,b) => a-b);
        return (
            <SelectEditControl {...this.props} multiple selectableValues={selectableValues} />
        );
    }
}