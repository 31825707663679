import * as React from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

export default class ToolbarRefreshButton extends React.Component {
    render() {
        let stores = this.props.stores;
        let manager = stores.manager;
        const storesToRefresh = this.props.storesToRefresh ?? null;
        return (
            <IconButton
                onClick={() => {
                    manager.reloadStores(storesToRefresh);
                }}
                style={{color: "white"}}
            >
                <Icon>refresh</Icon>
            </IconButton>
        );
    }
}