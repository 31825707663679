import {initialStoreObject} from "./store";
import {computed} from "mobx";
import {getAnreiseMoment} from "../buchungUtils";
import SimpleCollectionStore from "./SimpleCollectionStore";

export default class BookingsStore extends SimpleCollectionStore {

    init() {
        this.data = initialStoreObject;
    }

    refetchById(id, callback) {
        this.refetchByIdBase(
            this.data,
            "db/providers/currentUserBuchungenProvider.php",
            id,
            {},
            callback
        );
    }

    doFetch(parameters, afterLoadCallback) {
        super.doFetch();
        this.fetch(
            this.data,
            "db/providers/currentUserBuchungenProvider.php",
            {
                seasonYear: "all",
                apartmentFK: "all"
            },
            afterLoadCallback
        );
    }

    @computed get allSeasons() {
        let seasons = new Set();
        this.allItems.forEach(element => seasons.add(getAnreiseMoment(element).year()));
        return Array.from(seasons);
    }

}