import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {observer} from "mobx-react";
import EditStoreInputField from "../form/EditStoreInputField";
import CodeEditControl from "../form/baseControls/CodeEditControl";
import TextEditControl from "../form/baseControls/TextEditControl";
import EditStoreStaticField from "../form/EditStoreStaticField";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";
import EditStoreModelEditDialog from "../form/EditStoreModelEditDialog";
import CheckboxControl from "../form/baseControls/CheckboxControl";
import EditStoreModelCreationDialog from "../form/EditStoreModelCreationDialog";

class Fields extends React.Component {
    render() {
        let editStoreInputFieldProps = this.props.editStoreInputFieldProps;
        return (
            <React.Fragment>
                <EditStoreInputField component={TextEditControl} {...editStoreInputFieldProps} fieldName={"displayName"}/>
                <EditStoreInputField component={TextEditControl} {...editStoreInputFieldProps} fieldName={"occurrenceTitle"} />
                <EditStoreInputField component={TextEditControl} {...editStoreInputFieldProps} fieldName={"type"} />
                <EditStoreInputField component={CheckboxControl} {...editStoreInputFieldProps} fieldName={"enabledBeforeTrip"} />
                <EditStoreInputField component={CheckboxControl} {...editStoreInputFieldProps} fieldName={"enabledDuringTrip"} />
                <EditStoreInputField component={CheckboxControl} {...editStoreInputFieldProps} fieldName={"enabledAfterTrip"} />
                <EditStoreInputField component={CheckboxControl} {...editStoreInputFieldProps} fieldName={"enabledOnlyForCancelled"} />
                <EditStoreInputField component={TextEditControl} {...editStoreInputFieldProps} fieldName={"requiredFields"} />
                <EditStoreInputField component={CodeEditControl} {...editStoreInputFieldProps} mode={"application/x-httpd-php-open"} fieldName={"formula"} />
                <EditStoreInputField component={CheckboxControl} {...editStoreInputFieldProps} fieldName={"enabled"} />
                <EditStoreInputField component={ApartmentsSelectionControl} {...editStoreInputFieldProps} multiple fieldName={"apartments"} />
            </React.Fragment>
        );
    }
}

@observer
export class EditOccurrenceRuleDialog extends React.Component {
    render() {

        const editStoreInputFieldProps = {
            stores: this.props.stores,
            editStore: this.props.editStore,
            tableName: "occurrencerules"
        };

        return (
            <EditStoreModelEditDialog
                stores={this.props.stores}
                editStore={this.props.editStore}
            >
                <Fields editStoreInputFieldProps={editStoreInputFieldProps}/>
            </EditStoreModelEditDialog>
        );
    }
}

@observer
export class CreateOccurrenceRuleDialog extends React.Component {
    render() {

        const editStoreInputFieldProps = {
            stores: this.props.stores,
            editStore: this.props.editStore,
            tableName: "occurrencerules"
        };

        return (
            <EditStoreModelCreationDialog
                stores={this.props.stores}
                editStore={this.props.editStore}
            >
                <Fields editStoreInputFieldProps={editStoreInputFieldProps}/>
            </EditStoreModelCreationDialog>
        );
    }
}