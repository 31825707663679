import * as React from "react";
import * as ModelEditFields from "../modelEditField";
import BuchungHistoryManager from "../js/src/buchungHistoryManager";
import BuchungHistoryList from "../detailsPage/buchungHistoryList";
import AccessibleUsersManager from "../js/src/accessibleUsersManager";
import {BasedataSelectionControl} from "../basedataSelectionControl";
import {MajorContentCard} from "../detailsPage/buchungDetailsPage";
import UserManager from "../js/src/userManager";
import PageTitleManager from "../js/src/pageTitleManager";

export default class ProfilePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            buchungHistory: null,
            allAccessibleUsers: null
        };

        this.onModelUpdate = this.onModelUpdate.bind(this);
        this.handleCurrentUserChange = this.handleCurrentUserChange.bind(this);
    }


    componentDidMount() {
        let userId = this.state.user !== null ? this.state.user.attributes.id : null;

        this.currentUserManager = new UserManager(userId);
        this.buchungHistoryManager = new BuchungHistoryManager("user", userId);
        this.accessibleUsersManager = new AccessibleUsersManager();

        this.currentUserManager.addDependant(this);
        this.buchungHistoryManager.addDependant(this);
        this.accessibleUsersManager.addDependant(this);

        this.props.drawer.setPageTitle("Profilansicht");
        PageTitleManager.getInstance().setTitle("Profilansicht");
        this.onModelUpdate();
    }

    componentWillUnmount() {
        this.currentUserManager.removeDependant(this);
        this.buchungHistoryManager.removeDependant(this);
        this.accessibleUsersManager.removeDependant(this);

        this.currentUserManager = null;
        this.buchungHistoryManager = null;
        this.accessibleUsersManager = null;
    }

    onModelUpdate() {
        this.setState({
            user: this.currentUserManager.getData() !== null ? this.currentUserManager.getData().values[0].attributes : null,
            buchungHistory: this.buchungHistoryManager.getData(),
            allAccessibleUsers: this.accessibleUsersManager.allModels,
            selectedUserId: null
        });
    }

    handleCurrentUserChange(userId) {
        this.currentUserManager.setUser(userId);
        this.buchungHistoryManager.setUser(userId);
    }

    render() {
        if(this.state.user === null)
            return null;

        let stores = this.props.stores;

        let loggedInUserId = this.state.user !== null ? this.state.user.id : null;

        return (
            <React.Fragment>
                <MajorContentCard>
                        <BasedataSelectionControl
                            title="Benutzer"
                            preset={loggedInUserId !== null ? loggedInUserId : ""}
                            items={this.state.allAccessibleUsers !== null && this.state.allAccessibleUsers.map(user => [user.attributes.id, user.attributes.vorname + " " + user.attributes.nachname])}
                            onChange={this.handleCurrentUserChange}
                        />
                </MajorContentCard>
                <MajorContentCard title={this.state.user.vorname + " " + this.state.user.nachname}>
                        <ModelEditFields.ModelEditFieldText modelManager={this.currentUserManager} noEdit columnName="vorname"/>
                        <ModelEditFields.ModelEditFieldText modelManager={this.currentUserManager} noEdit columnName="nachname"/>
                        <ModelEditFields.ModelEditFieldText modelManager={this.currentUserManager} noEdit columnName="email"/>
                        <ModelEditFields.ModelEditFieldText modelManager={this.currentUserManager} noEdit columnName="permissions"/>
                </MajorContentCard>
                <MajorContentCard title="Änderungsverlauf">
                    <BuchungHistoryList data={this.state.buchungHistory} buchungHistoryManager={this.buchungHistoryManager} stores={stores} showBuchungButUser/>
                </MajorContentCard>
            </React.Fragment>
        );
    }
}