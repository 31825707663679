import React from "react";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {formatEuroValue, formatPercentageValue, getCurrentSeason} from "../reactUtils";
import MieteinnahmenPerMonthChart from "../charts/mieteinnahmenPerMonthChart";
import InvoicedRevenueProportionChart from "../charts/invoicedRevenueProportionChart";
import AveragePricePerDayPerMonthChart from "../charts/averagePricePerDayPerMonthChart";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import {observer} from "mobx-react";
import LoadingIndicator from "../LoadingIndicator";
import {toJS} from "mobx";

@observer
export default class SeasonalStatistics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            seasonData: null,
            expandedSeason: getCurrentSeason().toString()
        };

        this.setExpandedSeason = this.setExpandedSeason.bind(this);
    }

    setExpandedSeason(season) {
        this.setState({
            expandedSeason: season
        });
    }

    render() {


        const handleChange = panel => (event, isExpanded) => {
            this.setExpandedSeason(isExpanded ? panel : false);
        };

        let statisticsStore = this.props.statisticsStore;

        let seasonKeys = statisticsStore.seasons.sort();
        seasonKeys.reverse(); //Latest season first

        return (
            <Box mt={2}>
                {
                    seasonKeys.map(seasonKey => {
                        let isExpanded = this.state.expandedSeason === seasonKey;
                        return (
                            <Accordion
                                expanded={isExpanded}
                                key={seasonKey}
                                onChange={handleChange(seasonKey)}
                            >
                                <AccordionSummary>{isExpanded ? (<Typography variant="h6">Saison {seasonKey}</Typography>) : ("Saison " + seasonKey)}</AccordionSummary>
                                <AccordionDetails>
                                    {
                                        isExpanded && <SingleSeasonStatistics statisticsStore={statisticsStore} season={seasonKey} apartment={this.props.apartment}/>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                }
            </Box>
        );
    }
}

@observer
class SingleSeasonStatistics extends React.Component {

    render() {
        let statisticsStore = this.props.statisticsStore;
        let season = this.props.season;

        let allSeasonsBaseData = statisticsStore.baseStatistics;

        let allSeasonsCharts = statisticsStore.seasonalCharts;

        if(allSeasonsBaseData.isLoading || allSeasonsCharts.isLoading || !allSeasonsBaseData.data || !allSeasonsCharts.data) {
            return (
                <LoadingIndicator/>
            );
        }

        let seasonBaseData = allSeasonsBaseData.data[season];
        let seasonCharts = allSeasonsCharts.data[season];

        let mieteinnahmenPerMonthChartData = toJS(seasonCharts["mieteinnahmenPerMonthChart"]);
        let invoicedRevenueProportionChartData = toJS(seasonCharts["invoicedRevenueProportionChart"]);
        let averagePricePerDayPerMonthChartData = toJS(seasonCharts["averagePricePerDayPerMonthChart"]);

        return (
            <Box width={1}>

                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Anzahl Buchungen</TableCell>
                            <TableCell>{(seasonBaseData["numBookingsTotal"]) + " Buchungen (davon " + seasonBaseData["numCancellations"] + " storniert)"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anzahl vermieteter Tage</TableCell>
                            <TableCell>{seasonBaseData["anzVermieteterTage"] + " Tage"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anteil Belegung auf ein Jahr</TableCell>
                            <TableCell>{formatPercentageValue(seasonBaseData["anzVermieteterTage"] / 365 * 100)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Mieteinnahmen (exkl. Stornierungsgebühren)</TableCell>
                            <TableCell>{formatEuroValue(seasonBaseData["mieteinnahmen"])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Stornierungsgebühren</TableCell>
                            <TableCell>{formatEuroValue(seasonBaseData["cancellationFeesSum"])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Gesamteinnahmen (inkl. Stornierungsgebühren)</TableCell>
                            <TableCell>{formatEuroValue(seasonBaseData["revenue"])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Provision des Verwalters</TableCell>
                            <TableCell>{formatEuroValue(seasonBaseData["provision"])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anzahl der Nächste mal Personen (für Touristensteuer)</TableCell>
                            <TableCell>{seasonBaseData["touristensteuerNaechte"] + " Nächte x Personen"}</TableCell>
                        </TableRow>
                        {/*
                            <TableRow>
                                <TableCell>Touristensteuer</TableCell>
                                <TableCell>{formatEuroValue(seasonData["touristensteuer"])}</TableCell>
                            </TableRow>
                        */}
                        <TableRow>
                            <TableCell>Durchschnittlicher Preis pro Nacht</TableCell>
                            <TableCell>{formatEuroValue(seasonBaseData["mieteinnahmen"] / seasonBaseData["anzVermieteterTage"]) + " pro Nacht"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                    <Grid container>
                        <Grid item md={12} xl={5}>
                            <MieteinnahmenPerMonthChart data={mieteinnahmenPerMonthChartData}/>
                        </Grid>
                        <Grid item md={12} xl={3}>
                            <InvoicedRevenueProportionChart data={invoicedRevenueProportionChartData}/>
                        </Grid>
                        <Grid item md={12} xl={4}>
                            <AveragePricePerDayPerMonthChart data={averagePricePerDayPerMonthChartData}/>
                        </Grid>
                    </Grid>
            </Box>
        );
    }

}