export default class ValidationObject {

    editStore = null;

    constructor(editStore) {
        this.editStore = editStore;

        this.getValue = this.getValue.bind(this);
    }

    getValue(fieldName) {
        if(this.editStore != null)
            return this.editStore.getValue(fieldName);
        return null;
    }

    checkField(inFieldName, outErrorObj) {}

}

export function isEmpty(inString) {
    return (typeof inString === "undefined" || inString == null || inString === "");
}

export function setOk(outErrorObj) {
    outErrorObj["state"] = false;
    outErrorObj["msg"] = "";
}

export function setErrorMayNotBeEmpty(outErrorObj) {
    setCustomError("Dieses Feld darf nicht leer sein", outErrorObj);
}

export function setErrorPriceMayNotBeNegative(outErrorObj) {
    setCustomError("Ein Preis darf nicht kleiner als 0 sein", outErrorObj);
}

export function setCustomError(inErrorMsg, outErrorObj) {
    if(typeof outErrorObj === "undefined")
        console.error("Function setCustomError called without errorObject");

    outErrorObj["state"] = true;
    outErrorObj["msg"] = inErrorMsg;
}