import * as React from "react";
import AvailableApartmentSelector from "../availableApartmentSelector";
import ApartmentConfigurationSheet from "./apartmentConfigurationSheet";
import PageTitleManager from "../js/src/pageTitleManager";

export default class ConfigurationPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedApartmentId: null
        };

        this.onSelectedApartmentChange = this.onSelectedApartmentChange.bind(this);
    }

    onSelectedApartmentChange(apartmentId) {
        this.setState({
            selectedApartmentId: apartmentId
        });
    }

    componentDidMount() {
        this.props.drawer.setToolbarItems(null);
        this.props.drawer.setPageTitle("Konfiguration");
        PageTitleManager.getInstance().setTitle("Konfiguration");
    }

    render() {
        return(
            <React.Fragment>
                <AvailableApartmentSelector presetFirst onModelChange={this.onSelectedApartmentChange}/>
                {
                    this.state.selectedApartmentId != null &&
                        <ApartmentConfigurationSheet apartmentId={this.state.selectedApartmentId} />
                }
            </React.Fragment>
        );
    }
}