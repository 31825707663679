import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {observer} from "mobx-react";
import EditStoreInputField from "../form/EditStoreInputField";
import CodeEditControl from "../form/baseControls/CodeEditControl";
import TextEditControl from "../form/baseControls/TextEditControl";
import EditStoreStaticField from "../form/EditStoreStaticField";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";

@observer
export default class EditStoreModelEditDialog extends React.Component {

    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    save() {
        this.props.editStore.save();
    }

    saveAndClose() {
        this.props.editStore.stopEditingAndSave();
        this.props.editStore.modelId = null;
        if(this.props.onClose)
            this.props.onClose();
    }

    cancel() {
        this.props.editStore.stopEditingAndDiscard();
        this.props.editStore.modelId = null;
        if(this.props.onClose)
            this.props.onClose();
    }

    render() {
        return (
            <Dialog
                open={this.props.editStore.modelId != null}
                onClose={this.cancel}
                fullWidth
                maxWidth={"xl"}
            >
                <DialogTitle>Bearbeiten</DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={this.save} color="primary">
                        Speichern
                    </Button>
                    <Button onClick={this.saveAndClose} color="primary">
                        Speichern und schließen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}