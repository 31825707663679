import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import {observer} from "mobx-react";
import ActiveBuchungOverviewCard from "../viewPage/activeBuchungOverviewCard";
import BlockageOverviewCard from "../viewPage/BlockageOverviewCard";

@observer
export default class CalendarDetailsDialog extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let bookingId = this.props.bookingId;
        let blockageId = this.props.blockageId;
        let booking, blockage;
        if(bookingId != null)
            booking = this.props.stores.bookingsStore.getModelById(bookingId);
        if(blockageId != null)
            blockage = this.props.stores.blockagesStore.getModelById(blockageId);

        return (
            (bookingId != null || blockageId != null) &&
                <Dialog
                    open={bookingId != null || blockageId != null}
                    onClose={this.props.onClose}
                    maxWidth={"lg"}
                    fullWidth
                >
                    <div style={{minHeight: "100px"}}>
                        {
                            booking &&
                                <ActiveBuchungOverviewCard stores={this.props.stores} buchung={booking} disableExpansion/>
                        }
                        {
                            blockage &&
                                <BlockageOverviewCard stores={this.props.stores} blockage={blockage} disableExpansion/>
                        }
                    </div>
                </Dialog>
        );
    }
}