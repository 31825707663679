import {initialStoreObject} from "./store";
import SimpleCollectionStore from "./SimpleCollectionStore";

export default class ApartmentsStore extends SimpleCollectionStore {

    doFetch(parameters, afterLoadCallback) {
        this.data = initialStoreObject;
        this.fetch(
            this.data,
            "db/providers/currentUserApartmentsProvider.php",
            {},
            afterLoadCallback
        );
    }

}