import Grid from "@material-ui/core/Grid";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {MajorContentCard} from "../detailsPage/buchungDetailsPage";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {toast} from "react-toastify";
import {callLoginCmd} from "../js/src/commands";
import LoginStateManager from "../js/src/LoginStateManager";
import {gotoPage} from "../reactUtils";

export default class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            stayLoggedIn: false
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleStayLoggedInChange = this.handleStayLoggedInChange.bind(this);
        this.doLogin = this.doLogin.bind(this);
    }

    doLogin(event) {
        event.preventDefault(); //Cancel form submission

        let email = this.state.email;
        let password = this.state.password;
        let stayLoggedIn = this.state.stayLoggedIn;

        callLoginCmd(
            email,
            password,
            stayLoggedIn,
            (data) => {
                LoginStateManager.getInstance().setLoggedIn(true); //Set manually because reloading the provider takes a while
                LoginStateManager.getInstance().getCurrentUserManager().refresh();
                gotoPage("/#/");
            },
            () => toast.error("Login fehlgeschlagen: E-Mail Adresse oder Passwort falsch")
        );
    }

    handleEmailChange(event) {
        this.setState({
           email: event.target.value
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    handleStayLoggedInChange(event) {
        this.setState({
            stayLoggedIn: event.target.checked
        });
    }

    render() {
        return (
            <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                style={{
                    height: "100%",
                    background: "#F5F5F5"
                }}
            >
                <Grid item>
                    <MajorContentCard title={"Buchungsverwaltung Login"}>
                        <form onSubmit={this.doLogin}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        label="E-Mail"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={this.handleEmailChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Password"
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={this.handlePasswordChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.stayLoggedIn}
                                                onChange={this.handleStayLoggedInChange}
                                            />
                                        }
                                        label={"Eingeloggt bleiben (nur auf eigenen Geräten)"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                    >
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </MajorContentCard>
                </Grid>
            </Grid>

        );
    }
}