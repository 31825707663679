import Store, {initialStoreObject} from "./store";

export default class BookingAuxiliaryStore extends Store {

    init(editStore) {
        super.init();
        this.parameters.editStore = editStore;

        this.data.documentGenerators = initialStoreObject;
        this.data.history = initialStoreObject;
    }

    doFetch(parameters, afterLoadCallback) {
        let bookingId = parameters.editStore.modelId;
        if(bookingId != null) {
            this.fetch(
                this.data.documentGenerators,
                "db/providers/documentGenerationProvider.php",
                {
                    buchung: bookingId
                },
                afterLoadCallback
            );

            this.fetch(
                this.data.history,
                "db/providers/buchungHistoryProvider.php",
                {
                    mode: "buchung",
                    buchungId: bookingId
                },
                afterLoadCallback
            )

        }
    }

}