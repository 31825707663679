import React from "react";
import ViewFilterCard from "./viewFilterCard";
import ActiveBuchungOverviewCard from "./activeBuchungOverviewCard";
import Box from "@material-ui/core/Box";
import {getAbreiseMoment, getAnreiseMoment} from "../buchungUtils";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import jQuery from "jquery";
import CancelledBuchungOverviewCard from "./cancelledBuchungOverviewCard";
import PageTitleManager from "../js/src/pageTitleManager";
import {observer} from "mobx-react";
import BuchungFilterObject from "../buchungFilterObject";
import {autorun, computed, toJS} from "mobx";
import LoadingIndicator from "../LoadingIndicator";
import DynamicStore from "../store/DynamicStore";
import ConfigurationStore from "../store/ConfigurationStore";
import ToolbarRefreshButton from "../ToolbarRefreshButton";
import ExportApartmentSeasonToolbarButton from "./ExportApartmentSeasonToolbarButton";

window.$ = window.jQuery = jQuery;

@observer
export default class ViewPage extends React.Component {


    constructor(props) {
        super(props);
        props.stores.manager.registerStore("viewPageStore", new ConfigurationStore({
            seasons: [],
            apartments: [],
            name: "",
            showOnlyWithOccurrences: false
        }));

        this.viewPageFilterObject = new BuchungFilterObject(props.stores.viewPageStore);
    }


    componentDidMount() {
        this.props.drawer.setPageTitle("Buchungsübersicht");
        this.props.drawer.setToolbarItems(
            <React.Fragment>
                <ExportApartmentSeasonToolbarButton stores={this.props.stores} />
                <ToolbarRefreshButton stores={this.props.stores} />
            </React.Fragment>
        );
        PageTitleManager.getInstance().setTitle("Buchungsübersicht");
    }

    render() {
        const bookingsStore = this.props.stores.bookingsStore;
        let allBookings = !bookingsStore.error ? bookingsStore.allItems : [];
        let filteredBookings = allBookings?.filter((element) => this.viewPageFilterObject.matches(element));
        filteredBookings?.sort((a,b) => moment(a.attributes.anreise).diff(moment(b.attributes.anreise)));

        let cards = [];
        if(filteredBookings) {
            let todayIndicatorPlaced = false;
            filteredBookings.forEach(
                (currentBuchung, index, array) => {
                    let nextBuchung = null;
                    if(index + 1 < array.length)
                        nextBuchung = array[index + 1];

                    const currentIsFirstBooking = index === 0;
                    const currentIsLastBooking = nextBuchung === null;

                    const placeTodayIndicator = () => {
                        cards.push(<TodayIndicator key="todayIndicator"/>);
                        todayIndicatorPlaced = true;
                    };

                    const today = moment().startOf("day");
                    const anreise = getAnreiseMoment(currentBuchung);
                    const abreise = getAbreiseMoment(currentBuchung);
                    const nextAnreise = getAnreiseMoment(nextBuchung);

                    // Before first booking
                    if(!todayIndicatorPlaced && currentIsFirstBooking && anreise.isSameOrAfter(today))
                        placeTodayIndicator();

                    // Place before (first) booking currently on holiday
                    if(!todayIndicatorPlaced && today.isBetween(anreise, abreise, undefined, "[]"))
                        placeTodayIndicator();

                    // Place booking card
                    if(currentBuchung.attributes.hasCancellation)
                        cards.push(
                            <Box key={currentBuchung.attributes.id} style={{marginBottom: "15px"}}>
                                <CancelledBuchungOverviewCard key={currentBuchung.attributes.id} stores={this.props.stores} buchung={currentBuchung}/>
                            </Box>
                        );
                    else
                        cards.push(
                            <Box key={currentBuchung.attributes.id} style={{marginBottom: "15px"}}>
                                <ActiveBuchungOverviewCard stores={this.props.stores} buchung={currentBuchung}/>
                            </Box>
                        );

                    // Place if between two bookings (one in the past, one in the future)
                    if(!todayIndicatorPlaced && !currentIsLastBooking && today.isBetween(abreise, nextAnreise, undefined, "[)"))
                        placeTodayIndicator();

                    // Place if current is the last booking and current booking is over
                    if(!todayIndicatorPlaced && currentIsLastBooking && abreise.isBefore(today))
                        placeTodayIndicator();

                }
            );
        }

        if(bookingsStore.isLoading && allBookings.length === 0)
            cards.push(<LoadingIndicator key={"loading"}/>);
        else if(bookingsStore.error) {
            console.error(bookingsStore.error);
            cards.push(<Box key={"error"}>Beim Laden ist ein Fehler aufgetreten</Box>);
        }
        else if(filteredBookings.length === 0 && allBookings.length > 0)
            cards.push(<Box key={"noItemsFound"}>Für diese Filterkriterien konnten keine Buchungen gefunden werden</Box>);

        return (
            <React.Fragment>
                <JumpToTopFab/>
                    <ViewFilterCard stores={this.props.stores} filterObject={this.viewPageFilterObject}/>
                    { cards }
            </React.Fragment>
        );
    }
}

class JumpToTopFab extends React.Component {
    render() {
        return (
            <Fab
                color="secondary"
                onClick={() => window.scrollTo(0,0)}
                style = {{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1000
                }}
            >
                <Icon>vertical_align_top</Icon>
            </Fab>
        );
    }
}

class TodayIndicator extends React.Component {
    render() {
        return (
            <Box>
                <div
                    style={{
                        height: "6px",
                        width: "100%",
                        background: "linear-gradient(to bottom, #e57373, white)"
                    }}
                />
                <div
                    style={{
                        color: "#e57373",
                        fontWeight: "bold",
                        fontSize: "0.8em",
                        marginTop: "0px",
                        marginLeft: "40px",
                        marginBottom: "8px"
                    }}
                >
                    HEUTE
                </div>
                <a
                    id="todayIndicator"
                    style={{
                        display: "block",
                        position: "relative",
                        top: "-100px",
                        visibility: "hidden"
                    }}
                />
            </Box>
        );
    }
}