import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {toJS} from "mobx";
import {toast} from "react-toastify";

export default class ToolbarRefreshButton extends React.Component {
    render() {
        let stores = this.props.stores;
        let manager = stores.manager;
        const viewPageStore = stores.viewPageStore;

        return (
            <IconButton
                onClick={() => {
                    let apartmentIds = viewPageStore.data.apartments;
                    let seasons = viewPageStore.data.seasons;

                    if(apartmentIds.length !== 1 || seasons.length !== 1) {
                        toast.warn("Bitte wähle genau eine Ferienwohnung und genau eine Saison aus, um die Buchungen zu exportieren.");
                    } else {
                        window.open("generation/bookingList.php?season="+seasons[0]+"&apartmentId="+apartmentIds[0], '_blank');
                    }

                }}
                style={{color: "white"}}
            >
                <Icon>description</Icon>
            </IconButton>
        );
    }
}