import Store from "./store";
import {computed} from "mobx";
import {computedFn} from "mobx-utils";
import {arrayRemove} from "../JSArrayUtils";

export default class SimpleCollectionStore extends Store {

    constructor() {
        super();
        this.findByPredicate = this.findByPredicate.bind(this);
    }

    @computed get allItems() {
        if(this.data.data == null || this.data.error || this.data.data === "noLogin")
            return [];
        return this.data.data;
    }

    @computed get isLoading() {
        return this.data.isLoading;
    }

    @computed get error() {
        return this.data.error;
    }

    refetchByIdBase(resultObj, url, id, additionalData, callback) {
        let oldItemIndex = resultObj.data.findIndex(element => element.attributes.id === id);
        if(oldItemIndex >= 0)
            resultObj.data.splice(oldItemIndex, 1);
        let fetchResult = {};
        let data = additionalData;
        data.id = id;
        this.fetch(fetchResult, url,data, () => {
            if(fetchResult.data != null && fetchResult.data.length === 1) {
                let fetchObject = fetchResult.data[0];
                resultObj.data.push(fetchObject);
            }
            callback();
        });
    }

    replaceItem(item) {
        if(!this.data.data)
            return;

        let id = item.attributes.id;
        arrayRemove(this.data.data, elem => elem.attributes.id === id);
        this.data.data.push(item);
    }

    findByPredicate(predicate) {
        if(!this.error && this.data.data != null && typeof this.data.data === "object") {
            let result = this.data.data.find(element => predicate(element));
            return result !== undefined ? result : null;
        }
        return null;
    }

    getModelById = computedFn(function getModelById(id) {
        return this.findByPredicate(element => element.attributes.id === id);
    });

}