import Store, {initialStoreObject} from "./store";
import {computed, toJS} from "mobx";

export default class StatisticsStore extends Store {

    constructor() {
        super();
        this.setApartment = this.setApartment.bind(this);
    }

    init() {
        super.init();
        this.data.baseStatistics = initialStoreObject;
        this.data.seasonalCharts = initialStoreObject;
        this.data.globalCharts = initialStoreObject;
    }

    setApartment(apartmentId) {
        this.parameters.apartment = apartmentId;
    }

    doFetch(parameters) {
        super.doFetch();
        if(parameters.apartment > 0) {
            this.fetch(
                this.data.baseStatistics,
                "db/providers/statisticsBaseProvider.php",
                {
                    apartmentId: parameters.apartment
                }
            );

            this.fetch(
                this.data.seasonalCharts,
                "db/providers/statisticsSeasonalChartsProvider.php",
                {
                    apartmentId: parameters.apartment
                }
            );

            this.fetch(
                this.data.globalCharts,
                "db/providers/statisticsGlobalChartsProvider.php",
                {
                    apartmentId: parameters.apartment
                }
            );
        }
    }

    @computed get baseStatistics() {
        return this.data.baseStatistics;
    }

    @computed get seasonalCharts() {
        return this.data.seasonalCharts;
    }

    @computed get globalCharts() {
        return this.data.globalCharts;
    }

    @computed get seasons() {
        let result = null;
        if(this.baseStatistics.isLoading || this.baseStatistics.error || !this.baseStatistics.data)
            result = [];
        else
            result = Object.keys(this.baseStatistics.data);
        return result;
    }

}