import React from "react";
import Grid from "@material-ui/core/Grid";
import {CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";

export default class ChartWrapperCard extends React.Component {
    render() {
        return (
            <Card style={{marginTop: "15px", marginBottom: "15px"}}>
                <CardContent>
                    <Grid justifyContent={"center"} container>
                        <Grid item sm={12} md={11} lg={10} xl={9}>
                            {this.props.children}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}