import React from "react";
import {observer} from "mobx-react";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

@observer
export default class DateSelectionControl extends React.Component{

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.onInteraction = this.onInteraction.bind(this);
    }

    handleChangeDate(date) {
        let value = null;
        if(date !== null && date.isValid())
            value = date.format("YYYY-MM-DD");

        let editStore = this.props.editStore;
        editStore.modifyField(this.props.fieldName, value);

        this.onInteraction();
    }

    stringToDate(string) {
        if(string)
            return moment(string).format("YYYY-MM-DD");
        else
            return null;
    }

    componentDidMount() {
        if(this.props.initialRegisterDefaultValue)
            this.props.onValueChange(null);
    }

    onInteraction() {
        let interactionCallback = this.props.onInteraction;
        if(typeof interactionCallback === "function")
            interactionCallback();
    }

    render() {
        const { classes } = this.props;
        let editStore = this.props.editStore;
        let value = editStore.getValue(this.props.fieldName);
        let date = this.stringToDate(value);

        if(this.inputRef.current)
            this.inputRef.current.disabled = !this.props.edit;

        let inEdit = this.props.edit;

        let textFieldWithTooltip = (props) => {
            return (
                <Tooltip title={this.props.tooltip || ""}>
                    <TextField {...props} />
                </Tooltip>
            )
        };

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="DD.MM.YYYY"
                    margin="normal"
                    label={this.props.title}
                    value={date}
                    onChange={this.handleChangeDate}
                    TextFieldComponent={textFieldWithTooltip}
                    autoOk={true}
                    readOnly={!inEdit}
                    inputRef={this.inputRef}
                    //disabled={!this.props.edit}
                    fullWidth
                    error={this.props.error}
                    helperText={this.props.helperText}
                    InputProps={{
                        readOnly: !inEdit
                    }}
                    /*{...this.state.errorState}*/
                />
            </MuiPickersUtilsProvider>
        );
    }
}