import React from "react";
import {formatGermanFloat, parseGermanFloatValue} from "../../reactUtils";
import TextFieldControl from "./TextFieldControl";

export default class FloatEditControl extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <TextFieldControl
                initialValue={null}
                {...this.props}
                inputFormatter={value => {
                    if(value != null) {
                        if(!isNaN(value) && typeof value === "number") //valid
                            value = formatGermanFloat(value, null);
                        else //NaN
                            value = "";
                    } else //null
                        value = "";

                    return value;
                }}
                outputFormatter={value => {
                    let floatVal = parseGermanFloatValue(value);
                    if(isNaN(floatVal))
                        floatVal = null;
                    return floatVal;
                }}
                applyOnBlur
            />
        );
    }
}