import * as React from "react";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";

export default class EditSaveFAB extends React.Component {
    render() {
        let editStore = this.props.editStore;
        let inEdit = editStore.inEdit;
        return (
            <Box
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: "100"
                }}
            >
                {
                    inEdit &&
                        <Fab
                            variant="extended"
                            color="primary"
                            onClick={() => {
                                editStore.stopEditingAndDiscard();
                            }}
                            style = {{
                                marginRight: "10px"
                            }}
                        >
                            <Icon style={{marginRight: "3px"}}>cancel</Icon>
                            abbrechen
                        </Fab>
                }
                <Fab
                    variant="extended"
                    color="secondary"
                    onClick={() => {
                        if(inEdit)
                            editStore.stopEditingAndSave();
                        else
                            editStore.startEditing();
                    }}
                    style = {{

                    }}
                >
                    <Icon style={{marginRight: "3px"}}>{inEdit ? "save" : "edit"}</Icon>
                    {
                        inEdit ? "speichern" : "bearbeiten"
                    }
                </Fab>
        </Box>
        );
    }
}
