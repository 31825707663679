import {observer} from "mobx-react";
import React from "react";
import BookingCreationMemorizer from "./BookingCreationMemorizer";
import BuchungValidationObject from "../validationObjects/buchungValidationObject";
import BookingComputedFieldsCalculationObject from "../BookingComputedFieldsCalculationObject";
import Grid from "@material-ui/core/Grid";
import {MajorContentCard} from "../detailsPage/buchungDetailsPage";
import EditStoreInputField from "../form/EditStoreInputField";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";
import CheckboxControl from "../form/baseControls/CheckboxControl";
import GenderSelectionControl from "../form/GenderSelectionControl";
import TextEditControl from "../form/baseControls/TextEditControl";
import TextareaEditControl from "../form/baseControls/TextareaEditControl";
import DateSelectionControl from "../form/baseControls/DateSelectionControl";
import IntegerEditControl from "../form/baseControls/IntegerEditControl";
import PriceEditControl from "../form/baseControls/PriceEditControl";
import {Spacer} from "../reactUtils";
import ControlButtonPair from "../form/ControlButtonPair";
import SmartfillButton from "./SmartfillButton";
import moment from "moment";
import {FinalPaymentRentDisplayControl, FinalPaymentTotalDisplayControl} from "../form/FinalPaymentDisplayControls";
import {toast} from "react-toastify";
import {
    TotalCostsExclDepositDisplayControl,
    TotalCostsInclDepositDisplayControl
} from "../form/TotalCostsDisplayControls";
import BuchungAutofillObject from "../autofillObjects/buchungAutofillObject";
import BookingTemplateSelectionMenu from "./BookingTemplateSelectionMenu";

@observer
export default class BookingCreationSheet extends React.Component {

    constructor(props) {
        super(props);
        props.editStore.reset();
        props.editStore.memorizer = new BookingCreationMemorizer();
        props.editStore.validatorObject = new BuchungValidationObject(this.props.editStore);
        props.editStore.computedFieldsCalculationObject = new BookingComputedFieldsCalculationObject();
        props.editStore.autofillObject = new BuchungAutofillObject(this.props.editStore, this.props.stores);
        props.editStore.simpleCollectionStore = this.props.stores.bookingsStore;
        props.editStore.modelId = -1;
        props.editStore.startEditing();
    }


    componentDidMount() {

    }

    render() {
        let editStore = this.props.editStore;
        if(!editStore.inEdit)
            return null;

        let stores = this.props.stores;
        const formEditFieldsBasicProps = {
            stores: stores,
            editStore: editStore,
            tableName: "buchungen",
            permanentInputMode: true,
            errorOnlyAfterInteraction: true
        };

        return (
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <MajorContentCard title="Ferienwohnung">
                        <EditStoreInputField component={ApartmentsSelectionControl} {...formEditFieldsBasicProps} fieldName={"apartmentFK"} />
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"zeitraumGeblockt"} />

                    </MajorContentCard>
                    <MajorContentCard title="Persönliche Informationen">
                        <EditStoreInputField component={GenderSelectionControl} {...formEditFieldsBasicProps} fieldName={"anrede"} />
                        <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"vorname"} />
                        <ControlButtonPair
                            control={<EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"nachname"} />}
                            button={<BookingTemplateSelectionMenu stores={stores} editStore={editStore} />
                            }
                        />
                        <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"adresse"} />
                        <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"bankverbindung"} />
                        <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"email"} />
                        <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"telefonnummer"} />
                        <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"vornamen"} />
                    </MajorContentCard>
                    <MajorContentCard title="Reisedaten">
                        <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"anreise"} />
                        <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"abreise"} />
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"extendOneDayForCleaning"} />
                        <EditStoreInputField component={IntegerEditControl} {...formEditFieldsBasicProps} fieldName={"anzahlPersonen"} />
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"sofortigeRestzahlung"} />
                    </MajorContentCard>
                    <MajorContentCard title="Preise und fixe Nebenkosten">
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"energieBezahlen"} />
                        <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"mietpreis"} />
                        <Spacer/>
                        <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"anzahlung"} />
                        <ControlButtonPair
                            control={<EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"anzahlungBis"} />}
                            button={
                                <SmartfillButton items={
                                    [
                                        {
                                            text: "Heute in zwei Wochen",
                                            action: () => this.props.editStore.modifyField("anzahlungBis", moment().add(2, "weeks").format("YYYY-MM-DD"))
                                        }
                                    ]
                                }/>
                            }
                        />
                        <Spacer/>
                        <FinalPaymentRentDisplayControl {...formEditFieldsBasicProps} />
                        <FinalPaymentTotalDisplayControl {...formEditFieldsBasicProps} />
                        <ControlButtonPair
                            control={<EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"restzahlungBis"} />}
                            button={
                                <SmartfillButton items={
                                    [
                                        {
                                            text: "6 Wochen vor Anreise",
                                            action: () => {
                                                let anreise = moment(this.props.editStore.getValue("anreise"));
                                                let restzahlungBis = anreise.isValid() ? anreise.subtract(6, "weeks").format("YYYY-MM-DD") : null;
                                                this.props.editStore.modifyField("restzahlungBis", restzahlungBis);
                                                if(!restzahlungBis)
                                                    toast.warn("Bitte zuerst das Anreisedatum auswählen");
                                            }
                                        },
                                        {
                                            text: "Heute in zwei Wochen",
                                            action: () => this.props.editStore.modifyField("restzahlungBis", moment().add(2, "weeks").format("YYYY-MM-DD"))
                                        }
                                    ]
                                }/>
                            }
                        />
                        <Spacer/>
                        <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"kaution"} />
                        <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"endreinigung"} />
                        <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"touristensteuer"} />
                        <TotalCostsExclDepositDisplayControl {...formEditFieldsBasicProps} />
                        <TotalCostsInclDepositDisplayControl {...formEditFieldsBasicProps} />
                    </MajorContentCard>
                </Grid>
                <Grid  item xs={12} lg={6}>
                    <MajorContentCard title="Schlüsseltresor">
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"hasKeysafe"} />
                    </MajorContentCard>
                    <MajorContentCard title="Allgemeines">
                        <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"kommentar"} />
                    </MajorContentCard>
                    <MajorContentCard title="Verwalter">
                        <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"provision"} />
                        <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"custodianSpecialRequest"} />
                    </MajorContentCard>
                </Grid>
            </Grid>
        );
    }
}