import * as React from "react";
import CurrentUserApartmentsManager from "./js/src/currentUserApartmentsManager";
import {BasedataSelectionControl} from "./basedataSelectionControl";

export default class AvailableApartmentSelector extends React.Component {

    constructor(props) {
        super(props);
        this.currentUserApartmentsManager = CurrentUserApartmentsManager.get();
        this.state = {
            selectedApartmentId:  null,
            apartments: null
        }

        this.onModelUpdate = this.onModelUpdate.bind(this);
    }

    componentDidMount() {
        this.currentUserApartmentsManager.addDependant(this);
        this.onModelUpdate();
        if(this.props["presetFirst"] === true && this.props["onModelChange"] != null)
            this.props.onModelChange(this.getPresetValue());

    }

    componentWillUnmount() {
        this.currentUserApartmentsManager.removeDependant(this);
    }

    onModelUpdate() {
        this.setState({
            apartments: this.currentUserApartmentsManager.getAsKeyValArray()
        });
    }

    getPresetValue() {
        return this.props["presetFirst"] === true ? (this.state.apartments != null && this.state.apartments[0] != null) ? this.state.apartments[0][0] : null : null;
    }

    render() {
        let preset = this.getPresetValue() === null ? "" : this.getPresetValue();
        return (
            (this.state.apartments != null && this.state.apartments.length > 0) &&
            <BasedataSelectionControl
                title="Ferienwohnung"
                preset={preset}
                items={this.state.apartments}
                onChange={(id) => {
                    this.setState({selectedApartmentId: id});
                    if(this.props["onModelChange"] !=  null)
                        this.props["onModelChange"](id);
                }}
            />
        );
    }


}