import ModelManager from "./modelManager";

export default class CurrentUserApartmentsManager extends ModelManager {

    //Singleton
    static instance = null;
    static get() {
        if(this.instance === null)
            this.instance = new CurrentUserApartmentsManager();
        return this.instance;
    }

    constructor() {
        super(
            "db/providers/currentUserApartmentsProvider.php",
            null,
            null,
            null
        );
    }

    getAsKeyValArray() {
        let ret = [];
        if(this.data !== null) {
            this.data.forEach((item) => {
                ret.push([item.attributes.id, item.attributes.name]);
            });
        }
        return ret;
    }

}