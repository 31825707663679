import moment from "moment";
import ValidationObject, {
    isEmpty,
    setCustomError,
    setErrorMayNotBeEmpty,
    setErrorPriceMayNotBeNegative,
    setOk
} from "./ValidationObject";

export default class BuchungValidationObject extends ValidationObject{

    checkField(inFieldName, outErrorObj) {
        let value = this.getValue(inFieldName);
        switch(inFieldName) {
            case "vorname":
            case "nachname":
            case "anrede":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                break;

            case "anreise":
            case "abreise":
                let anreiseStr = this.getValue("anreise");
                let abreiseStr = this.getValue("abreise");

                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);

                if(!isEmpty(anreiseStr) && !isEmpty(abreiseStr)) {
                    if(moment(anreiseStr).isSameOrAfter(moment(abreiseStr))) {
                        setErrorAbreiseBeforeAnreise(outErrorObj);
                    }
                }
                break;

            case "mietpreis":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                if(value < 0)
                    setErrorPriceMayNotBeNegative(outErrorObj);
                break;

            case "anzahlung":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                if(value < 0)
                    setErrorPriceMayNotBeNegative(outErrorObj);

                let mietpreis = this.getValue("mietpreis");
                if(value > mietpreis)
                    setCustomError("Die Anzahlung darf nicht größer sein als der Mietpreis", outErrorObj);
                break;

            case "anzahlungBis":
            case "restzahlungBis":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                //Could add time checking here
                break;

            case "kaution":
            case "endreinigung":
            case "touristensteuer":
            case "provision":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                if(value < 0)
                    setErrorPriceMayNotBeNegative(outErrorObj);
                break;

            case "apartmentFK":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                break;

            case "anzahlPersonen":
                if(value === "") {
                    setErrorMayNotBeEmpty(outErrorObj);
                    break;
                }

                if(!Number.isInteger(value) || value < 0)
                    setCustomError("Die Personenzahl muss ganzzahlig und positiv sein", outErrorObj);
                break;

            default:
                setOk(outErrorObj);
        }
    }

}

function setErrorAbreiseBeforeAnreise(outErrorObj) {
    setCustomError("Die Abreise muss nach der Anreise erfolgen", outErrorObj);
}
