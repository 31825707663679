import * as React from "react";
import {observer} from "mobx-react";

@observer
export default class DynamicStoreInputField extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        let dynamicStore = this.props.dynamicStore;

        let fieldName = this.props.fieldName;
        let title = this.props?.title ?? "";
        let tooltip = this.props?.tooltip;
        let TagName = this.props.component;

        return (
            <TagName
                title={title}
                value={dynamicStore.getValue(fieldName)}
                onValueChange={this.handleChange}
                onEnter={() => dynamicStore.stopEditingAndSave()}
                onEsc={() => dynamicStore.stopEditingAndDiscard()}
                edit={true}
                permanentInputMode={true}
                initialRegisterDefaultValue={false}
                tooltip={tooltip}
                {...this.props}
            />
        );
    }

    handleChange(value) {
        let dynamicStore = this.props.dynamicStore;
        let fieldName = this.props.fieldName;

        dynamicStore.modifyField(fieldName, value);
    }

}