import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import React from "react";
import * as ReactUtils from "../reactUtils";
import {BoldText} from "../reactUtils";
import moment from "moment";
import 'moment/locale/de';
import {toast} from "react-toastify";
import * as buchungUtils from "../buchungUtils";
import $ from "jquery";
import LoginStateManager from "../js/src/LoginStateManager";
import {observer} from "mobx-react";
import LoadingIndicator from "../LoadingIndicator";
import {toJS} from "mobx";

moment.locale('de');

export default class BuchungHistoryList extends React.Component {

    render() {
        if(this.props.data != null)
            return (
                this.props.data.map(history =>
                    <BuchungHistoryCard
                        key={history.attributes.id}
                        buchungHistoryManager={this.props.buchungHistoryManager}
                        history={history}
                        showBuchungButUser={this.props.showBuchungButUser}
                        stores={this.props.stores}
                    />
                )
            );
        else
            return null;
    }

}

const oldValueColor = "#B71C1C";
const newValueColor = "#1B5E20";


const BuchungHistoryCard = observer(class BuchungHistoryCard extends React.Component {

    constructor(props) {
        super(props);

        this.deleteHistory = this.deleteHistory.bind(this);
    }

    getDescriptiveText(bookingsStore, fieldDescriptionsStore, usersStore) {
        let history = this.props.history.attributes;

        let bookingId = history.buchungFK;
        let buchung = bookingsStore.getModelById(bookingId);

        let fieldDescriptionId = history.attrFK;
        let fieldDescription = fieldDescriptionId !== null ? fieldDescriptionsStore.getModelById(fieldDescriptionId) : null;
        let fieldDescriptionTitle = fieldDescription !== null ? fieldDescription.attributes.title : "";

        let userId = history.userFK;
        let user = usersStore.getModelById(userId);
        let userVorname = user?.attributes.vorname ?? "Wird geladen...";
        let userNachname = user?.attributes.nachname ?? "Wird geladen...";
        let userFullName = userVorname + " " + userNachname;

        if(fieldDescription !== null) {
            if(this.props.showBuchungButUser) {

                return (
                    <React.Fragment>
                        Das Attribut <BoldText>{fieldDescriptionTitle}</BoldText> der Buchung <BoldText>{buchungUtils.getFullName(buchung) + " " + buchungUtils.getMonthYearTextString(buchung)}</BoldText> wurde geändert [{moment(history.time).format("DD.MM.YYYY HH:mm")}]
                    </React.Fragment>
                );
            }
            else
                return (
                    <React.Fragment>
                        <BoldText>{userFullName}</BoldText> hat <BoldText>{fieldDescriptionTitle}</BoldText> geändert [{moment(history.time).format("DD.MM.YYYY HH:mm")}]
                    </React.Fragment>
                );
        }
        else {
            if(this.props.showBuchungButUser) {
                return (
                    <React.Fragment>
                        Die Buchung <BoldText>{buchungUtils.getFullName(buchung) + " " + buchungUtils.getMonthYearTextString(buchung)}</BoldText> wurde erstellt [{moment(history.time).format("DD.MM.YYYY HH:mm")}]
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <BoldText>{userFullName}</BoldText> hat die Buchung angelegt
                        [{moment(history.time).format("DD.MM.YYYY HH:mm")}]
                    </React.Fragment>
                );
            }
        }
    }

    deleteHistory() {
        let history = this.props.history.attributes;
        let buchungHistoryManager = this.props.buchungHistoryManager;
        $.ajax({
            method: "POST",
            url: "db/commands/deleteBuchungHistoryCmd.php",
            data: {
                id: history.id
            }
        })
        .done(function(data) {
            if(data === 'success') {
                toast.success("Historieneintrag gelöscht");
            } else {
                toast.error("Fehler beim speichern");
            }
            buchungHistoryManager.loadValues();
        });
    }


    render() {
        let history = this.props.history.attributes;

        let stores = this.props.stores;
        let bookingsStore = stores.bookingsStore;
        let fieldDescriptionsStore = stores.fieldDescriptions;
        let usersStore = stores.users;

        if(bookingsStore.isLoading || bookingsStore.error || fieldDescriptionsStore.isLoading || fieldDescriptionsStore.error || usersStore.isLoading || usersStore.error)
            return <LoadingIndicator/>;

        let showDeleteButton = LoginStateManager.getInstance().getCurrentUserManager().hasPermission("admin");

        return (
            <Card style={{marginBottom: "15px"}}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.getDescriptiveText(bookingsStore, fieldDescriptionsStore, usersStore)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box color={oldValueColor} style={{textDecoration: "line-through"}}>{ReactUtils.nl2br(history.oldValue)}</Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box color={newValueColor}>{ReactUtils.nl2br(history.newValue)}</Box>
                        </Grid>
                    </Grid>
                </CardContent>
                {
                    showDeleteButton &&
                        <CardActions disableSpacing style={{ display: 'flex' }}>
                            <IconButton onClick={this.deleteHistory} style={{ marginLeft: 'auto' }}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </CardActions>
                }
            </Card>
        );
    }
})