import * as React from "react";
import TextEditControl from "./baseControls/TextEditControl";
import {observer} from "mobx-react";
import EditStoreStaticField from "./EditStoreStaticField";
import {formatEuroValue} from "../reactUtils";

@observer
export default class CancellationRefundSumDisplayControl extends React.Component {
    render() {

        //TODO Adapt and use this class

        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("cancellationRefund"), false)}
                adornment={"€"}
                title={"Zu erstattende Differenz"}
            />
        );
    }
}