import * as React from "react";
import TextEditControl from "./baseControls/TextEditControl";
import {observer} from "mobx-react";
import EditStoreStaticField from "./EditStoreStaticField";
import {formatEuroValue} from "../reactUtils";

@observer
export class FinalPaymentRentDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("finalPaymentRent"), false)}
                adornment={"€"}
                title={"Restzahlung Miete"}
            />
        );
    }
}

@observer
export class FinalPaymentTotalDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("finalPaymentTotal"), false)}
                adornment={"€"}
                title={"Restzahlung inkl. Nebenkosten und Kaution"}
            />
        );
    }
}