import React from "react";
import TextFieldControl from "./TextFieldControl";
import {removeAllCharsButDigits} from "../../reactUtils";

export default class IntegerEditControl extends React.Component{

    render() {
        return (
            <TextFieldControl
                initialValue={null}
                {...this.props}
                inputFormatter={value => {
                    if(value != null) {
                        if(!isNaN(value)) //valid
                            value = "" + value;
                        else //NaN
                            value = "";
                    } else //null
                        value = "";

                    return value;
                }}
                outputFormatter={value => {
                    value = removeAllCharsButDigits(value);
                    let intVal = parseInt(value);
                    if(isNaN(intVal))
                        intVal = null;
                    return intVal;
                }}
            />
        );
    }
}