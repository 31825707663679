import * as React from "react";
import {Bubble} from "react-chartjs-2";
import 'chartjs-plugin-colorschemes';

const monthToString = {1: "Januar", 2:"Februar", 3:"März", 4:"April", 5:"Mai", 6:"Juni", 7:"Juli", 8:"August", 9:"September", 10:"Oktober", 11:"November", 12:"Dezember"};

export default class BookingMonthChart extends React.Component {

    chartOptions = {
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Reisemonat",
                    fontSize: 15
                },
                ticks: {
                    type: "linear",
                    stepSize: 1,
                    min: 1,
                    max: 12,
                    beginAtZero: false
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Buchungsmonat",
                    fontSize: 15
                },
                ticks: {
                    type: "linear",
                    stepSize: 1,
                    min: 1,
                    max: 12,
                    beginAtZero: false
                }
            }]
        },
        title: {
            display:true,
            text: "Reisezeitraum im Verhältnis zum Buchungsdatum",
            fontSize: 20
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    data = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return "Reisemonat: "+monthToString[data.x]+"; Buchungsmonat: "+monthToString[data.y]+"; Häufigkeit: "+data.r/4;
                }
            }
        }
    };

    render() {
        let data = this.props.data;
        return (
            <Bubble data={data} options={this.chartOptions}/>
        );
    }
}