import React from "react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class HTMLEditControl extends React.Component {
    render() {
        return (
            <CKEditor
                editor={ ClassicEditor }
                data={this.props.value || ""}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    if(this.props.onValueChange != null)
                        this.props.onValueChange(data);
                } }
            />
        )
    }
}