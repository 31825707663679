import ModelCollectionManagerBase from "./modelCollectionManagerBase";
import {toast} from "react-toastify";

export default class ModelManager extends ModelCollectionManagerBase {

    validationObject = null;
    validationEnabled = {};
    validationForAllFieldsEnabled = false;
    autofillObject = null;

    getFieldTitle(fieldName) {
        let searchResult = null;
        let title = "";
        if(this.data !== null && !this.hasError())
            searchResult = this.data.descriptions.find(function(element) {
                    return element.attributes.columnName === fieldName;
                }
            );
        if(searchResult != null)
            title = searchResult.attributes.title;

        return title;
    }

    getErrorState(fieldName) {
        let errorObj = {};
        if(this.validationObject !== null && this.isValidationEnabled(fieldName))
            this.validationObject.checkField(fieldName, errorObj);
        return {error: errorObj.state, helperText: errorObj.msg};
    }

    isValidationEnabled(fieldName) {
        if(this.validationForAllFieldsEnabled)
            return true;
        return typeof this.validationEnabled[fieldName] !== undefined && this.validationEnabled[fieldName];
    }

    enableValidation(fieldName, value) {
        this.validationEnabled[fieldName] = typeof value === "undefined" || value === true;
    }

    getModel() {
        if(this.data != null && this.data.values != null)
            return this.data.values[0];
        return null;
    }

    getValue(fieldName) {
        if(this.data != null && typeof this.data.values[0].attributes[fieldName] !== 'undefined')
            return this.data.values[0].attributes[fieldName];
        else
            return null;
    }

    doAutofill(modifiedField) {
        if(this.autofillObject !== null)
            this.autofillObject.doAutofill(modifiedField);
    }

    setValue(fieldName, value) {
        this.saveValueCmd(fieldName, value);
        this.doAutofill(fieldName);
    }

    saveValueCmd(fieldName, value) {
        let self = this;
        this.data.values[0].attributes[fieldName] = value;

        let localCommandData = Object.assign({}, this.commandData); //copy this.commandData
        localCommandData["dbFieldName"] = fieldName;
        localCommandData["value"] = value;

        $.ajax({
            method: "POST",
            url: this.commandFilePath,
            data: localCommandData
        })
            .done(function(data) {
                if(data === 'success') {
                    toast.success("Änderung gespeichert");
                } else {
                    toast.error('Fehler beim speichern: '+data);
                }
            })
            .always(function() {
                self.loadValues(); //Reload all data to make unsaved changes visible
                //self.notifyDependants(); Enable if loadvalues is disabled
            });
    }

    handleProviderRequestData(data) {
        this.data = data;
        super.handleProviderRequestData(data);
    }

}
