import {observer} from "mobx-react";
import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import EditStoreInputField from "../form/EditStoreInputField";
import CodeEditControl from "../form/baseControls/CodeEditControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import EditStore from "../store/EditStore";
import TextEditControl from "../form/baseControls/TextEditControl";
import {toast} from "react-toastify";
import EditStoreStaticField from "../form/EditStoreStaticField";
import HTMLEditControl from "../form/baseControls/HTMLEditControl";
import {toJS} from "mobx";


@observer
export default class SendmailDialog extends React.Component {

    constructor(props) {
        super(props);
        this.send = this.send.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async send() {
        const {adress, subject, mailContent, mailAccountId} = this.props.editStore.dynamicModel;

        let response = await fetch(
            "/api/mail/send.php",
            {
                method: "POST",
                body: JSON.stringify({
                    mailAccountId,
                    recipient: adress,
                    subject,
                    content: mailContent
                })
            });
        if(response.status === 200)
            toast.success("E-Mail erfolgreich versendet");
        else
            toast.error("Ein Fehler ist aufgetreten: " + await response.text());

        this.props.editStore.stopEditingAndDiscard();
        if(this.props.onClose)
            this.props.onClose();
    }

    cancel() {
        this.props.editStore.stopEditingAndDiscard();
        if(this.props.onClose)
            this.props.onClose();
    }

    render() {

        const editStoreInputFieldProps = {
            stores: this.props.stores,
            editStore: this.props.editStore,
            tableName: null,
            permanentInputMode: true
        };

        let editStore = this.props.editStore;

        const mailAccountId = editStore.getValue("mailAccountId");
        return (
            <Dialog
                open={editStore.inEdit}
                onClose={this.cancel}
                fullWidth
                maxWidth={"xl"}
            >
                <DialogTitle>E-Mail senden</DialogTitle>
                <DialogContent>
                    <EditStoreStaticField component={TextEditControl} {...editStoreInputFieldProps} title={"Absenderadresse"} valueGetter={(editStore) => mailAccountId != null ? this.props.stores.mailAccountsStore.getModelById(mailAccountId).attributes.adress : "Wird geladen..."} />
                    <EditStoreInputField component={TextEditControl} {...editStoreInputFieldProps} fieldName={"adress"} title={"Empfängeradresse"} />
                    <EditStoreInputField component={TextEditControl} {...editStoreInputFieldProps} fieldName={"subject"} title={"Betreff"} />
                    <EditStoreInputField component={HTMLEditControl} {...editStoreInputFieldProps} fieldName={"mailContent"} title={"Inhalt"} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={this.send} color="primary">
                        E-Mail senden
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}