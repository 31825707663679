import ModelCollectionManagerBase from "./modelCollectionManagerBase";
import $ from "jquery";
import {computed, observable} from "mobx";

class CollectionManager extends ModelCollectionManagerBase {

    @observable filterObject = null;
    data = [];

    getFilterObject() {
        return this.filterObject;
    }

    findModel(modelId) {
        if(this.data == null)
            return null;
        let found = this.data.find(model => model.attributes.id === modelId);
        return found;
    }

    @computed get allModels() {
        return !this.hasError() ? this.data : [];
    }

    @computed get filteredModels() {
        if(this.filterObject === null)
            return this.allModels;
        return this.allModels.filter((element) => this.filterObject.matches(element));
    }

    getValue(fieldName, modelId) {
        let model = this.findModel(modelId);
        if(model !== null)
            return model.attributes[fieldName];

        return null;
    }

    setModelValue(modelId, fieldName, value) {
        let self = this;
        this.data[fieldName] = value;

        let localCommandData = Object.assign({}, this.commandData); //copy this.commandData
        localCommandData.dbFieldName = fieldName;
        localCommandData = value;

        $.ajax({
            method: "POST",
            url: this.commandFilePath,
            data: localCommandData
        })
        .done(function(data) {
            if(data === 'success') {
                M.toast({html: 'Änderung erfolgreich gespeichert', displayDuration: 6000});
            } else {
                M.toast({html: 'Fehler beim speichern: '+data, displayDuration: 6000, classes:"red"});
            }
        })
        .always(function() {
            self.loadValues(); //Reload all data to make unsaved changes visible
            //self.notifyDependants(); Enable if loadvalues is disabled
        });
    }

    handleProviderRequestData(data) {
        this.data = data;
        super.handleProviderRequestData(data);
    }

}

module.exports = {
    CollectionManager
}