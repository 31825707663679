import * as React from "react";
import {Bar} from "react-chartjs-2";
import 'chartjs-plugin-colorschemes';
import {formatEuroValue} from "../reactUtils";

export default class AveragePricePerDayPerMonthChart extends React.Component {

    chartOptions = {
        plugins: {
            colorschemes: {
                scheme: "brewer.DarkTwo8"
            }
        },

        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: false,
                    callback: function(value, index, values) {
                        return value + " €";
                    }
                }
            }],
        },
        title: {
            display:true,
            text: "Durchschnittlicher Mietpreis pro Tag",
            fontSize: 20
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    data = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return formatEuroValue(data);
                }
            }
        }
    };

    render() {
        let data = this.props.data;
        return (
            <Bar data={data} options={this.chartOptions}/>
        );
    }
}