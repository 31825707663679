import * as React from "react";
import {observer} from "mobx-react";

@observer
export default class EditStoreStaticField extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        let editStore = this.props.editStore;
        let stores = this.props.stores;

        let title = this.props.title;
        let TagName = this.props.component;
        let blockOnEdit = this.props.blockOnEdit;
        let permanentInputMode = this.props.permanentInputMode;
        let value = " ";
        if(!editStore.inEdit || permanentInputMode) //Show empty field if other fields are edited because data integrity cannot be guaranteed if values are temporarily changed
            value = this.props.valueGetter != null ? this.props.valueGetter(editStore) : "";

        return (
            <TagName
                title={title}
                value={value}
                edit={false}
                blocked={editStore.inEdit && blockOnEdit}
                {...this.props}
            />
        );
    }

    handleChange(value) {
        let editStore = this.props.editStore;
        let fieldName = this.props.fieldName;

        editStore.modifyField(fieldName, value);
    }

}