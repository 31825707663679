import EventOverviewCard from "./EventOverviewCard";
import React from "react";
import {createData} from "./buchungOverviewCard";
import moment from "moment";
import {isEmptyOrSpacesOrNull} from "../reactUtils";

export default class BlockageOverviewCard extends React.Component {
    render() {
        let blockage = this.props.blockage;
        let apartment = this.props.stores.apartmentsStore.getModelById(blockage.attributes.apartmentFK);

        let dataRows = [
            createData("Ferienwohnung", apartment.attributes.name, true),
            createData("Anfang", moment(blockage.attributes.beginning).format("L"), true),
            createData("Ende", moment(blockage.attributes.end).format("L"), true),
            createData("Beschreibung", blockage.attributes.description, !isEmptyOrSpacesOrNull(blockage.attributes.description))
        ];

        return (
            <EventOverviewCard
                background={"#FAFAFA"}
                title={"Blockung: " + blockage.attributes.title}
                dataRows={dataRows}
                secondColumn={null}
                collapsedByDefault={false}
                detailsLinkUrl={"/#/blockage/" + blockage.attributes.id}
                {...this.props}
            />
        );
    }
}