import {autorun, observable, reaction, toJS, trace} from "mobx";
import $ from "jquery";
import LoginStateManager from "../js/src/LoginStateManager";

export const initialStoreObject = {
    isLoading: true,
    error: false,
    data: null
};

export default class Store {

    @observable data = {};
    @observable parameters = {};

    constructor() {
        this.doFetch = this.doFetch.bind(this);
        this.init = this.init.bind(this);

        this.init.apply(null, arguments);

        const r = reaction(
            () => Object.assign({}, this.parameters),
            (parameters) => {
                this.doFetch(this.parameters);
            }
        );
        this.doFetch(this.parameters);
        /*autorun((r) => {
            r.trace();
            this.doFetch(this.parameters);
        });*/
    }

    init() {
        //To be overwritten
    }


    fetch(resultObj, url, data, afterLoadCallback) {
        resultObj.isLoading = true;
        resultObj.error = false;

        $.ajax({
            method: "GET",
            url: url,
            data: data
        })
        .done((data) => {
            try {
                resultObj.data = JSON.parse(data);
                if(typeof resultObj.data === "string")
                    throw new Error();
                resultObj.isLoading = false;
                resultObj.error = false;
            } catch(err) {
                resultObj.data = data;
                resultObj.isLoading = false;
                resultObj.error = true;

                if(data === "noLogin")
                    LoginStateManager.getInstance().logoutAndRedirect();

            }
            if(typeof afterLoadCallback === "function")
                afterLoadCallback();
        }).fail((xhr, status, error) => {
            resultObj.isLoading = false;
            resultObj.error = true;
            if(typeof afterLoadCallback === "function")
                afterLoadCallback();
        });
    }

    doFetch(parameters, afterLoadCallback) {
        //To be overwritten
    }

    reload(afterLoadCallback) {
        this.doFetch(this.parameters, afterLoadCallback);
    }

}