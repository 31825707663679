import React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import PageTitleManager from "../js/src/pageTitleManager";
import EditStore from "../store/EditStore";
import {observer} from "mobx-react";
import BookingCreationSheet from "./BookingCreationSheet";
import BookingBlockageSelectionControl from "../form/BookingBlockageSelectionControl";
import Box from "@material-ui/core/Box";
import BlockageCreationSheet from "./BlockageCreationSheet";


export default observer(class ReservationCreationPage extends React.Component {

    constructor(props) {
        super(props);
        this.editStore = new EditStore();

        this.state = {
            reservationType: null
        };
    }

    componentDidMount() {
        this.props.drawer.setPageTitle("Reservierung erstellen");
        PageTitleManager.getInstance().setTitle("Reservierung erstellen");

        this.props.drawer.setToolbarItems(
            <React.Fragment>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Icon>save</Icon>}
                    onClick={() => this.editStore.stopEditingAndSave()}
                >Speichern</Button>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Box>
                <Box style={{marginBottom: "2em"}}>
                    <BookingBlockageSelectionControl value={this.state.reservationType} onValueChange={val => this.setState({reservationType: val})} edit />
                </Box>
                {
                    this.state.reservationType === "booking" &&
                        <BookingCreationSheet {...this.props} editStore={this.editStore}/>
                }
                {
                    this.state.reservationType === "blockage" &&
                        <BlockageCreationSheet {...this.props} editStore={this.editStore} />
                }
                {
                    !this.state.reservationType &&
                        <Box>Bitte wähle aus, ob du eine Buchung oder eine Blockierung für den Eigentümer erstellen möchtest</Box>
                }
            </Box>
        );
    }
})