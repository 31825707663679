import * as React from "react";
import TextEditControl from "./baseControls/TextEditControl";
import {observer} from "mobx-react";
import EditStoreStaticField from "./EditStoreStaticField";
import {formatEuroValue} from "../reactUtils";

@observer
export class ElectricitySumDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("stromKosten"), false)}
                adornment={"€"}
                title={"Stromkosten"}
            />
        );
    }
}

@observer
export class GasSumDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("gasKosten"), false)}
                adornment={"€"}
                title={"Gaskosten"}
            />
        );
    }
}

@observer
export class WaterSumDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("wasserKosten"), false)}
                adornment={"€"}
                title={"Wasserkosten"}
            />
        );
    }
}

@observer
export class UtilitiesSumDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("summeEnergieUndWasserkosten"), false)}
                adornment={"€"}
                title={"Nebenkosten für Energie und Wasser"}
            />
        );
    }
}