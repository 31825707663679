import {callCommand} from "./../js/src/commands";

export default class ExpenseCreationMemorizer {
    memorize(data, id) {
        callCommand(
            "createExpenseCmd.php",
            data,
            () => Promise.resolve()
        );
    }
}