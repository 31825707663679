import * as React from "react";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import {gotoPage} from "../reactUtils";

export default class EventOverviewCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    componentDidMount() {
        this.setState({
            expanded: !this.props.collapsedByDefault
        });
    }

    render() {


        let expanded = this.state.expanded || this.props.disableExpansion == true; //has to be like this to evaluate undefined to false, otherwise returns undefined

        let title = this.props.title;
        let dataRows = this.props.dataRows;
        let detailsLink = this.props.detailsLinkUrl;
        let secondColumn = this.props.secondColumn;
        let background = this.props.background;

        let style = { background };

        return (
            <React.Fragment>
                <Accordion style={style} expanded={expanded} onChange={(e, expanded) => this.setState({expanded: expanded})}>
                    <AccordionSummary expandIcon={!this.props.disableExpansion && <Icon>expand_more</Icon>}>
                        {expanded ? <Typography variant="h5">{title}</Typography> : title}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container style={{marginBottom: "20px"}}>
                            <Grid item xs={12} md={secondColumn ? 6 : 12} style={{paddingLeft: "10px", paddingRight: "10px"}}>
                                <Table size="small">
                                    <TableBody>
                                        {dataRows.filter(row => row.enabled).map(row => (
                                            <TableRow key={row.title}>
                                                <TableCell component="th" scope="row">
                                                    {row.title}
                                                </TableCell>
                                                <TableCell>{row.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            {
                                secondColumn &&
                                    <Grid item xs={12} md={6}>
                                        {secondColumn}
                                    </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        {
                            detailsLink &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => gotoPage(detailsLink)}
                                style={{marginRight: "auto"}}
                                startIcon={<Icon>open_in_new</Icon>}>
                                Details anzeigen
                            </Button>
                        }
                        {this.props.actions}
                    </AccordionActions>
                </Accordion>
            </React.Fragment>
        );
    }
}