import {observer} from "mobx-react";
import React from "react";
import EditStore from "../store/EditStore";
import {getToolbarItems, onKeyDown} from "../detailsPage/DetailsPageUtils";
import LoadingIndicator from "../LoadingIndicator";
import EditSaveFAB from "../form/EditSaveFAB";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {gotoPage, PageTitle, Spacer} from "../reactUtils";
import {MajorContentCard} from "../detailsPage/buchungDetailsPage";
import EditStoreInputField from "../form/EditStoreInputField";
import TextEditControl from "../form/baseControls/TextEditControl";
import DateSelectionControl from "../form/baseControls/DateSelectionControl";
import TextareaEditControl from "../form/baseControls/TextareaEditControl";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";
import PageTitleManager from "./../js/src/pageTitleManager";
import RESTModifyMemorizer from "../memorizer/RESTModifyMemorizer";
import BlockageValidationObject from "./BlockageValidationObject";
import {RESTDeleteCmd} from "../js/src/commands";
import {toast} from "react-toastify";


@observer
export default class BlockageDetailsPage extends React.Component {

    editStore = null;
    blockageId = null;

    constructor(props) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.editStore = new EditStore();
        this.editStore.simpleCollectionStore = props.stores.blockagesStore;
        this.editStore.memorizer = new RESTModifyMemorizer("blockage");
        this.editStore.validatorObject = new BlockageValidationObject(this.editStore);

    }

    onKeyDown(event) {
        onKeyDown(this.editStore, event);
    }

    componentDidMount() {
        let blockageId = parseInt(this.props.match.params.id);

        let stores = this.props.stores;

        this.editStore.modelId = blockageId;

        document.addEventListener("keydown", this.onKeyDown, false);

        this.props.drawer.setPageTitle("Blockungsdetails");
        this.props.drawer.setToolbarItems(() => getToolbarItems(
            this.editStore,
                async editStore => {
                    await RESTDeleteCmd("blockage", editStore.modelId);
                    editStore.refreshBaseStore();
                    toast.success("Blockierung gelöscht");
                    gotoPage("/#/calendar");
                },
            stores
            ));
        PageTitleManager.getInstance().setTitle("Blockungsdetails");
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    render() {
        let blockageId = this.editStore.modelId;
        let stores = this.props.stores;
        if(blockageId === null)
            return null;
        let blockage = this.editStore.dynamicModel;

        const formEditFieldsBasicProps = {
            stores: stores,
            editStore: this.editStore,
            tableName: "blockages",
            blockOnEdit: true
        };

        if(blockage == null)
            return (<LoadingIndicator/>);

        PageTitleManager.getInstance().setTitle("Blockungsdetails " + blockage.title);

        return (
            <React.Fragment>
                <EditSaveFAB editStore={this.editStore} />
                <Box width={1}>
                    <Grid item xs={12}>
                        {<PageTitle>Blockierung {blockage.title}</PageTitle>}
                        <BlockageDetailsCard {...formEditFieldsBasicProps} />
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

export class BlockageDetailsCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Allgemeines">
                <EditStoreInputField component={ApartmentsSelectionControl} {...this.props} fieldName={"apartmentFK"} />
                <EditStoreInputField component={TextEditControl} {...this.props} fieldName={"title"} />
                <Spacer/>
                <EditStoreInputField component={DateSelectionControl} {...this.props} fieldName={"beginning"} />
                <EditStoreInputField component={DateSelectionControl} {...this.props} fieldName={"end"} />
                <Spacer/>
                <EditStoreInputField component={TextareaEditControl} {...this.props} fieldName={"description"} />
            </MajorContentCard>
        );
    }
}