import moment from "moment";
import ValidationObject, {isEmpty, setCustomError, setErrorMayNotBeEmpty, setOk} from "../validationObjects/ValidationObject";

export default class BlockageValidationObject extends ValidationObject{

    checkField(inFieldName, outErrorObj) {
        let value = this.getValue(inFieldName);
        switch(inFieldName) {
            case "title":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                break;

            case "beginning":
            case "end":
                let beginningStr = this.getValue("beginning");
                let endStr = this.getValue("end");

                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);

                if(!isEmpty(beginningStr) && !isEmpty(endStr)) {
                    if(moment(beginningStr).isSameOrAfter(moment(endStr))) {
                        setCustomError("Anfang darf nicht nach Ende liegen", outErrorObj);
                    }
                }
                break;

            case "apartmentFK":
                if(isEmpty(value))
                    setErrorMayNotBeEmpty(outErrorObj);
                break;

            default:
                setOk(outErrorObj);
        }
    }

}