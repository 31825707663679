import React from "react";
import BookingTitle from "../bookingTitle";
import OccurrenceList from "../occurrenceList";
import moment from "moment";
import EventOverviewCard from "./EventOverviewCard";

export function createData(title, value, enabled) {
    return { title, value, enabled };
}

export function getFristRowString(date) {
    let ret = date.format("L");
    let daysDiff = date.diff(moment().startOf("day"), "days");
    if(daysDiff > 0)
        ret += " (noch " + daysDiff+" Tag(e))";
    else if(daysDiff === 0) {
        ret += " (heute fällig)";
    }
    else
        ret += " (" + (-daysDiff) + " Tag(e) überfällig)";
    return ret;
}

export default class BuchungOverviewCard extends React.Component {

    render() {
        let buchung = this.props.buchung;
        let buchungAttr = buchung.attributes;
        let dataRows = this.props["dataRows"];
        let collapsedByDefault = this.props.buchung.attributes.hasCancellation && this.props.buchung.attributes.occurrences.length === 0;

        return (
            <EventOverviewCard
                background={this.props.background}
                title={<BookingTitle buchung={buchung}/>}
                dataRows={dataRows}
                secondColumn={<OccurrenceList occurrences={buchungAttr.occurrences}/>}
                collapsedByDefault={collapsedByDefault}
                detailsLinkUrl={"/#/details/" + buchung.attributes.id}
                {...this.props}
            />
        );
    }
}