import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";
import Box from "@material-ui/core/Box";
import SeasonalStatistics from "./seasonalStatistics";
import GlobalStatistics from "./globalStatistics";
import PageTitleManager from "../js/src/pageTitleManager";
import AvailableApartmentSelector from "../availableApartmentSelector";
import StatisticsStore from "../store/statisticsStore";


export default class StatisticsPage extends React.Component {

    statisticsStore = null;

    constructor(props) {
        super(props);
        this.state = {
            selectedApartmentId: null
        };

        this.statisticsStore = new StatisticsStore();

        this.getSelectedApartmentId = this.getSelectedApartmentId.bind(this);
        this.onSelectedApartmentChange = this.onSelectedApartmentChange.bind(this);
    }

    componentDidMount() {
        this.props.drawer.setToolbarItems(null);
        this.props.drawer.setPageTitle("Statistiken");
        PageTitleManager.getInstance().setTitle("Statistiken");
    }

    getSelectedApartmentId() {
        if(this.state.selectedApartmentId === null || this.state.selectedApartmentId === "")
            return null;
        else
            return this.state.selectedApartmentId;
    }

    onSelectedApartmentChange(apartmentId) {
        this.statisticsStore.setApartment(apartmentId);
        this.setState({
            selectedApartmentId: apartmentId
        });
    }

    render() {
        return (
            <React.Fragment>
                <AvailableApartmentSelector presetFirst onModelChange={this.onSelectedApartmentChange}/>
                {
                    this.getSelectedApartmentId() !== null ?
                        <StatisticsTabBody statisticsStore={this.statisticsStore} apartment={this.getSelectedApartmentId()}/>
                        :
                        <Box>Bitte wähle eine Ferienwohnung aus</Box>
                }
            </React.Fragment>
        );
    }
}

class StatisticsTabBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "seasonal"
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(event, newValue) {
        this.setState({
            selectedTab: newValue
        });
    }


    render() {
        let statisticsStore = this.props.statisticsStore;
        return (
            <React.Fragment>
                <Paper square>
                    <Tabs
                        value={this.state.selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabChange}
                        centered
                    >
                        <Tab label="Saisonstatistiken" value="seasonal" />
                        <Tab label="Globale Statistiken" value="global" />
                    </Tabs>
                </Paper>

                <TabPanel value={this.state.selectedTab} index="seasonal">
                    {this.state.selectedTab === "seasonal" && <SeasonalStatistics statisticsStore={statisticsStore} apartment={this.props.apartment}/>}
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index="global">
                    {this.state.selectedTab === "global" && <GlobalStatistics statisticsStore={statisticsStore} apartment={this.props.apartment}/>}
                </TabPanel>
            </React.Fragment>
        );
    }
}