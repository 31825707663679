import {initialStoreObject} from "./store";
import SimpleCollectionStore from "./SimpleCollectionStore";
import {computedFn} from "mobx-utils";

export default class ApartmentConfigsStore extends SimpleCollectionStore {
    constructor() {
        super();
        this.data = initialStoreObject;
        this.fetch(
            this.data,
            "db/providers/apartmentConfigProvider.php",
            {},
        );
    }

    getByApartment = computedFn(function getByApartment(apartmentId) {
        return this.findByPredicate(element => element.attributes.apartmentFK === apartmentId);
    });

    getConfigValue(apartmentId, name) {
        let config = this.findByPredicate(element => element.apartmentFK === apartmentId && element.name === name);
        return config ? config.value : null;
    }

}