import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {observer} from "mobx-react";
import {toJS} from "mobx";
import {getFullName, getMonthYearTextString} from "../buchungUtils";
import {toast} from "react-toastify";
const stringSimilarity = require('string-similarity');


@observer
export default class BookingTemplateSelectionMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        };

        this.onButtonClick = this.onButtonClick.bind(this);
        this.getItems = this.getItems.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    getItems() {
        let editStore = this.props.editStore;
        let bookingsStore = this.props.stores.bookingsStore;
        let allBookings = bookingsStore.allItems;
        let nachname = editStore.getValue("nachname") || "";
        let scores = {};
        allBookings.forEach(booking => scores[booking.attributes.id] = stringSimilarity.compareTwoStrings(nachname, booking.attributes.nachname));
        let bookingWithSufficientScore = allBookings.filter(booking => scores[booking.attributes.id] > 0.5);
        allBookings = bookingWithSufficientScore.sort((a,b) => scores[b.attributes.id] - scores[a.attributes.id]); //Sort best match first
        return bookingWithSufficientScore.map(booking => {return {
            title: getFullName(booking) + " - " + getMonthYearTextString(booking),
            id: booking.attributes.id
        }});
    }

    onButtonClick(event) {
        let items = this.getItems();
        let noActions = items.length <= 0;
        if(noActions)
            toast.warn("Bitte gib den Nachnamen des Mieters zu dem du die Vorlage suchst ein");
        else
            this.setState({
                open: true,
                anchorEl: event.currentTarget
            });
    }

    onClose(event) {
        this.setState({
            open: false
        });
    }

    onAction(object) {
        let editStore = this.props.editStore;
        let bookingsStore = this.props.stores.bookingsStore;
        let booking = bookingsStore.getModelById(object.id);

        const valuesToCopy = ["anrede", "vorname", "nachname", "adresse", "telefonnummer", "email", "vornamen", "bankverbindung", "anzahlPersonen"];
        valuesToCopy.forEach(fieldName => editStore.modifyField(fieldName, booking.attributes[fieldName]));

        this.onClose();
    }

    render() {
        let items = this.getItems();
        return (
            <React.Fragment>
                <IconButton
                    onClick={this.onButtonClick}
                >
                    <Tooltip title={"Buchungsvorlage anwenden"}>
                        <Icon>{"content_copy"}</Icon>
                    </Tooltip>
                </IconButton>
                <Menu
                    keepMounted
                    open={this.state.open}
                    onClose={this.onClose}
                    anchorEl={this.state.anchorEl}
                >
                    {
                        items.map(item => <MenuItem key={item.title} onClick={() => this.onAction(item)}>{item.title}</MenuItem>)
                    }
                </Menu>
            </React.Fragment>
        );
    }
}