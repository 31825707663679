import {getApartmentId, getNachname, getSeason, getVorname, hasOccurrences} from "./buchungUtils";
import {observable} from "mobx";


function textContainsText(haystack, needle) {
    haystack = haystack.toUpperCase();
    needle = needle.toUpperCase();
    return haystack.includes(needle);
}

export default class BuchungFilterObject {

    @observable filterProperties = {};

    constructor(filterPropertiesStore) {
        this.filterPropertiesStore = filterPropertiesStore;
    }

    matches(buchung) {
        let filterProperties = this.filterPropertiesStore.data;
        let bookingSeason = getSeason(buchung);
        let allowedSeasons = filterProperties.seasons ?? [];
        if(!allowedSeasons.includes(bookingSeason))
            return false;

        let bookingApartment = getApartmentId(buchung);
        let allowedApartments = filterProperties.apartments;
        if(!allowedApartments.includes(bookingApartment))
            return false;

        let name = getVorname(buchung) + " " + getNachname(buchung);
        if(!(textContainsText(name, filterProperties.name)))
            return false;

        if(filterProperties.showOnlyWithOccurrences && !hasOccurrences(buchung))
            return false;

        return true;
    }

    setSeason(season) {
    }

    setApartment(apartment) {
    }

    setName(name) {
    }

    setShowOnlyWithOccurrences(val) {
    }

}