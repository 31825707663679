import UserManager from "./userManager";
import {gotoPage} from "../../reactUtils";
import {observable} from "mobx";

export default class LoginStateManager {

    static instance = null;

    static init() {
        LoginStateManager.instance = new LoginStateManager();
    }

    static getInstance() {
        return this.instance;
    }

    getCurrentUserManager() {
        return this.currentUserManager;
    }

    @observable isLoggedIn;
    @observable isOnLoginFormPage;
    @observable loginInfoAvailable;

    constructor() {
        this.currentUserManager = new UserManager();
        this.currentUserManager.addDependant(this);

        this.isLoggedIn = false;
        this.isOnLoginFormPage = false;
        this.loginInfoAvailable = false;

        this.onModelUpdate = this.onModelUpdate.bind(this);
        this.getCurrentUserManager = this.getCurrentUserManager.bind(this);
        this.setLoggedIn = this.setLoggedIn.bind(this);
    }

    logoutAndRedirect() {
        this.setLoggedIn(false);
    }

    onModelUpdate() {
        console.log("model update", this.currentUserManager);
        this.loginInfoAvailable = true;
        this.isLoggedIn = this.currentUserManager.isLoggedIn();
    }

    setLoggedIn(state) {
        this.isLoggedIn = state;
    }

    setIsOnLoginFormPage(state) {
        this.isOnLoginFormPage = state;
    }

}