import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {observer} from "mobx-react";
import DynamicStoreInputField from "../form/DynamicStoreInputField";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";
import IntegerEditControl from "../form/baseControls/IntegerEditControl";
import TextEditControl from "../form/baseControls/TextEditControl";
import CheckboxControl from "../form/baseControls/CheckboxControl";
import SeasonsSelectionControl from "../form/SeasonsSelectionControl";
import {autorun, toJS} from "mobx";
import moment from "moment";
import StringSearchEditControl from "../form/StringSearchEditControl";
import SwitchControl from "../form/baseControls/SwitchControl";
import {getAbreiseMoment, getAnreiseMoment} from "../buchungUtils";
import {argMaxPredicate} from "../JSArrayUtils";

@observer
export default class ViewFilterCard extends React.Component {

    constructor(props) {
        super(props);

        autorun((reaction) => {
            let viewPageStore = props.stores.viewPageStore;
            let bookingsStore = props.stores.bookingsStore;
            let apartmentsStore = props.stores.apartmentsStore;

            let seasons = bookingsStore.allSeasons;
            let apartments = apartmentsStore.allItems;

            if(!viewPageStore.getValue("seasonsInitialized") && seasons.length > 0) {
                const currentYear = moment().year();
                const bookingOfCurrentYear = bookingsStore.allItems.filter(booking => getAnreiseMoment(booking).year() === currentYear);
                const lastBookingOfCurrentYear = argMaxPredicate(bookingOfCurrentYear, booking => getAbreiseMoment(booking).dayOfYear());
                const startWithNextYearDeadline = getAbreiseMoment(lastBookingOfCurrentYear).add(4, "weeks");
                let startYear = null;
                if(moment().startOf("day") > startWithNextYearDeadline)
                    startYear = currentYear + 1;
                else
                    startYear = currentYear;

                viewPageStore.modifyField("seasons", seasons.filter(season => season === startYear)); //Pre-select current season
                viewPageStore.modifyField("seasonsInitialized", true);
            }

            if(!viewPageStore.getValue("apartmentsInitialized") && apartments.length > 0) {
                viewPageStore.modifyField("apartments", apartments.map(apartment => apartment.attributes.id)); //Pre-select all apartments
                viewPageStore.modifyField("apartmentsInitialized", true);
            }

            if(viewPageStore.getValue("apartmentsInitialized") && viewPageStore.getValue("seasonsInitialized"))
                reaction.dispose();
        });

        this.getSeasons = this.getSeasons.bind(this);
        this.getApartments = this.getApartments.bind(this);
    }

    getSeasons() {
        let seasons = [];
        seasons.push([0, "Alle Saisons"]);
        seasons = seasons.concat(
            this.props.stores.bookingsStore.allSeasons.map(
                season =>
                    [season, season]
            )
        );
        return seasons;
    }

    getApartments() {
        let apartments = [];
        apartments.push([0, "Alle Apartments"]);
        apartments = apartments.concat(
            this.props.stores.apartmentsStore.allItems.map(
                element =>
                    [element.attributes.id, element.attributes.name]
            )
        );
        return apartments;
    }

    render() {
        let stores = this.props.stores;

        const inputFieldProps = {
            stores: stores,
            dynamicStore: stores.viewPageStore
        };


        return (
                <Box
                    style={{
                        marginBottom: "40px"
                    }}
                >
                    <Grid container style={{marginBottom: "15px"}}>
                        <Grid item xs={12} lg={6}>
                            <Box style={{marginLeft: "6px", marginRight: "6px"}}>
                                <DynamicStoreInputField fieldName={"apartments"} title="Ferienwohnung" component={ApartmentsSelectionControl} multiple {...inputFieldProps} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box style={{marginLeft: "6px", marginRight: "6px"}}>
                                <DynamicStoreInputField fieldName={"seasons"} title="Saison" component={SeasonsSelectionControl} {...inputFieldProps} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box style={{marginLeft: "6px", marginRight: "6px"}}>
                                <DynamicStoreInputField fieldName={"name"} title="Filtern nach Namen" component={StringSearchEditControl} {...inputFieldProps} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box style={{marginLeft: "6px", marginRight: "6px"}}>
                                <DynamicStoreInputField fieldName={"showOnlyWithOccurrences"} title="Buchungen ohne Ereignisse ausblenden" component={SwitchControl} {...inputFieldProps} height={"80px"} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            let todayIndicator = document.getElementById("todayIndicator");
                            if(todayIndicator != null)
                                todayIndicator.scrollIntoView();
                        }}
                        startIcon={<Icon>today</Icon>}
                    >
                        Nach heute springen
                    </Button>
                </Box>
        );
    }
}