import React from "react";
import SelectEditControl from "./baseControls/SelectEditControl";
import {observer} from "mobx-react";

@observer
export default class BookingBlockageSelectionControl extends React.Component {
    render() {
        return (
            <SelectEditControl {...this.props} title={"Reservierungsart auswählen"} selectableValues={[["booking","Buchung"],["blockage", "Blockierung für den Eigentümer"]]} />
        );
    }
}