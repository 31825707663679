import React from "react";
import moment from "moment";
import 'moment/locale/de';
import {getFullName, getMonthYearTextString} from "./buchungUtils";

moment.locale('de');

export default class BookingTitle extends React.Component {
    render() {
        if(typeof this.props.buchung === "undefined" || this.props.buchung === null)
            return null;

        return (
            <React.Fragment>
                {
                    getFullName(this.props.buchung)
                    + " - "
                    + getMonthYearTextString(this.props.buchung)
                    + (this.props.buchung.attributes["hasCancellation"] ? " [storniert]" : "")
                }
            </React.Fragment>
        );
    }
}
