import * as React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import 'moment/locale/de';
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as accounting from "accounting-js";

moment.locale('de');

export function nl2br(text) {
    return (
        text.split('\n').map((item, i) => {
            return <p key={i}>{item}</p>;
        })
    );

}

export class BoldText extends React.Component {
    render() {
        return (
            <Box component="span" fontWeight="fontWeightBold">{this.props.children}</Box>
        );
    }
}

export class Spacer extends React.Component {
    render() {
        return (
            <Box style={{height: "15px"}}/>
        );
    }
}

export class PageTitle extends React.Component {
    render() {
        return (
            <Grid item xs={12}>
                <Typography variant="h3" style={{marginTop: "10px", marginBottom: "30px"}}>
                    {this.props.children}
                </Typography>
            </Grid>
        );
    }
}

let keyCounter = 1;
export function getUniqueKey() {
    return keyCounter++;
}

let formatters = {
    default: new Intl.NumberFormat(),
    germanEuro: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    germanAuto: new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    germanOneDecimal: new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 }),
    germanTwoDecimals: new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })
};

export function formatGermanFloat(numericValue, precision = null) {
    if(!precision)
        return formatters.germanAuto.format(numericValue);
    else
        return (new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: precision, maximumFractionDigits: precision })).format(numericValue);
}

export function formatEuroValue(numericValue, appendEuroSign = true) {
    return formatGermanFloat(numericValue, 2) + (appendEuroSign ? "€" : "");
}

export function parseGermanFloatValue(stringValue) {
    return accounting.unformat(stringValue, ",");
}

export function parseEuroValue(stringValue) {
    return parseGermanFloatValue(stringValue);
}

export function isEmptyOrSpacesOrNull(str){
    return str == null || str.match(/^ *$/) !== null;
}

export function formatPercentageValue(numericValue, appendPercentageSign = true) {
    return accounting.formatNumber(numericValue, {precision: 2, thousand: ".", decimal: ","}) + (appendPercentageSign ? "%" : "");
}

export function getYearFromDate(date) {
    return moment(date).year();
}

export function isEmptyOrNull(str) {
    return (str === null || 0 === str.length);
}

export function getCurrentSeason() {
    return new Date().getFullYear();
}

export function removeAllCharsButDigits(str) {
    return str.replace(/\D/g, '');
}

export function reactBackslashNToLineBreak(str) {
    return str.split ('\n').map ((item, i) => <p style={{margin: 0}} key={i}>{item}</p>);
}

export function scrollToTop() {
    window.scrollTo(0,0);
}

export function gotoPage(pageUrl, gotoTop = true) {
    window.location.href = pageUrl;
    if(gotoTop)
        scrollToTop();
        //window.setTimeout(scrollToTop, 4000);
}