import React from "react";
import {MajorContentCard} from "./buchungDetailsPage";
import * as ReactUtils from "../reactUtils";
import OccurrenceList from "../occurrenceList";
import DocumentGeneratorsList from "./documentGeneratorsList";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import BuchungHistoryList from "./buchungHistoryList";
import EditStoreInputField from "../form/EditStoreInputField";
import TextEditControl from "../form/baseControls/TextEditControl";
import PriceEditControl from "../form/baseControls/PriceEditControl";
import GenderSelectionControl from "../form/GenderSelectionControl";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";
import DateSelectionControl from "../form/baseControls/DateSelectionControl";
import IntegerEditControl from "../form/baseControls/IntegerEditControl";
import CheckboxControl from "../form/baseControls/CheckboxControl";
import FloatEditControl from "../form/baseControls/FloatEditControl";
import TextareaEditControl from "../form/baseControls/TextareaEditControl";
import TravelDurationDisplayControl from "../form/TravelDurationDisplayControl";
import {FinalPaymentRentDisplayControl, FinalPaymentTotalDisplayControl} from "../form/FinalPaymentDisplayControls";
import {
    TotalCostsExclDepositDisplayControl,
    TotalCostsInclDepositDisplayControl
} from "../form/TotalCostsDisplayControls";
import CancellationDateArrivalDaysDifferenceDisplayControl
    from "../form/CancellationDateArrivalDaysDifferenceDisplayControl";
import ReceivedPaymentsSumDisplayControl from "../form/ReceivedPaymentsSumDisplayControl";
import CancellationRefundSumDisplayControl from "../form/CancellationRefundSumDisplayControl";
import {
    ElectricitySumDisplayControl,
    GasSumDisplayControl,
    UtilitiesSumDisplayControl,
    WaterSumDisplayControl
} from "../form/UtilitiesSumDisplayControls";
import DepositRefundDisplayControl from "../form/DepositRefundDisplayControl";
import {observer} from "mobx-react";

export class PersonalInformationCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Persönliche Informationen">
                <EditStoreInputField component={GenderSelectionControl} {...formEditFieldsBasicProps} fieldName={"anrede"} />
                <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"vorname"} />
                <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"nachname"} />
                <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"adresse"} />
                <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"bankverbindung"} />
                <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"email"} />
                <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"telefonnummer"} />
                <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"vornamen"} />
            </MajorContentCard>
        );
    }
}

export class TravelDataCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Reisedaten">
                <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"anreise"} />
                <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"abreise"} />
                <TravelDurationDisplayControl {...formEditFieldsBasicProps} />
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"extendOneDayForCleaning"} />
                <EditStoreInputField component={IntegerEditControl} {...formEditFieldsBasicProps} fieldName={"anzahlPersonen"} />
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"sofortigeRestzahlung"} />
            </MajorContentCard>
        );
    }
}

export class PricingCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Preise und fixe Nebenkosten">
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"energieBezahlen"} />
                <ReactUtils.Spacer/>

                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"mietpreis"} />
                <ReactUtils.Spacer/>

                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"anzahlung"} />
                <ReactUtils.Spacer/>

                <FinalPaymentRentDisplayControl {...formEditFieldsBasicProps} />
                <FinalPaymentTotalDisplayControl {...formEditFieldsBasicProps} />
                <ReactUtils.Spacer/>

                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"kaution"} />
                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"endreinigung"} />
                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"touristensteuer"} />
                <ReactUtils.Spacer/>

                <TotalCostsExclDepositDisplayControl {...formEditFieldsBasicProps} />
                <TotalCostsInclDepositDisplayControl {...formEditFieldsBasicProps} />
            </MajorContentCard>
        );
    }
}

@observer
export class CancellationCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        let editStore = this.props.editStore;
        return (
            <MajorContentCard title="Stornierung">
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"hasCancellation"} />
                {
                    editStore.getValue("hasCancellation") &&
                    <React.Fragment>
                        <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"cancellationDate"} />
                        <CancellationDateArrivalDaysDifferenceDisplayControl {...formEditFieldsBasicProps} />
                        <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"cancellationFee"} />
                        <ReceivedPaymentsSumDisplayControl {...formEditFieldsBasicProps} />
                        <CancellationRefundSumDisplayControl {...formEditFieldsBasicProps} />
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"cancellationFeeRefunded"} />
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"cancellationFeeUsedAsCredit"} />
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"cancellationVerwalterInformed"} />
                    </React.Fragment>
                }
            </MajorContentCard>
        );
    }
}

export class ApartmentCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Ferienwohnung">
                <EditStoreInputField component={ApartmentsSelectionControl} {...formEditFieldsBasicProps} fieldName={"apartmentFK"} />
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"zeitraumGeblockt"} />
            </MajorContentCard>
        );
    }
}

export class OccurrenceCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        let booking = this.props.buchung;
        return (
            <MajorContentCard title="Ereignisse und Aufgaben">
                <OccurrenceList occurrences={booking != null ? booking.attributes.occurrences : null}/>
            </MajorContentCard>
        );
    }
}

export class KeysafeCard extends React.Component {
    render() {
        let booking = this.props.booking;
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Schlüsseltresor">
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"hasKeysafe"} />
                {
                    booking.attributes.hasKeysafe &&
                    <React.Fragment>
                        <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"keysafeName"} />
                        <EditStoreInputField component={TextEditControl} {...formEditFieldsBasicProps} fieldName={"keysafeCode"} />
                        <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"keysafeForwardedToMieter"} />
                    </React.Fragment>
                }
            </MajorContentCard>
        );
    }
}

export class DocumentGenerationCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        let booking = this.props.buchung;
        return (
            <MajorContentCard title="Dokumentengenerierung">
                <DocumentGeneratorsList stores={this.props.stores} editStore={this.props.editStore} bookingAuxiliaryStore={this.props.bookingAuxiliaryStore} bookingId={booking.attributes.id} />
            </MajorContentCard>
        );
    }
}

export class MiscancellousCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Allgemeines">
                <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"kommentar"} />
                <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"bookedAt"} />
                <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"custodianSpecialRequest"} />
            </MajorContentCard>
        );
    }
}

export class PaymentStatusCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Zahlungsstatus">
                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"anzahlung"} />
                <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"anzahlungBis"} />
                <ReactUtils.Spacer/>
                <FinalPaymentTotalDisplayControl {...formEditFieldsBasicProps} />
                <EditStoreInputField component={DateSelectionControl} {...formEditFieldsBasicProps} fieldName={"restzahlungBis"} />
                <ReactUtils.Spacer/>
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"anzahlungErhalten"} />
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"restzahlungErhalten"} />
                <ReceivedPaymentsSumDisplayControl {...formEditFieldsBasicProps} />
            </MajorContentCard>
        );
    }
}

export class EnergyCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        let booking = this.props.booking;
        return (
            <MajorContentCard title="Energieverbrauch">
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"energieBezahlen"} />
                {
                    booking.attributes.energieBezahlen &&
                    <React.Fragment>
                        <ReactUtils.Spacer/>

                        <Accordion>
                            <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                                <Typography>Energie- und Wasserpreise</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box width={1}>
                                    <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"strompreis"} />
                                    <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"gaspreis"} />
                                    <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"wasserpreis"} />
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <ReactUtils.Spacer/>
                        <EditStoreInputField component={FloatEditControl} {...formEditFieldsBasicProps} fieldName={"stromverbrauch"} adornment={"kWh"} />
                        <ElectricitySumDisplayControl {...formEditFieldsBasicProps} />
                        <EditStoreInputField component={FloatEditControl} {...formEditFieldsBasicProps} fieldName={"gasverbrauch"} adornment={"m³"} />
                        <GasSumDisplayControl {...formEditFieldsBasicProps} />
                        <EditStoreInputField component={FloatEditControl} {...formEditFieldsBasicProps} fieldName={"wasserverbrauch"} adornment={"m³"} />
                        <WaterSumDisplayControl {...formEditFieldsBasicProps} />
                        <ReactUtils.Spacer/>
                        <UtilitiesSumDisplayControl {...formEditFieldsBasicProps} />
                    </React.Fragment>
                }
            </MajorContentCard>
        );
    }
}

export class DepositCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Kaution">
                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"kaution"} />
                <UtilitiesSumDisplayControl {...formEditFieldsBasicProps} />
                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"additionalDepositBill"} />
                <ReactUtils.Spacer/>
                <DepositRefundDisplayControl {...formEditFieldsBasicProps} />
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"kautionErstattet"} />
            </MajorContentCard>
        );
    }
}

export class CustodianCard extends React.Component {
    render() {
        const formEditFieldsBasicProps = this.props.formEditFieldsBasicProps;
        return (
            <MajorContentCard title="Verwalter">
                <EditStoreInputField component={PriceEditControl} {...formEditFieldsBasicProps} fieldName={"provision"} />
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"verwalterBestaetigung"} />
                <EditStoreInputField component={CheckboxControl} {...formEditFieldsBasicProps} fieldName={"verwalterBezahlt"} />
                <EditStoreInputField component={TextareaEditControl} {...formEditFieldsBasicProps} fieldName={"custodianSpecialRequest"} />
            </MajorContentCard>
        );
    }
}

export class BookingHistoryCard extends React.Component {
    render() {
        let bookingHistory = this.props.bookingHistory;
        return (
            <MajorContentCard title="Änderungshistorie">
                <BuchungHistoryList data={bookingHistory} stores={this.props.stores}/>
            </MajorContentCard>
        );
    }
}