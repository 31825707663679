import ConfigurationManager from "../js/src/configurationManager";
import * as React from "react";
import {MajorContentCard} from "../detailsPage/buchungDetailsPage";
import {TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export default class ApartmentConfigurationSheet extends React.Component {
    constructor(props) {
        super(props);
        this.configurationManager = new ConfigurationManager(null);
        this.state = {
            configData: null
        }
    }

    componentDidMount() {
        this.configurationManager.addDependant(this);
        this.configurationManager.setApartment(this.props.apartmentId);
    }

    componentWillUnmount() {
        this.configurationManager.removeDependant(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.apartmentId !== this.props.apartmentId)
            this.configurationManager.setApartment(this.props.apartmentId);
    }

    onModelUpdate() {
        this.setState({
            configData: this.configurationManager.getConfigsByApartment()
        });
    }

    render() {
        return (
            <MajorContentCard>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>Einstellung</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Datentyp</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Wert</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.configData != null &&
                                this.state.configData.map(
                                    element =>
                                        <TableRow key={element.name}>
                                            <TableCell>{element.name}</TableCell>
                                            <TableCell>{element.type}</TableCell>
                                            <TableCell>{element.value}</TableCell>
                                        </TableRow>
                                )
                        }
                    </TableBody>
                </Table>
            </MajorContentCard>
        );
    }

}