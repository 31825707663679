import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import {gotoPage, PageTitle} from "../reactUtils";
import BookingTitle from "../bookingTitle";
import PageTitleManager from "../js/src/pageTitleManager";
import {getFullName} from "../buchungUtils";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";
import {
    ApartmentCard,
    BookingHistoryCard,
    CancellationCard,
    CustodianCard,
    DepositCard,
    DocumentGenerationCard,
    EnergyCard,
    KeysafeCard,
    MiscancellousCard,
    OccurrenceCard,
    PaymentStatusCard,
    PersonalInformationCard,
    PricingCard,
    TravelDataCard
} from "./detailsPageInfoCards";
import {observer} from "mobx-react";
import EditStore from "../store/EditStore";
import BookingMemorizer from "../memorizer/BookingMemorizer";
import LoadingIndicator from "../LoadingIndicator";
import BookingAuxiliaryStore from "../store/BookingAuxiliaryStore";
import EditSaveFAB from "../form/EditSaveFAB";
import BuchungValidationObject from "../validationObjects/buchungValidationObject";
import {getToolbarItems, onKeyDown} from "./DetailsPageUtils";
import {callDeleteBuchungCmd} from "../js/src/commands";

export default observer(class BuchungDetailsPageContent extends React.Component {

    editStore = null;
    bookingAuxiliaryStore = null;
    bookingId = null;

    constructor(props) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onKeyDown(event) {
        onKeyDown(this.editStore, event);
    }

    componentDidMount() {
        let buchungId = this.props.match.params.buchungId;
        this.bookingId = parseInt(buchungId);

        let stores = this.props.stores;
        let bookingsStore = stores.bookingsStore;

        this.editStore = new EditStore();
        this.editStore.simpleCollectionStore = bookingsStore;
        this.editStore.modelId = parseInt(buchungId);
        this.editStore.memorizer = new BookingMemorizer();
        this.editStore.validatorObject = new BuchungValidationObject(this.editStore);

        this.bookingAuxiliaryStore = new BookingAuxiliaryStore(this.editStore);

        document.addEventListener("keydown", this.onKeyDown, false);

        this.props.drawer.setPageTitle("Buchungsdetails");
        this.props.drawer.setToolbarItems(() => getToolbarItems(
            this.editStore,
            editStore => {
                callDeleteBuchungCmd(editStore.modelId, () => {
                    editStore.refreshBaseStore();
                    gotoPage("/#/view");
                });
            },
            stores
        ));
        PageTitleManager.getInstance().setTitle("Buchungsdetails");
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    render() {
        let bookingId = this.bookingId;
        let stores = this.props.stores;
        let bookingsStore = stores.bookingsStore;
        if(bookingId === null)
            return null;
        let staticBooking = bookingsStore.getModelById(bookingId);
        let buchungHistory = this.bookingAuxiliaryStore.data.history.data;
        buchungHistory = buchungHistory != null ? buchungHistory : [];

        const formEditFieldsBasicProps = {
            stores: stores,
            editStore: this.editStore,
            tableName: "buchungen",
            blockOnEdit: true
        };

        if(staticBooking == null)
            return (<LoadingIndicator/>);

        PageTitleManager.getInstance().setTitle("Buchungsdetails " + getFullName(staticBooking));

        return (
            <React.Fragment>
                <EditSaveFAB editStore={this.editStore} />
                <Box width={1}>
                    <Grid item xs={12}>
                        {<PageTitle>
                            <BookingTitle buchung={staticBooking}/>
                        </PageTitle>}
                        <DetailsPageTabBody
                            editStore={this.editStore}
                            bookingAuxiliaryStore={this.bookingAuxiliaryStore}
                            buchung={staticBooking}
                            bookingHistory={buchungHistory}
                            stores={this.props.stores}
                            formEditFieldsBasicProps={formEditFieldsBasicProps}
                        />
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
})

const DetailsPageTabBody = observer(class DetailsPageTabBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "general"
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(event, newValue) {
        this.setState({
            selectedTab: newValue
        });
    }


    render() {
        let selectedTab = this.state.selectedTab;
        return (
            <Box>
                <Paper square>
                    {<Tabs
                        value={this.state.selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabChange}
                        variant={"scrollable"}
                        scrollButtons={"on"}
                    >
                        <Tab label="Allgemeines" value="general" />
                        <Tab label="Vor Aufenthalt" value="preArrival" />
                        <Tab label="Kaution und Verwalter" value="postDeparture" />
                        {
                            //<Tab label="Alles anzeigen" value="all" />
                        }
                        <Tab label="Historie" value="history" />
                    </Tabs>}
                </Paper>
                <Box style={{paddingTop: "15px"}}>
                    <TabPanel value={this.state.selectedTab} index="general">
                        {selectedTab === "general" && <GeneralTab {...this.props}/>}
                    </TabPanel>
                    <TabPanel value={this.state.selectedTab} index="preArrival">
                        {selectedTab === "preArrival" && <PreHolidaysTab {...this.props}/>}
                    </TabPanel>
                    <TabPanel value={this.state.selectedTab} index="postDeparture">
                        {selectedTab === "postDeparture" && <PostHolidaysTab {...this.props}/>}
                    </TabPanel>
                    <TabPanel value={this.state.selectedTab} index="all">
                        {selectedTab === "all" && <ShowAllTab {...this.props}/>}
                    </TabPanel>
                    <TabPanel value={this.state.selectedTab} index="history">
                        {selectedTab === "history" && <HistoryTab {...this.props}/>}
                    </TabPanel>
                </Box>
            </Box>
        );
    }
})

class DetailsPageBasicLayout extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        {this.props.firstColumn}
                    </Grid>
                    <Grid  item xs={12} lg={6}>
                        {this.props.secondColumn}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

class GeneralTab extends React.Component {
    render() {
        let buchung = this.props.buchung;
        return (
            <DetailsPageBasicLayout
                firstColumn={
                    <React.Fragment>
                        <PersonalInformationCard {...this.props}/>
                        <TravelDataCard {...this.props}/>
                    </React.Fragment>
                }
                secondColumn={
                    <React.Fragment>
                        <OccurrenceCard {...this.props} />
                        <ApartmentCard {...this.props} />
                        <MiscancellousCard {...this.props} />
                        <PricingCard {...this.props} />
                    </React.Fragment>
                }
            />
        );
    }
}

class PreHolidaysTab extends React.Component {
    render() {
        let buchung = this.props.buchung;

        return (
            <DetailsPageBasicLayout
                firstColumn={
                    <React.Fragment>
                        <PaymentStatusCard {...this.props} />
                        <KeysafeCard booking={buchung} {...this.props} />
                    </React.Fragment>
                }
                secondColumn={
                    <React.Fragment>
                        <OccurrenceCard booking={buchung} {...this.props} />
                        <CancellationCard {...this.props} booking={buchung} />
                        <DocumentGenerationCard {...this.props} />
                    </React.Fragment>
                }
            />
        );
    }
}

class PostHolidaysTab extends React.Component {
    render() {
        let buchung = this.props.buchung;

        return (
            <DetailsPageBasicLayout
                firstColumn={
                    <React.Fragment>
                        <MiscancellousCard {...this.props} />
                        <EnergyCard booking={buchung} {...this.props} />
                    </React.Fragment>
                }
                secondColumn={
                    <React.Fragment>
                        <OccurrenceCard booking={buchung} {...this.props}/>
                        <DepositCard booking={buchung} {...this.props} />
                        <CustodianCard {...this.props} />
                    </React.Fragment>
                }
            />
        );
    }
}

class ShowAllTab extends React.Component {
    render() {

        let buchung = this.props.buchung;
        let buchungHistory = this.props.buchungHistory;

        return (
            <DetailsPageBasicLayout
                booking={buchung}
                firstColumn={
                    <React.Fragment>
                        <PersonalInformationCard {...this.props}/>
                        <TravelDataCard {...this.props}/>
                        <PricingCard {...this.props} />
                        <CancellationCard {...this.props} booking={buchung} />
                    </React.Fragment>
                }
                secondColumn={
                    <React.Fragment>
                        <ApartmentCard {...this.props} />
                        <OccurrenceCard booking={buchung} {...this.props} />
                        <KeysafeCard booking={buchung} {...this.props} />
                        <DocumentGenerationCard {...this.props} />
                        <MiscancellousCard {...this.props} />
                        <PaymentStatusCard {...this.props} />
                        <EnergyCard booking={buchung} {...this.props} />
                        <DepositCard booking={buchung} {...this.props} />
                        <CustodianCard {...this.props} />
                        <BookingHistoryCard bookingHistory={buchungHistory} />
                    </React.Fragment>
                }
            />
        );
    }
}

class HistoryTab extends React.Component {
    render() {
        let bookingHistory = this.props.bookingHistory;

        return (
            <BookingHistoryCard bookingHistory={bookingHistory} stores={this.props.stores} />
        );
    }
}

export class MajorContentCard extends React.Component {
    render() {
        return (
            <Card style={{
                marginBottom: "15px",
                marginLeft: "10px",
                marginRight: "10px",
                background: "#FAFAFA"
            }}>
                {
                    this.props.title != null &&
                        <CardHeader title={this.props.title}/>
                }
                <CardContent>
                    {this.props.children}
                </CardContent>
            </Card>
        );
    }
}
