import * as React from "react";
import TextEditControl from "./baseControls/TextEditControl";
import {observer} from "mobx-react";
import EditStoreStaticField from "./EditStoreStaticField";
import {formatEuroValue} from "../reactUtils";

@observer
export default class DepositRefundDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(editStore) => formatEuroValue(editStore.getValue("depositRefundSum"), false)}
                adornment={"€"}
                title={"Zurückzuerstattende Kaution"}
            />
        );
    }
}