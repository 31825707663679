import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import ToolbarRefreshButton from "../ToolbarRefreshButton";

export function getToolbarItems(editStore, onDeleteFunction, stores) {
    return (
        <React.Fragment>
            <ToolbarRefreshButton stores={stores} />
            {
                !editStore.inEdit && <React.Fragment>
                    <IconButton
                        onClick={() => {
                            editStore.startEditing();
                        }}
                        style={{color: "white"}}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </React.Fragment>
            }

            {
                editStore.inEdit && <React.Fragment>
                    <IconButton
                        onClick={() => {
                            editStore.stopEditingAndDiscard();
                        }}
                        style={{color: "white"}}
                    >
                        <Icon>cancel</Icon>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            editStore.stopEditingAndSave();
                        }}
                        style={{color: "white"}}
                    >
                        <Icon>save</Icon>
                    </IconButton>
                </React.Fragment>
            }

            {
                !editStore.inEdit && <React.Fragment>
                    <IconButton
                        onClick={() => {
                            let confirmDelete = window.confirm("Möchtest Du diese Reservierung wirklich endgültig löschen?");
                            if(confirmDelete)
                                onDeleteFunction(editStore);
                        }}
                        style={{color: "white"}}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                </React.Fragment>
            }

        </React.Fragment>
    );
}

export function onKeyDown(editStore, event) {
    if(event.ctrlKey && event.keyCode === 66) { //B
        editStore.startEditing();
    }
    /*
    if(event.keyCode === 27) //Esc
        editStore.stopEditingAndDiscard();

    if(event.keyCode === 13) //Enter
        editStore.stopEditingAndSave();
    */
}