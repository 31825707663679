import {toast} from "react-toastify";
import {callCommand} from "./js/src/commands";

export default class BaseMemorizer {

    constructor(cmdName) {
        this.cmdName = cmdName;
    }

    memorize(data, bookingId) {
        let promises = [];
        Object.keys(data).forEach((columnName) => {
            callCommand(
                this.cmdName,
                {
                    id: bookingId,
                    dbFieldName: columnName,
                    value: data[columnName]
                },
                (data) => {
                    if(data === "success")
                        toast.success("Speichern erfolgreich");
                    else {
                        if(data === "noLogin")
                            toast.error("Nicht gespeichert: Du bist nicht (mehr) eingeloggt");
                        else
                            toast.error("Fehler beim speichern: " + data);
                    }
                    return Promise.resolve();
                }
            );
        });
    }
}