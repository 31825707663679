import {toast} from "react-toastify";
import RESTMemorizer from "./RESTMemorizer";
import LoginStateManager from "../js/src/LoginStateManager";

export default class RESTCreateMemorizer extends RESTMemorizer{

    async memorize(keyValPairs, modelId) {
        let response = await fetch(
            `api/${this.apiObjectName}/create.php`,
            {
                method: "POST",
                body: JSON.stringify(keyValPairs)
            }
        );
        switch(response.status) {
            case 200:
                toast.success("Erstellen erfolgreich");
                let resJson = await response.json();
                if(this.afterSaveCallback)
                    this.afterSaveCallback(resJson);
                return Promise.resolve(resJson);
            case 400:
                toast.error("Eingabe ungültig: " + await response.text());
                return Promise.reject();
            case 403:
                toast.error("Fehlende Berechtigung: " + await response.text());
                return Promise.reject();
            default:
                toast.error("Es ist ein Fehler aufgetreten: " + await response.text());
                return Promise.reject();
        }
    }
}