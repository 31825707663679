import {CollectionManager} from "./collectionManager";

export default class BuchungHistoryManager extends CollectionManager {

    constructor(mode, modelId) {
        let providerData = {};
        switch(mode) {
            case "buchung":
                providerData = {
                    mode: "buchung",
                    buchungId: modelId
                };
                break;
            case "user":
                providerData = {
                    mode: "user",
                    userId: modelId
                };
                break;
            default:
                console.error("No mode choosen for BuchungHistoryManager");
                break;
        }

        super(
            "db/providers/buchungHistoryProvider.php",
            providerData,
            "",
            null
        );

        this.mode = mode;
    }

    setUser(id) {
        if(this.mode === "user") {
            this.providerData.userId = id;
            this.loadValues();
        } else
            console.warn("Trying to update user on buchungHistoryManager while mode is not user");
    }

    handleProviderRequestData(data) {
        this.data = data;
        super.handleProviderRequestData(data);
    }

}