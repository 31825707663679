import $ from "jquery";
import LoginStateManager from "./LoginStateManager";

export function callCommand(
    commandFileName,
    commandData,
    onDoneCallback
) {
    $.ajax({
        method: "POST",
        url: "db/commands/" + commandFileName,
        data: commandData
    }).done(onDoneCallback);
}

export function RESTDeleteCmd(apiModelName, modelId) {
    callCommand(
        `../../api/${apiModelName}/delete.php`,
        JSON.stringify({
            id: modelId
        }),
        () => Promise.resolve()
    );
}

export function callDeleteBuchungCmd(buchungId, callback) {
    callCommand(
        "deleteBuchungCmd.php",
        {
            id: buchungId
        },
        callback
    );
}

export function callLoginCmd(email, password, stayLoggedIn, onSuccess, onError) {
    callCommand(
        "loginCmd.php",
        {
            email : email,
            password: password,
            stayLoggedIn: stayLoggedIn
        },
        (data) => {
            if(data === "success")
                onSuccess(data);
            else {
                onError(data);
            }
        }
    );
}

export function callLogoutCmd() {
    callCommand(
        "logoutCmd.php",
        null,
        () => {
            LoginStateManager.getInstance().setLoggedIn(false);
        }
    );
}

export function callCreateBuchungCmd(data, callback) {
    callCommand(
        "insertBuchung.php",
        data,
        callback
    );
}

export function callUpdateBuchungCmd(data, callback) {
    callCommand(
        "updateBuchungCmd.php",
        data,
        callback
    );
}