import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {observer} from "mobx-react";
import EditStore from "../store/EditStore";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";
import EditStoreInputField from "../form/EditStoreInputField";
import TextEditControl from "../form/baseControls/TextEditControl";
import PriceEditControl from "../form/baseControls/PriceEditControl";
import TextareaEditControl from "../form/baseControls/TextareaEditControl";
import DateSelectionControl from "../form/baseControls/DateSelectionControl";
import ExpenseCreationMemorizer from "./ExpenseCreationMemorizer";

@observer
export default class CreateExpenseDialog extends React.Component {

    constructor(props) {
        super(props);
        this.createExpense = this.createExpense.bind(this);
        this.cancel = this.cancel.bind(this);
    }


    componentDidMount() {
        this.editStore = new EditStore();
        this.editStore.simpleCollectionStore = this.props.stores.expenses;
        this.editStore.modelId = -1;
        this.editStore.memorizer = new ExpenseCreationMemorizer();
        this.editStore.startEditing();
    }

    createExpense() {
        this.editStore.stopEditingAndSave();
        this.props.onClose();
        this.editStore.startEditing();
    }

    cancel() {
        if(this.props.onClose)
            this.props.onClose();
        this.editStore.stopEditingAndDiscard();
        this.editStore.startEditing();
    }

    render() {

        const editStoreInputFieldProps = {
            stores: this.props.stores,
            editStore: this.editStore,
            tableName: "expenses",
            permanentInputMode: true,
            errorOnlyAfterInteraction: true
        };

        return (
            <Dialog open={this.props.open} onClose={this.cancel}>
                <DialogTitle id="form-dialog-title">Ausgabe eintragen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte trage die Daten ein, um einen neue Ausgabe anzulegen.
                    </DialogContentText>
                    <EditStoreInputField component={ApartmentsSelectionControl} {...editStoreInputFieldProps} fieldName={"apartmentFK"} />
                    <EditStoreInputField component={TextEditControl} {...editStoreInputFieldProps} fieldName={"name"} />
                    <EditStoreInputField component={PriceEditControl} {...editStoreInputFieldProps} fieldName={"amount"} />
                    <EditStoreInputField component={TextareaEditControl} {...editStoreInputFieldProps} fieldName={"description"} />
                    <EditStoreInputField component={DateSelectionControl} {...editStoreInputFieldProps} fieldName={"date"} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={this.createExpense} color="primary">
                        Ausgabe erstellen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}