import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default class SmartfillButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchorEl: null
        }

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    openMenu(event) {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    }

    closeMenu() {
        this.setState({
            open: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <IconButton variant="contained" color="primary" onClick={this.openMenu}>
                    <Icon>emoji_objects</Icon>
                </IconButton>
                <Menu
                    keepMounted
                    open={this.state.open}
                    onClose={this.closeMenu}
                    anchorEl={this.state.anchorEl}
                >
                    {
                        this.props.items.map(item => <MenuItem key={item.text} onClick={() => { item.action(); this.closeMenu(); }}>{item.text}</MenuItem>)
                    }
                </Menu>
            </React.Fragment>

        );
    }
}