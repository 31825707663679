import ButtonGroup from "@material-ui/core/ButtonGroup";
import React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {formatEuroValue, isEmptyOrNull} from "./reactUtils";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import 'moment/locale/de';
import {FormHelperText} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Menu from "@material-ui/core/Menu";
import * as accounting from "accounting-js";

moment.locale('de');

const modelFieldEditActionButtonStyle = {
    fontSize: 30
};

class ModelEditFieldBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: null, //At this point, the ModelManager has not loaded data yet, so value can be left empty
            inEdit: this.props.autoSave,
            title: ""
        };

        this.inputRef = React.createRef();

        this.handleChangeDefault = this.handleChangeDefault.bind(this);
        this.save = this.save.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.getButtons = this.getButtons.bind(this);
        this.setValueDirect = this.setValueDirect.bind(this);
        this.getAdornment = this.getAdornment.bind(this);
    }

    componentDidMount() {
        this.props.modelManager.addDependant(this, this.props.columnName, this.state.value);
        this.onModelUpdate();
    }

    componentWillUnmount() {
        this.props.modelManager.removeDependant(this);
    }

    formatValueToSave(value) {
        return value; //Default implementation does nothing
    }

    formatValueFromModelUpdate(value) {
        return value; //Default implementation does nothing
    }

    onModelUpdate() {
        this.setState({
            value: this.formatValueFromModelUpdate(this.props.modelManager.getValue(this.props.columnName)),
            title: this.props.modelManager.getFieldTitle(this.props.columnName),
            errorState: this.props.modelManager.getErrorState(this.props.columnName)
        });
    }

    handleChangeDefault(event, setStateCallbackFunction) {
        setStateCallbackFunction = typeof setStateCallbackFunction !== "undefined" ? setStateCallbackFunction : null;
        this.setState({
            value: event.target.value
        }, setStateCallbackFunction);
    }

    onValueChange() {
        if(this.props.autoSave)
            this.save(null);
    }

    startEditing(event) {
        this.valueBeforeEditing = this.state.value;
        this.setState({
            inEdit: true
        });
        if(this.inputRef.current)
            this.inputRef.current.focus();
    }

    cancelEditing(event) {
        this.setState({
            inEdit: false,
            value: this.valueBeforeEditing
        });
    }

    save(event) {
        if(event !== null)
            event.preventDefault();
        if(!this.props.autoSave)
            this.setState({
                inEdit: false
            });
        let formattedValue = this.formatValueToSave(this.state.value);
        this.setValueDirect(formattedValue);
    }

    setValueDirect(value) {
        this.props.modelManager.setValue(this.props.columnName, value);
    }

    renderField() {
        //To be implemented by derived class
    }

    getButtons() {
        let buttons = [];
        let smartfillItems = Array.isArray(this.props.smartfillItems) ? this.props.smartfillItems : [];

        if (!this.props.autoSave && !this.props.noEdit) {
            if (!this.state.inEdit && !this.props.staticField) {
                buttons.push(
                    <Button key="startEditing" variant="contained" color="primary" onClick={this.startEditing}>
                        <Icon>edit</Icon>
                    </Button>
                );
            }

            if (this.state.inEdit) {
                buttons.push(
                    <Button key="save" variant="contained" color="primary" onClick={this.save}>
                        <Icon>save</Icon>
                    </Button>
                );
                buttons.push(
                    <Button key="cancel" variant="contained" color="secondary" onClick={this.cancelEditing}>
                        <Icon>cancel</Icon>
                    </Button>
                );
            }
        }

        if(smartfillItems.length > 0) {
            buttons.push(
                <SmartfillButton key="autofillButton" items={smartfillItems} setValueFnct={this.setValueDirect} manager={this.props.modelManager}/>
            );
        }

        return buttons;
    }

    render() {
        return (
            <form onSubmit={this.save}>
                    <div style={{ display: 'inline-flex', width: "100%" }}>
                        <div style={{width: "100%"}}>
                            {this.renderField()}
                        </div>
                        <div style={{ alignSelf: 'center', float: "right", width: "auto", marginLeft: "5px" }}>
                            <ButtonGroup size="small">
                                {
                                    this.getButtons()
                                }
                            </ButtonGroup>
                        </div>

                    </div>
            </form>
        );
    }

    getAdornment() {
        if (typeof this.props.adornment === "string")
            return (
                <InputAdornment position="end">
                    {this.props.adornment}
                </InputAdornment>
            );
        return null;
    }

}

class ModelEditFieldText extends ModelEditFieldBase {

    constructor(props) {
        super(props);
        this.state.value = "";
    }

    renderField() {

        return (
            <TextField
                label={this.state.title}
                margin="normal"
                variant="outlined"
                value={this.state.value || ''}
                onChange={this.handleChangeDefault}
                onBlur={this.onValueChange}
                inputProps={{
                    readOnly:!this.state.inEdit
                }}
                fullWidth
                style={{width: "100%"}}
                inputRef={this.inputRef}
                {...this.state.errorState}
            />
        );
    }
}

class ModelEditFieldTextarea extends ModelEditFieldBase {

    constructor(props) {
        super(props);
        this.state.value = "";
    }

    renderField() {

        return (
            <TextField
                label={this.state.title}
                margin="normal"
                variant="outlined"
                value={this.state.value || ''}
                onChange={this.handleChangeDefault}
                onBlur={this.onValueChange}
                inputProps={{
                    readOnly:!this.state.inEdit
                }}
                fullWidth
                multiline
                rows={2}
                rowsMax={Infinity}
                inputRef={this.inputRef}
                {...this.state.errorState}
            />
        );
    }
}

class ModelEditFieldInt extends ModelEditFieldBase {

    constructor(props) {
        super(props);
        this.state.value = null;
    }

    formatValueFromModelUpdate(value) {
        let intVal = isEmptyOrNull(value) ? "" : parseInt(value);
        return intVal;
    }

    formatValueToSave(value) {
        let intVal = isEmptyOrNull(value) ? null : parseInt(value);
        return intVal;
    }

    renderField() {
        let textFieldValue = this.state.value !== null ? this.state.value.toString() : '';
        return (
            <TextField
                label={this.state.title}
                margin="normal"
                variant="outlined"
                value={textFieldValue}
                onChange={this.handleChangeDefault}
                onBlur={this.onValueChange}
                fullWidth
                InputProps={{
                    readOnly: !this.state.inEdit,
                    endAdornment: this.getAdornment()
                }}
                inputRef={this.inputRef}
                {...this.state.errorState}
            />
        );
    }
}

class ModelEditFieldFloat extends ModelEditFieldBase {

    constructor(props) {
        super(props);
        this.state.value = null;
    }

    formatValueFromModelUpdate(value) {
        let floatVal = isEmptyOrNull(value) ? "" : accounting.formatNumber(value, 3, ".", ",");
        return floatVal;
    }

    formatValueToSave(value) {
        let floatVal = isEmptyOrNull(value) ? null : accounting.unformat(value, ",");;
        return floatVal;
    }

    renderField() {
        let textFieldValue = this.state.value !== null ? this.state.value.toString() : '';
        return (
            <TextField
                label={this.state.title}
                margin="normal"
                variant="outlined"
                value={textFieldValue}
                onChange={this.handleChangeDefault}
                onBlur={this.onValueChange}
                fullWidth
                InputProps={{
                    readOnly: !this.state.inEdit,
                    endAdornment: this.getAdornment()
                }}
                inputRef={this.inputRef}
                {...this.state.errorState}
            />
        );
    }
}

class ModelEditFieldDate extends ModelEditFieldBase {

    constructor(props) {
        super(props);
        this.state.value = null;

        this.handleChangeDate = this.handleChangeDate.bind(this);
    }

    formatValueFromModelUpdate(value) {
        if(value !== null)
            return moment(value).format("YYYY-MM-DD");
        else
            return null;
    }

    handleChangeDate(date) {
        let value = null;
        if(date !== null && date.isValid())
            value = date.format("YYYY-MM-DD");

        this.setState({
            value: value
        }, this.onValueChange);
    }

    renderField() {
        let date = this.state.value !== null ? moment(this.state.value) : null;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="DD.MM.YYYY"
                    margin="normal"
                    label={this.state.title}
                    value={date}
                    onChange={this.handleChangeDate}
                    autoOk={true}
                    readOnly={!this.state.inEdit}
                    fullWidth
                    inputRef={this.inputRef}
                    {...this.state.errorState}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

class ModelEditFieldSelect extends ModelEditFieldBase {

    constructor(props) {
        super(props);
        this.state.selectableValues = null;

        this.setOptions = this.setOptions.bind(this);
        this.renderField = this.renderField.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.setOptions();
    }

    onModelUpdate() {
        this.setOptions();
        super.onModelUpdate();
    }

    handleChangeSelect(event) {
        let self = this;
        this.handleChangeDefault(event, self.onValueChange);
    }

    renderField() {
        let valueType = typeof this.state.value;
        let value = "";

        if(this.state.value !== null) {
            if(valueType === "object") //Foreign Key -> Model
                value = this.state.value[0].attributes.id.value || '';
            else if(valueType !== "undefined") //Simple value
                value = this.state.value;
        }

        let error       = typeof this.state.errorState !== "undefined" ? this.state.errorState.error : false;
        let errorMsg    = typeof this.state.errorState !== "undefined" ? this.state.errorState.helperText : "";

        return (
            <FormControl fullWidth variant={"outlined"}>
                <InputLabel>{this.state.title}</InputLabel>
                <Select
                    inputProps={{
                        readOnly: !this.state.inEdit
                    }}
                    value={value}
                    onChange={this.handleChangeSelect}
                    fullWidth
                    inputRef={this.inputRef}
                    error={error}
                >
                    {
                        this.state.selectableValues &&
                        this.state.selectableValues.map((pair) => (<MenuItem value={pair.value} key={pair.value}>{pair.label}</MenuItem>))
                    }
                </Select>
                <FormHelperText>{errorMsg}</FormHelperText>
            </FormControl>
        );
    }

    setOptions() {
        let selectableValues = this.props.modelManager.getSelectableValues(this.props.columnName);
        this.setState({
           selectableValues: selectableValues
        });
    }
}

class ModelEditFieldCheckbox extends ModelEditFieldBase {

    constructor(props) {
        super(props);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.state.value = false;
    }

    formatValueFromModelUpdate(value) {
        return value == 1 || value === true;
    }

    handleChangeCheckbox(event) {
        if(this.state.inEdit) { //Replace missing readonly property
            this.setState({
                value: event.target.checked
            }, this.onValueChange);
        }
    }

    renderField() {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={this.state.value || false}
                        onChange={this.handleChangeCheckbox}
                        inputProps={{
                            readOnly: !this.state.inEdit
                        }}
                    />
                }
                label={this.state.title}
            />
        );
    }
}

class StaticInformationField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            title: this.props.title
        };
    }

    getAdornment() {
        if (typeof this.props.adornment === "string")
            return (
                <InputAdornment position="end">
                    {this.props.adornment}
                </InputAdornment>
            );
        return null;
    }

    render() {
        return (
            <TextField
                label={this.state.title}
                margin="normal"
                variant="outlined"
                value={this.state.value || ''}
                InputProps={{
                    readOnly: true,
                    endAdornment: this.getAdornment()
                }}
                fullWidth
                {...this.state.errorState}
            />
        );
    }
}

class ModelDependantInformationField extends StaticInformationField {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
      if(this.props.modelManager !== "undefined" && this.props.modelManager != null) {
        this.props.modelManager.addDependant(this);
        this.onModelUpdate();
      }
    }

    componentWillUnmount() {
        this.props.modelManager.removeDependant(this);
    }

    onModelUpdate() {
        if(this.props.onModelUpdate !== 'undefined')
            this.setState({
                value: this.props.valueGetter(this.props.modelManager)
            });
    }

}

class GesamtsummeInklKautionDisplayField extends ModelDependantInformationField {

    onModelUpdate() {
        let modelManager = this.props.modelManager;
        let mietpreis = modelManager.getValue("mietpreis");
        let kaution = modelManager.getValue("kaution");
        let touristensteuer = modelManager.getValue("touristensteuer");
        let endreinigung = modelManager.getValue("endreinigung");
        let sum = mietpreis + kaution + touristensteuer + endreinigung;
        this.setState({
            value: formatEuroValue(sum, false)
        })
    }
}

class GesamtsummeExklKautionDisplayField extends ModelDependantInformationField {

    onModelUpdate() {
        let modelManager = this.props.modelManager;
        let mietpreis = modelManager.getValue("mietpreis");
        let touristensteuer = modelManager.getValue("touristensteuer");
        let endreinigung = modelManager.getValue("endreinigung");
        let sum = mietpreis + touristensteuer + endreinigung;
        this.setState({
            value: formatEuroValue(sum, false)
        })
    }
}

class ZurueckzuerstattendeKautionDisplayField extends ModelDependantInformationField {

    onModelUpdate() {
        let modelManager = this.props.modelManager;
        let buchung = modelManager.getData();
        if(buchung != null) {
            let zurueckzuerstattendeKaution = modelManager.getData().values[0].attributes.depositRepaySum;
            this.setState({
                value: formatEuroValue(zurueckzuerstattendeKaution, false)
            });
        } else {
            this.setState({
                value: ""
            });
        }
    }
}

class ReisedauerDisplayField extends ModelDependantInformationField {

    onModelUpdate() {
        let modelManager = this.props.modelManager;
        let anreise = moment(modelManager.getValue("anreise"));
        let abreise = moment(modelManager.getValue("abreise"));
        let reisedauer = abreise.diff(anreise, "days");
        let result = isNaN(reisedauer) ? "" : reisedauer + " Tage";
        this.setState({
            value: result
        })
    }
}

class ReceivedPaymentsField extends ModelDependantInformationField {
    onModelUpdate() {
        let modelManager = this.props.modelManager;
        let anzahlung = modelManager.getValue("anzahlung");
        let anzahlungErhalten = modelManager.getValue("anzahlungErhalten");
        let mietpreis = modelManager.getValue("mietpreis");
        let restzahlungMiete = mietpreis - anzahlung;
        let kaution = modelManager.getValue("kaution");
        let endreinigung = modelManager.getValue("endreinigung");
        let touristensteuer = modelManager.getValue("touristensteuer");
        let restzahlungGesamt = restzahlungMiete + kaution + endreinigung + touristensteuer;
        let restzahlungErhalten = modelManager.getValue("restzahlungErhalten");
        let sumPaid = (anzahlungErhalten * anzahlung) + (restzahlungErhalten * restzahlungGesamt);
        this.setState({
            value: formatEuroValue(sumPaid, false)
        });
    }
}

class CancellationRefundField extends ModelDependantInformationField {
    onModelUpdate() {
        let modelManager = this.props.modelManager;
        let anzahlung = modelManager.getValue("anzahlung");
        let anzahlungErhalten = modelManager.getValue("anzahlungErhalten");
        let mietpreis = modelManager.getValue("mietpreis");
        let restzahlungMiete = mietpreis - anzahlung;
        let kaution = modelManager.getValue("kaution");
        let endreinigung = modelManager.getValue("endreinigung");
        let touristensteuer = modelManager.getValue("touristensteuer");
        let restzahlungGesamt = restzahlungMiete + kaution + endreinigung + touristensteuer;
        let restzahlungErhalten = modelManager.getValue("restzahlungErhalten");
        let sumPaid = (anzahlungErhalten * anzahlung) + (restzahlungErhalten * restzahlungGesamt);
        let cancellationFee = modelManager.getValue("cancellationFee");

        let refund = sumPaid - cancellationFee;
        this.setState({
            value: formatEuroValue(refund, false)
        });
    }
}

class DaysUntilArrivalField extends ModelDependantInformationField {
    onModelUpdate() {
        let modelManager = this.props.modelManager;
        let cancellationDate = moment(modelManager.getValue("cancellationDate"));
        let anreise = moment(modelManager.getValue("anreise"));
        let daysDiff = anreise.diff(cancellationDate, "days");
        this.setState({
            value: isNaN(daysDiff) ? "Bitte Stornierungsdatum ausfüllen" : daysDiff + " Tage"
        });
    }
}

class AutofillMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    openMenu() {
        this.setState({
            open: true
        });
    }

    closeMenu() {
        this.setState({
            open: false
        });
    }

    render() {
        if(this.props.items === null)
            return null;

        return (
            <React.Fragment>
                <Button variant="contained" color="primary" onClick={this.openMenu}>
                    <Icon>edit</Icon>
                </Button>
                <Menu>
                    {
                        this.props.items.map(item =>
                            <MenuItem
                                onClick={
                                    () => {
                                        this.closeMenu();
                                        item.action();
                                    }
                                }
                            >
                                {item.text}
                            </MenuItem>
                        )
                    }
                </Menu>
            </React.Fragment>
        );
    }
}

module.exports = {
    ModelEditFieldText,
    ModelEditFieldCheckbox,
    ModelEditFieldDate,
    ModelEditFieldInt,
    ModelEditFieldFloat,
    ModelEditFieldSelect,
    ModelEditFieldTextarea,
    ReisedauerDisplayField,
    ZurueckzuerstattendeKautionDisplayField,
    GesamtsummeInklKautionDisplayField,
    GesamtsummeExklKautionDisplayField,
    ModelDependantInformationField,
    CancellationRefundField,
    DaysUntilArrivalField,
    ReceivedPaymentsField
}