import moment from "moment";
import {autorun} from "mobx";

export default class AutofillObject {

    constructor(editStore, stores) {
        this.editStore = editStore;
        this.stores = stores;

        autorun(() => {
            this.doAutofill("", true);
        });
    }

    getValue(fieldName) {
        return this.editStore.getValue(fieldName);
    }

    setValue(fieldName, value) {
        this.editStore.modifyField(fieldName, value);
    }

    doAutofill(modifiedField, constantsUpdated = false) {}

}