import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {callLogoutCmd} from "./js/src/commands";
import LoginStateManager from "./js/src/LoginStateManager";
import {SwipeableDrawer} from "@material-ui/core";
import {observer} from "mobx-react";
import {gotoPage} from "./reactUtils";

require('react-toastify/dist/ReactToastify.css');

const drawerWidth = 300;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});

class DrawerLink extends React.Component {
    render() {
        let key = this.props.href;
        return (
            <ListItem
                button
                component="a"
                onClick={() => {
                    this.props?.onClick();
                    if(this.props.href)
                        gotoPage(this.props.href);
                }}
                key={key}
            >
                <ListItemIcon><Icon>{this.props.icon}</Icon></ListItemIcon>
                <ListItemText primary={this.props.title} />
            </ListItem>
        );
    }
}

@observer
class ResponsiveDrawer extends React.Component {

    toolbarItemsGetter = () => null;

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            permissions: [],
            pageTitle: "Buchungsverwaltung"
        };

        this.pageTitleSave = "";

        this.toggleMobileOpen = this.toggleMobileOpen.bind(this);
        this.setMobileOpen = this.setMobileOpen.bind(this);
        this.getDrawer = this.getDrawer.bind(this);
        this.onModelUpdate = this.onModelUpdate.bind(this);
        this.closeMobile = this.closeMobile.bind(this);
        this.setPageTitle = this.setPageTitle.bind(this);
        this.setPageTitleTemp = this.setPageTitleTemp.bind(this);
        this.resetTempPageTitle = this.resetTempPageTitle.bind(this);
    }

    toggleMobileOpen() {
        this.setState({
            mobileOpen: !this.state.mobileOpen
        });
    }

    closeMobile() {
        this.setMobileOpen(false);
    }

    setMobileOpen(open) {
        this.setState({
            mobileOpen: open
        });
    }

    componentDidMount() {
        this.currentUserManager = LoginStateManager.getInstance().getCurrentUserManager();
        this.currentUserManager.addDependant(this);
        this.onModelUpdate();
    }

    componentWillUnmount() {
        this.currentUserManager.removeDependant(this);
        this.currentUserManager = null;
    }

    onModelUpdate() {
        this.setState({
            permissions: this.currentUserManager.getPermissions()
        });
    }

    getDrawer() {
        const { classes } = this.props;
        const hasPermission = permission => this.state.permissions.includes(permission);

        return (
            <div>
                <div className={classes.toolbar} />
                <Divider />
                <List>
                    {
                        hasPermission("buchungenR") &&
                            <React.Fragment>
                                <DrawerLink title={"Buchungsübersicht"} icon={"view_list"} href={"/#/"} onClick={this.closeMobile}/>
                                <DrawerLink title={"Buchungskalender"} icon={"event"} href={"/#/calendar"} onClick={this.closeMobile}/>
                            </React.Fragment>
                    }
                    {
                        hasPermission("buchungenW") &&
                            <DrawerLink title={"Reservierung erstellen"} icon={"note_add"} href={"/#/create"} onClick={this.closeMobile}/>
                    }
                    {
                        hasPermission("statistics") &&
                            <DrawerLink title={"Statistiken"} icon={"show_chart"} href={"/#/statistics"} onClick={this.closeMobile}/>

                    }
                    {
                        hasPermission("accounting") &&
                            <DrawerLink title={"Buchhaltung"} icon={"attach_money"} href={"/#/accounting"} onClick={this.closeMobile}/>
                    }
                    {
                        hasPermission("documentsW") &&
                            <DrawerLink title={"Dokumentengenerierung"} icon={"text_snippet"} href={"/#/documents"} onClick={this.closeMobile}/>
                    }
                    {
                        hasPermission("occurrences") &&
                        <DrawerLink title={"Ereignisregeln"} icon={"notification_important"} href={"/#/occurrences"} onClick={this.closeMobile}/>
                    }
                </List>
                <Divider/>
                <List>
                    {
                        hasPermission("configuration") &&
                            <DrawerLink title={"Konfiguration"} icon={"settings"} href={"/#/configuration"} onClick={this.closeMobile}/>

                    }
                </List>
                <Divider/>
                <List>
                    <DrawerLink title={LoginStateManager.getInstance().getCurrentUserManager().getFullName()} icon={"person"} href={"/#/profile"} onClick={this.closeMobile}/>
                    <DrawerLink title={"Abmelden"} icon={"exit_to_app"} href={null} onClick={() => { this.closeMobile(); callLogoutCmd(); }}/>
                </List>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div
                className={classes.root}
                style={{
                    minHeight: "100%"
                }}
            >
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.toggleMobileOpen}
                            className={classes.menuButton}
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                        <Typography variant="h6" noWrap style={{flex: 1}}>
                            {this.state.pageTitle || "Buchungsverwaltung"}
                        </Typography>
                        {this.toolbarItemsGetter()}
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="css">
                        <SwipeableDrawer
                            variant="temporary"
                            anchor={'left'}
                            open={this.state.mobileOpen}
                            onOpen={() => this.setMobileOpen(true)}
                            onClose={this.toggleMobileOpen}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {this.getDrawer()}
                        </SwipeableDrawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {this.getDrawer()}
                        </Drawer>
                    </Hidden>
                </nav>
                <div className={classes.toolbar} />
                <main
                    className={classes.content}
                    style={{
                        paddingTop: "100px",
                        background: "#F5F5F5",
                        maxWidth: "100%"
                    }}
                >
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={11}>
                            {this.props.children(this)}
                        </Grid>
                    </Grid>
                </main>
            </div>
        );
    }

    setToolbarItems(toolbarItemsGetter) {
        if(typeof toolbarItemsGetter === "function")
            this.toolbarItemsGetter = toolbarItemsGetter;
        else
            this.toolbarItemsGetter = () => toolbarItemsGetter;
    }

    setPageTitle(pageTitle) {
        this.setState({
            pageTitle: pageTitle
        });
    }

    setPageTitleTemp(pageTitle) {
        this.pageTitleSave = this.state.pageTitle;
        this.setState({
            pageTitle: pageTitle
        });
    }

    resetTempPageTitle() {
        this.setState({
            pageTitle: this.pageTitleSave
        });
    }

}

export default withStyles(styles)(ResponsiveDrawer);
