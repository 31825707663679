import {autorun, computed, observable, toJS} from "mobx";
import {computedFn} from "mobx-utils";
import {toast} from "react-toastify";
import DynamicStore from "./DynamicStore";

export default class EditStore extends DynamicStore{
    @observable interactions = [];
    @observable inEdit = false;
    @observable validatorObject = null;
    @observable simpleCollectionStore = null;
    @observable modelId = null;
    @observable autofillObject = null;

    @observable computedFieldsCalculationObject = null;
    memorizer = null;

    constructor() {
        super();
        autorun(() => {
            if(this.computedFieldsCalculationObject != null) {
                this.computedFieldsCalculationObject.booking = this.dynamicModelExclComputedFields;
            }
        });
    }

    reset() {
        super.reset();
        this.interactions = [];
        this.inEdit = false;
        this.validatorObject = null;
        this.simpleCollectionStore = null;
        this.modelId = null;
        this.autofillObject = null;
        this.computedFieldsCalculationObject = null;
        this.memorizer = null;
    }

    @computed get dataRef() {
        if(this.modelId != null && this.simpleCollectionStore != null)
            return this.simpleCollectionStore.getModelById(this.modelId);
        return null;
    }

    @computed get dynamicModelExclComputedFields() {
        let dynamicModelExclComputedFields = {};
        dynamicModelExclComputedFields = Object.assign(
            dynamicModelExclComputedFields,
            this.dataRef ? this.dataRef.attributes : {},
            this.modifiedData
        );
        return dynamicModelExclComputedFields;
    }

    @computed get nonComputedFields() {
        return Object.keys(this.dynamicModelExclComputedFields);
    }

    @computed get computedFields() {
        let obj = {};
        if(this.computedFieldsCalculationObject != null) {
            this.computedFieldsCalculationObject.fieldNames.forEach(fieldName => obj[fieldName] = this.computedFieldsCalculationObject[fieldName]);
        }
        return obj;
    }

    @computed get dynamicModel() {
        if(!this.dynamicModelExclComputedFields)
            return null;
        let dynamicModel = {};
        dynamicModel = Object.assign(
            this.dynamicModelExclComputedFields,
            this.computedFields,
        );
        return dynamicModel;
    }

    getErrorObject = computedFn(function getErrorObject(fieldName) {
        return this.errorObjects[fieldName];
    });

    @computed get errorObjects() {
        if(this.validatorObject === null)
            return {};
        let errorStates = {};
        this.nonComputedFields.forEach(fieldName => {
            if(this.hadInteraction(fieldName)) {
                let value = this.getValue(fieldName);
                let errorObj = {};
                this.validatorObject.checkField(fieldName, errorObj);
                errorStates[fieldName] = errorObj;
            }
        });
        return errorStates;
    }

    startEditing() {
        this.inEdit = true;
    }

    stopEditingAndDiscard() {
        this.inEdit = false;
        this.modifiedData = {};
    }

    async save() {
        return new Promise((resolve, reject) => {
            this.setAllFieldsInteracted(); //All fields are now validated
            if(Object.values(this.errorObjects).some(errorObj => errorObj.state)) {
                toast.error("Fehler beim Speichern - bitte Eingaben prüfen");
                reject();
            }
            else {
                this.saveInternal(() => {
                    this.modifiedData = {};
                    resolve();
                });
            }
        });
    }

    stopEditingAndSave() {
        this.save().then(() => {this.inEdit = false});
    }

    async saveInternal(afterSaveCallback) {
        if(this.memorizer != null) {
            try {
                let response = await this.memorizer.memorize(this.modifiedData,this.modelId);
                //this.simpleCollectionStore.replaceItem(response);
                if(this.simpleCollectionStore != null)
                    this.simpleCollectionStore.reload(afterSaveCallback); //TODO Remove if entire program is migrated to new rest api
            } catch(exception){}

            if(this.simpleCollectionStore != null)
                this.simpleCollectionStore.reload(afterSaveCallback); //TODO Remove if entire program is migrated to new rest api
        }
        else
            console.warn("Trying to save EditStore without Memorizer defined");
    }

    modifyField(fieldName, value) {
        if(this.inEdit) {
            super.modifyField(fieldName, value);
            if(this.autofillObject != null)
                this.autofillObject.doAutofill(fieldName);
        }
    }

    refreshBaseStore() {
        if(this.simpleCollectionStore != null) {
            this.simpleCollectionStore.reload();
        }
    }

    hadInteraction(fieldName) {
        return this.interactions.includes(fieldName);
    }

    registerInteraction(fieldName) {
        if(!this.interactions.includes(fieldName))
            this.interactions.push(fieldName);
    }

    setAllFieldsInteracted() {
        this.interactions = [...this.nonComputedFields];
    }

}