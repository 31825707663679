import React from "react";

export default class TabPanel extends React.Component {
    render() {
        return (
            <div
                hidden={this.props.value !== this.props.index}
            >
                {this.props.children}
            </div>
        );
    }
}