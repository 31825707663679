import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import {observer} from "mobx-react";
import LoadingIndicator from "../LoadingIndicator";
import EditStore from "../store/EditStore";
import Box from "@material-ui/core/Box";
import DocumentGenerationSendmailHandler from "./DocumentGenerationSendmailHandler";

@observer
export default class DocumentGeneratorsList extends React.Component {

    constructor(props) {
        super(props);

        this.mailEditStore = new EditStore();
    }


    render() {
        let storeData = this.props.bookingAuxiliaryStore.data.documentGenerators;
        let documents = storeData.data;
        documents = documents != null ? documents : [];
        let loading = storeData.loading;

        return (
            <React.Fragment>
                <Paper>
                    <List
                        subheader={
                            <ListSubheader>Dokumente</ListSubheader>
                        }
                    >
                        {
                            loading ? <LoadingIndicator/> :
                                documents.map(documentGenerator =>
                                    <DocumentGenerationListItem key={documentGenerator.attributes.id} bookingId={this.props.bookingId} document={documentGenerator} editStore={this.mailEditStore}/>
                                )
                        }
                    </List>
                </Paper>

                {(/*this.editStore.getValue("documentGeneratorId") != null && this.editStore.getValue("bookingId") != null*/true) && (
                    <DocumentGenerationSendmailHandler
                        stores={this.props.stores}
                        editStore={this.props.editStore}
                        mailEditStore={this.mailEditStore}
                        mailAccountId={this.props.stores.apartmentsStore.getModelById(this.props.editStore.getValue("apartmentFK"))}
                    />
                )}
            </React.Fragment>
        );
    }

}

class DocumentGenerationListItem extends React.Component {

    constructor(props) {
        super(props);
        this.openDocument = this.openDocument.bind(this);
    }

    openDocument(event) {
        let bookingId = this.props.bookingId;
        let documentAttributes = this.props.document.attributes;
        if(documentAttributes.email) {
            this.props.editStore.startEditing();
            this.props.editStore.modifyField("documentGeneratorId", documentAttributes.id);
            this.props.editStore.modifyField("bookingId", bookingId);
        } else {
            window.open("generation/?documentGeneratorId="+documentAttributes.id+"&bookingId="+bookingId, '_blank');
        }
    }

    render() {
        let documentAttributes = this.props.document.attributes;
        return (
            <ListItem button onClick={this.openDocument}>
                <ListItemIcon>
                    <Icon>{documentAttributes.icon}</Icon>
                </ListItemIcon>
                <ListItemText>
                    {documentAttributes.name}
                </ListItemText>
            </ListItem>
        );
    }
}
