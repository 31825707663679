import {computed, observable} from "mobx";
import {computedFn} from "mobx-utils";

export default class DynamicStore {
    @observable modifiedData = {};

    constructor(initialData = {}) {
        this.modifiedData = initialData;
    }

    reset() {
        this.modifiedData = {};
    }

    @computed get dynamicModel() {
        return this.modifiedData;
    }

    getValue = computedFn(function getValue(fieldName) {
        return this.dynamicModel ? this.dynamicModel[fieldName] : undefined;
    });

    modifyField(fieldName, value) {
        this.modifiedData[fieldName] = value;
    }

    reload() {
        //dummy
    }

}