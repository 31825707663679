import TextFieldControl from "./baseControls/TextFieldControl";
import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

export default class StringSearchEditControl extends React.Component{
    render() {
        return (
            <TextFieldControl
                initialValue={""}
                {...this.props}
                startAdornment={<Icon>search</Icon>}
                adornment={<IconButton edge="end" onClick={() => this.props.onValueChange("")}><Icon>clear</Icon></IconButton>}
            />
        );
    }
}