import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FormHelperText} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {observer} from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";

const selectStyles = theme => ({
    /*root: {
        "& $notchedOutline": {
            borderColor: props => props.permanentInputMode ? "inherit" : "green"
        }
    },*/
    focused: {},
    notchedOutline: {}
});

@observer
class SelectEditControl extends React.Component{

    constructor(props) {
        super(props);
        this.onInteraction = this.onInteraction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderMultipleValues = this.renderMultipleValues.bind(this);
    }

    onInteraction() {
        let interactionCallback = this.props.onInteraction;
        if(typeof interactionCallback === "function")
            interactionCallback();
    }

    renderMultipleValues(selectedValues) {
        const selectableValues = this.props.selectableValues;
        return selectedValues.map(selectedValueKey => { let searchRes = selectableValues.find(selVal => selVal[0] === selectedValueKey); return searchRes ? searchRes[1] : "Element nicht gefunden"; }).join(', ');
    }

    handleChange(event) {
        if(typeof this.props.onValueChange === "function")
            this.props.onValueChange(event.target.value);
        this.onInteraction();
    }

    handleChangeMultiple(event) {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        if(typeof this.props.onValueChange === "function")
            this.props.onValueChange(value);
        this.onInteraction();
    }

    render() {
        const { classes } = this.props;
        let value = this.props.value;

        const emptyValue = this.props.multiple ? [] : "";

        let selectableValues = this.props.selectableValues;
        if(!selectableValues)
            value = emptyValue;
        else if(!this.props.multiple && !selectableValues.find(element => element[0] === value))
            value = emptyValue;

        if(!value)
            value = emptyValue;

        let inEdit = this.props.edit;

        return (
            <Tooltip title={this.props.tooltip || ""}>
                <FormControl
                    fullWidth
                    variant={"outlined"}
                >
                    <InputLabel>{this.props.title}</InputLabel>
                    <Select
                        className={classes.select}
                        value={value}
                        label={this.props.title}
                        input={<Input />}
                        onChange={this.props.multiple ? this.handleChange : this.handleChange}
                        style={{
                            color: "black"
                        }}
                        disabled={!inEdit}
                        input={<OutlinedInput classes={classes} label={this.props.title} />}
                        error={this.props.error}
                        multiple={this.props.multiple}
                        renderValue={this.props.multiple ? this.renderMultipleValues : null}
                        MenuProps={{variant: "menu"}}
                    >
                        {
                            selectableValues ? selectableValues.map(pair => createMenuItem(pair, this.props.multiple, value)) : []
                        }
                    </Select>
                    <FormHelperText>{this.props.helperText}</FormHelperText>
                </FormControl>
            </Tooltip>
        );
    }
}

function createMenuItem(pair, multiple, selectedValue) {
    if(multiple) {
        if(!selectedValue)
            selectedValue = [];
        let checked = selectedValue.find(elem => elem === pair[0]) !== undefined;
        return (
            <MenuItem value={pair[0]} key={pair[0]}>
                <Checkbox checked={checked} />
                <ListItemText primary={pair[1]} />
            </MenuItem>
        );
    } else {
        return <MenuItem value={pair[0]} key={pair[0]}>{pair[1]}</MenuItem>;
    }
}

export default withStyles(selectStyles)(SelectEditControl);