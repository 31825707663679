import React from "react";

import {Controlled as CodeMirror} from 'react-codemirror2'
import Box from "@material-ui/core/Box";

require('codemirror/lib/codemirror.css');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/clike/clike.js');
require('codemirror/mode/htmlmixed/htmlmixed.js');
require('codemirror/mode/php/php.js');
require("./CodeEditControlStyle.css");

export default class CodeEditControl extends React.Component {
    render() {
        return (
            <Box>
                {this.props.title}
                <CodeMirror
                    value={this.props.value || ""}
                    options={{
                        theme: "default",
                        mode: this.props.mode,
                        lineWrapping: true,
                        lineNumbers: true
                    }}
                    style={{
                        fontSize: "30px"
                    }}
                    onBeforeChange={(editor, data, value) => {
                        if(this.props.onValueChange != null)
                            this.props.onValueChange(value);
                    }}
                />
            </Box>
        )
    }
}