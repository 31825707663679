import ModelManager from "./modelManager";

export default class UserManager extends ModelManager {

    constructor(userId = "null") {
        super(
            "db/providers/userProvider.php",
            {
                userId: userId
            },
            null,
            null
        );

        this.setUser = this.setUser.bind(this);
    }

    setUser(id) {
        this.providerData.userId = id;
        this.loadValues();
    }

    getPermissions() {
        if(this.getValue("permissions") === null)
            return [];
        return this.getValue("permissions").split(" ");
    }

    hasPermission(permission) {
        return this.getPermissions().includes(permission);
    }

    getFullName() {
        if(this.data != null)
            return this.data.values[0].attributes.vorname + " " + this.data.values[0].attributes.nachname;
        return "";
    }

    isLoggedIn() {
        let isLoggedIn = this.getError() !== "noLogin";
        return isLoggedIn;
    }

}