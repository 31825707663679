import {toast} from "react-toastify";

export default class RESTMemorizer {

    constructor(apiObjectName, afterSaveCallback) {
        this.apiObjectName = apiObjectName;
        this.afterSaveCallback = afterSaveCallback;
    }

    async memorize(keyValPairs, modelId) {}
}