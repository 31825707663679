import * as React from "react";
import {Doughnut} from "react-chartjs-2";
import 'chartjs-plugin-colorschemes';
import {formatEuroValue} from "../reactUtils";

export default class InvoicedRevenueProportionChart extends React.Component {

    chartOptions = {
            title: {
                display:true,
                text: "Zahlungsstatus",
                fontSize: 20
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        let labelText = data.labels[tooltipItem.index];
                        return labelText + ": " + formatEuroValue(val);
                    }
                }
            }
    };

    render() {
        let data = this.props.data;
        return (
            <Doughnut data={data} options={this.chartOptions}/>
        );
    }
}