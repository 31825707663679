import React from "react";
import {isEmptyOrSpacesOrNull, parseEuroValue} from "../../reactUtils";
import TextFieldControl from "./TextFieldControl";
import * as accounting from "accounting-js";

export default class PriceEditControl extends React.Component {

    formatFloatToString(floatValue) {
        if(floatValue == null)
            return "";
        return accounting.formatNumber(floatValue, {precision: 2, thousand: ".", decimal: ","});
    }

    parseStringToFloat(stringValue) {
        if(isEmptyOrSpacesOrNull(stringValue))
            return null;
        else
            return parseEuroValue(stringValue);
    }

    render() {
        return (
            <TextFieldControl
                initialValue={null}
                {...this.props}
                adornment={"€"}
                inputFormatter={this.formatFloatToString}
                outputFormatter={this.parseStringToFloat}
                applyOnBlur
            />
        );
    }
}