import * as React from "react";
import {observer} from "mobx-react";
import MaterialTable from "material-table";
import MaterialTableGermanLocalization from "../localization/MaterialTableGermanLocalization";
import PageTitleManager from "../js/src/pageTitleManager";
import {CreateOccurrenceRuleDialog, EditOccurrenceRuleDialog} from "./OccurrenceRuleEditAndCreationDialogs";
import EditStore from "../store/EditStore";
import DocumentGeneratorMemorizer from "../memorizer/DocumentGeneratorMemorizer";
import RESTModifyMemorizer from "../memorizer/RESTModifyMemorizer";
import LoadingIndicator from "../LoadingIndicator";
import {toJS} from "mobx";
import RESTCreateMemorizer from "../memorizer/RESTCreateMemorizer";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {RESTDeleteCmd} from "../js/src/commands";

@observer
export default class OccurrencePage extends React.Component {

    constructor(props) {
        super(props);
        if(!this.state) this.state = {
            editDocumentDialogOpen: false
        };

        const reloadStores = () => this.props.stores.manager.reloadStores(["bookingsStore", "apartmentsStore"]);

        this.editEditStore = new EditStore();
        this.editEditStore.simpleCollectionStore = this.props.stores.occurrenceRulesStore;
        this.editEditStore.memorizer = new RESTModifyMemorizer("occurrencerule", reloadStores);

        this.creationEditStore = new EditStore();
        this.creationEditStore.simpleCollectionStore = this.props.stores.occurrenceRulesStore;
        this.creationEditStore.memorizer = new RESTCreateMemorizer("occurrencerule", reloadStores);

    }

    componentDidMount() {
        this.props.drawer.setPageTitle("Ereignisverwaltung");
        PageTitleManager.getInstance().setTitle("Ereignisverwaltung");
        this.props.drawer.setToolbarItems(() => {
            return (
                <IconButton
                    onClick={() => {
                        this.creationEditStore.startEditing();
                    }}
                    style={{color: "white"}}
                >
                    <Icon>add</Icon>
                </IconButton>
            );
        });
    }

    render() {
        let occurrenceRules = this.props.stores.occurrenceRulesStore.allItems;
        let tableItems = occurrenceRules.map(occurrenceRule => {
            let ret = occurrenceRule.attributes;
            return ret;
        });

        let occurrenceRulesStore = this.props.stores.occurrenceRulesStore;
        return (
            occurrenceRulesStore.allItems.length === 0 && occurrenceRulesStore.isLoading ?
                <LoadingIndicator/> :
                <React.Fragment>
                    <MaterialTable
                        columns={[
                            {title: 'Name', field: 'displayName', type: 'string'},
                            {title: 'Ereignistext', field: 'occurrenceTitle', type: 'string'},
                            {title: 'Typ', field: 'type', type: 'string'}
                        ]}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Ereignisregel bearbeiten',
                                onClick: (event, rowData) => {
                                    let occurrenceRuleId = rowData.id;
                                    this.editEditStore.modelId = occurrenceRuleId;
                                    this.editEditStore.startEditing();
                                }
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Ereignisregel löschen',
                                onClick: async (event, rowData) => {
                                    let occurrenceRuleId = rowData.id;
                                    await RESTDeleteCmd("occurrencerule", occurrenceRuleId);
                                    this.props.stores.manager.reloadStores(["bookingsStore", "apartmentsStore", "occurrenceRulesStore"]);
                                }
                            }
                        ]}
                        options={{
                            defaultExpanded: true,
                            paging: false
                        }}
                        data={tableItems}
                        title="Ereignisregeln"
                        localization={MaterialTableGermanLocalization}
                    />
                    <EditOccurrenceRuleDialog
                        stores={this.props.stores}
                        editStore={this.editEditStore}
                    />
                    <CreateOccurrenceRuleDialog
                        stores={this.props.stores}
                        editStore={this.creationEditStore}
                    />
                </React.Fragment>
        );
    }
}