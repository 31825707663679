import React from "react";
import TextFieldControl from "./TextFieldControl";

export default class TextareaEditControl extends React.Component{

    render() {
        return (
            <TextFieldControl
                initialValue={""}
                {...this.props}
                multiline
            />
        );
    }
}