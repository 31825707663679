import {getYearFromDate} from "./reactUtils";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import React from "react";

export function getId(buchung) {
    return buchung.attributes.id;
}

export function getVorname(buchung) {
    return buchung.attributes.vorname;
}

export function getNachname(buchung) {
    return buchung.attributes.nachname;
}

export function getFullName(buchung) {
    return getVorname(buchung) + " " + getNachname(buchung);
}

export function getSeason(buchung) {
    let anreise = buchung.attributes.anreise;
    let season = getYearFromDate(anreise);
    return season;
}

export function getApartmentId(buchung) {
    return buchung.attributes.apartmentFK;
}

export function getAnzahlTage(buchung) {
    let anreise = getAnreiseMoment(buchung);
    let abreise = getAbreiseMoment(buchung);
    return abreise.diff(anreise, "days");
}

export function getAnreiseMoment(buchung) {
    return buchung ? moment(buchung.attributes.anreise) : null;
}

export function getAbreiseMoment(buchung) {
    return buchung ? moment(buchung.attributes.abreise) : null;
}

export function getMietpreis(buchung) {
    return buchung.attributes.mietpreis;
}

export function getAnzahlungBisMoment(buchung) {
    return moment(buchung.attributes.anzahlungBis);
}

export function getRestzahlungBisMoment(buchung) {
    return moment(buchung.attributes.restzahlungBis);
}

export function isAnzahlungErhalten(buchung) {
    return buchung.attributes.anzahlungErhalten;
}

export function isRestzahlungErhalten(buchung) {
    return buchung.attributes.restzahlungErhalten;
}

export function getZahlungsstatusText(buchung) {
    let anzahlungErhalten = isAnzahlungErhalten(buchung);
    let restzahlungErhalten = isRestzahlungErhalten(buchung);

    if(anzahlungErhalten && restzahlungErhalten)
        return <Box><Icon style={{color:"#2e7d32"}}>done_all</Icon> Vollständig bezahlt</Box>;
    if(anzahlungErhalten)
        return <Box><Icon style={{color: "#81c784"}}>done</Icon> Anzahlung erhalten</Box>;
    if(restzahlungErhalten)
        return <Box><Icon style={{color: "#fb8c00"}}>report</Icon> Restzahlung erhalten, Anzahlung nicht</Box>;

    return <Box><Icon style={{color: "#ef5350"}}>highlight_off</Icon> Zahlung ausstehend</Box>;
}

export function hasOccurrences(buchung) {
    return buchung.attributes.occurrences.length > 0;
}

export function getKommentar(buchung) {
    return buchung.attributes.kommentar;
}

export function getMonthYearTextString(buchung) {
    return getAnreiseMoment(buchung).format("MMMM YYYY");
}

module.exports = {
    getId,
    getVorname,
    getNachname,
    getFullName,
    getSeason,
    getApartmentId,
    getAnzahlTage,
    getAnreiseMoment,
    getAbreiseMoment,
    getMietpreis,
    getAnzahlungBisMoment,
    getRestzahlungBisMoment,
    isAnzahlungErhalten,
    isRestzahlungErhalten,
    getZahlungsstatusText,
    getKommentar,
    hasOccurrences,
    getMonthYearTextString
}