import {computed, observable, toJS} from "mobx";

export default class BookingComputedFieldsCalculationObject {
    @observable booking = null;
    @observable fieldNames = [
        "totalPriceExclDeposit",
        "totalPriceInclDeposit",
        "finalPaymentRent",
        "finalPaymentTotal"
    ];

    calc(predicate) {
        if(this.booking == null)
            return null;
        let booking = this.booking;
        return predicate(booking);
    }

    @computed get totalPriceExclDeposit() {
        return this.calc(booking => booking.mietpreis + booking.endreinigung + booking.touristensteuer);
    }

    @computed get totalPriceInclDeposit() {
        return this.calc(booking => this.totalPriceExclDeposit + booking.kaution);
    }

    @computed get finalPaymentRent() {
        return this.calc(booking => booking.mietpreis - booking.anzahlung);
    }

    @computed get finalPaymentTotal() {
        return this.calc(booking => this.totalPriceInclDeposit - booking.anzahlung);
    }

}