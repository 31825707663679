import React from "react";
import {getAbreiseMoment, getAnreiseMoment, getKommentar} from "../buchungUtils";
import {reactBackslashNToLineBreak} from "../reactUtils";
import BuchungOverviewCard, {createData} from "./buchungOverviewCard";
import {observer} from "mobx-react";

@observer
export default class CancelledBuchungOverviewCard extends React.Component {
    render() {
        let buchung = this.props.buchung;
        let buchungAttr = buchung.attributes;

        let apartmentsStore = this.props.stores.apartmentsStore;
        let apartment = apartmentsStore.allItems.find(apartment => apartment.attributes.id === buchungAttr.apartmentFK);
        let apartmentName = apartment != null ? apartment.attributes.name : "Wird geladen...";

        const dataRows = [
            createData(
                "Ferienwohnung",
                apartmentName,
                true
            ),
            createData(
                "Reisezeitraum",
                getAnreiseMoment(buchung).format("L") + " - " + getAbreiseMoment(buchung).format("L"),
                true
            ),
            createData(
                "Kommentar",
                reactBackslashNToLineBreak(getKommentar(buchung)),
                getKommentar(buchung) !== ""
            )
        ];

        return (
            <BuchungOverviewCard
                background="#ffebee"
                buchung={this.props.buchung}
                dataRows={dataRows}
            />
        );
    }
}