import {autorun, computed, observable, toJS} from "mobx";
import {getAbreiseMoment, getAnreiseMoment, getFullName, getNachname} from "../buchungUtils";
import moment from "moment";

function createStandardEvent(start, end, title, color, params = {}) {
    return {
        start: start,
        end: end,
        allDay: true,
        title: title,
        color: color,
        ...params
    };
}

export default class CalendarEventStore {
    constructor(stores) {
        this.stores = stores;
        autorun(() => {
            let apartments = stores.apartmentsStore.allItems;
            if(!this.apartments && apartments.length > 0)
                this.apartments = apartments.map(apartment => apartment.attributes.id);
        });
    }

    @observable apartments = null;
    @observable showHolidays = false;

    @computed get bookingEvents() {
        let bookingsStore = this.stores.bookingsStore;
        let events = [];
        let bookings = bookingsStore.allItems;
        let apartments = this.apartments ? this.apartments : [];
        bookings = bookings.filter(booking => apartments.includes(booking.attributes.apartmentFK));
        bookings = bookings.filter(booking => !booking.attributes.hasCancellation);
        bookings.forEach(booking => {
            let apartmentParams = this.getApartmentParams(booking.attributes.apartmentFK);

            let bookingEvent = createStandardEvent(
                getAnreiseMoment(booking),
                getAbreiseMoment(booking).add(1, "day"),
                apartmentParams.shortName + getFullName(booking),
                apartmentParams.color,
                {
                    bookingId: booking.attributes.id
                }
            );

            if(booking.attributes.extendOneDayForCleaning) {
                const dayAfterAbreise = getAbreiseMoment(booking).add(1, "day");
                let cleaningEvent = createStandardEvent(
                    dayAfterAbreise,
                    dayAfterAbreise,
                    apartmentParams.shortName + " Reinigung " + getNachname(booking),
                    apartmentParams.color,
                    {
                        bookingId: booking.attributes.id,
                        striped: true
                    }
                );
                events.push(cleaningEvent);
            }

            events.push(bookingEvent);
        });
        return events;
    }

    @computed get blockageEvents() {
        let blockagesStore = this.stores.blockagesStore;
        let events = [];
        let blockages = blockagesStore.allItems;
        let apartments = this.apartments ? this.apartments : [];
        blockages = blockages.filter(blockage => apartments.includes(blockage.attributes.apartmentFK));
        blockages.forEach(blockage => {
            let apartmentParams = this.getApartmentParams(blockage.attributes.apartmentFK);
            let event = createStandardEvent(
                moment(blockage.attributes.beginning),
                moment(blockage.attributes.end).add(1, "day"),
                apartmentParams.shortName + blockage.attributes.title,
                apartmentParams.color,
                {
                    blockageId: blockage.attributes.id,
                    striped: true
                }
            );
            events.push(event);
        });
        return events;
    }

    @computed get holidayEvents() {
        if(!this.showHolidays)
            return [];

        let holidayEventsStore = this.stores.holidayEvents;
        let holidayEvents = holidayEventsStore.allItems;
        let events = holidayEvents.map(holidayEvent => {
            return createStandardEvent(
                moment(holidayEvent.start),
                moment(holidayEvent.end),
                holidayEvent.name,
                "#5C6BC0"
            );
        });
        return events;
    }

    @computed get events() {
        let events = [];
        events.push(...this.bookingEvents);
        events.push(...this.blockageEvents);
        events.push(...this.holidayEvents);
        return events;
    }

    getApartmentParams(apartmentId) {
        let apartment = this.stores.apartmentsStore.getModelById(apartmentId);
        let ret = {
            color: "grey",
            shortName: ""
        };
        if(apartment) {
            ret.color = apartment.attributes.color;
            ret.shortName = apartment.attributes.shortName + " - ";
        }
        return ret;
    }

}