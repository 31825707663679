import $ from "jquery";
import {observable} from "mobx";

export default class ModelCollectionManagerBase {

    @observable data = null;

    constructor(providerFilePath, providerData, commandFilePath, commandData) {
        this.providerFilePath = providerFilePath;
        this.providerData = providerData;
        this.commandFilePath = commandFilePath;
        this.commandData = commandData;
        this.data = null;
        this.errorMsg = null;
        this.descriptions = null;
        this.dependants = [];
        this.loadValues();
    }

    //OBSERVERS

    addDependant(element, columnName, currentValue) {
        this.dependants.push(element);
    }

    removeDependant(element) {
        let index = this.dependants.indexOf(element);
        if (index > -1) {
            this.dependants.splice(index, 1);
        }
    }

    forceNotification() {
        this.notifyDependants();
    }

    notifyDependants() {
        this.dependants.forEach(function(element) {
            element.onModelUpdate();
        });
    }

    getData() {
        return this.data;
    }

    //DATA LOADER

    loadValues(callback) {
        let self = this;
        $.ajax({
            method: "GET",
            url: this.providerFilePath,
            data: this.providerData
        })
        .done(function (data) {
            let dataResult;
            try {
                dataResult = JSON.parse(data);
                self.errorMsg = null;
            } catch(err) {
                dataResult = null;
                self.errorMsg = data;
            }
            self.handleProviderRequestData(dataResult);
            self.notifyDependants();
            if(callback != null)
                callback(dataResult);
        });
    }

    refresh(callback) {
        this.loadValues(callback);
    }

    hasError() {
        return this.errorMsg !== null;
    }

    getError() {
        return this.errorMsg;
    }

    handleProviderRequestData(data) {}
}