import React from "react";
import Box from "@material-ui/core/Box";
import MieteinnahmenPerMonthChart from "../charts/mieteinnahmenPerMonthChart";
import ChartWrapperCard from "../charts/chartWrapperCard";
import MieteinnahmenPerYearChart from "../charts/mieteinnahmenPerYearChart";
import AveragePricePerDayPerMonthChart from "../charts/averagePricePerDayPerMonthChart";
import BookingMonthChart from "../charts/bookingMonthChart";
import LoadingIndicator from "../LoadingIndicator";
import {observer} from "mobx-react";
import {toJS} from "mobx";

@observer
export default class GlobalStatistics extends React.Component {
    render() {
        let statisticsStore = this.props.statisticsStore;

        let globalChartsData = statisticsStore.globalCharts;
        if(globalChartsData.isLoading || globalChartsData.error)
            return <LoadingIndicator/>;

        let mieteinnahmenPerMonthChartData = toJS(globalChartsData.data["mieteinnahmenPerMonthChart"]);
        let mieteinnahmenPerYearChartData = toJS(globalChartsData.data["mieteinnahmenPerYearChart"]);
        let averagePricePerDayPerMonthChartData = toJS(globalChartsData.data["averagePricePerDayPerMonthChart"]);
        let bookingMonthChartData = toJS(globalChartsData.data["bookingMonthChart"]);

        return (
            <Box>
                <ChartWrapperCard>
                    <MieteinnahmenPerMonthChart data={mieteinnahmenPerMonthChartData}/>
                </ChartWrapperCard>
                <ChartWrapperCard>
                    <MieteinnahmenPerYearChart data={mieteinnahmenPerYearChartData}/>
                </ChartWrapperCard>
                <ChartWrapperCard>
                    <AveragePricePerDayPerMonthChart data={averagePricePerDayPerMonthChartData}/>
                </ChartWrapperCard>
                <ChartWrapperCard>
                    <BookingMonthChart data={bookingMonthChartData}/>
                </ChartWrapperCard>
            </Box>
        );
    }
}