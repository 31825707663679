import * as React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class BasedataControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
                value: event.target.value
            },
            this.notify
        );
    }

    notify() {
        if(typeof this.props.onChange !== "undefined")
            this.props.onChange(this.state.value);
    }

    componentDidMount() {
        if(typeof this.props.preset !== undefined)
            this.setState({
                    value: this.props.preset
                },
                this.notify
            );
    }

}

export class BasedataSelectionControl extends BasedataControl {

    constructor(props) {
        super(props);
    }

    render() {

        let usedValue = this.state.value; //Prevent React warning: unknown item in <select>
        if(this.props.items !== null && this.props.items.find((item) => item[0] === usedValue) === undefined)
            usedValue = "";

        return (
            <Box style={{margin: "6px"}}>
                <FormControl variant={"outlined"} fullWidth>
                    <InputLabel>{ this.props.title }</InputLabel>
                    <Select
                        onChange={this.handleChange}
                        fullWidth
                        value={usedValue}
                    >
                        {
                            this.props.items !== null &&
                            this.props.items.map(
                                item =>
                                    <MenuItem value={item[0]} key={item[0]}>{item[1]}</MenuItem>
                            )

                        }
                    </Select>
                </FormControl>
            </Box>
        );
    }

}

export class BasedataCheckboxControl extends BasedataControl {

    constructor(props) {
        super(props);
    }

    handleChange(event) {
        this.setState({
                value: event.target.checked
            },
            this.notify
        );
    }

    render() {
        return (
            <Box style={{margin: "6px", height: this.props.height}}>
                <FormControlLabel
                    style={{height: this.props.height}}
                    control={
                        <Checkbox
                            checked={this.state.value || false}
                            onChange={this.handleChange}
                        />
                    }
                    label={this.props.title}
                />
            </Box>
        );
    }

}

module.exports = {
    BasedataSelectionControl,
    BasedataCheckboxControl
}