import React from "react";
import ReactDOM from 'react-dom'
import {HashRouter, Route, Switch} from 'react-router-dom';
import ResponsiveDrawer from "./drawer";
import LoginPage from "./loginPage/loginForm";
import $ from "jquery";
import ViewPage from "./viewPage/viewPage";
import BuchungDetailsPageContent from "./detailsPage/buchungDetailsPage";
import ReservationCreationPage from "./inputPage/ReservationCreationPage";
import StatisticsPage from "./statisticsPage/statisticsPage";
import ProfilePage from "./profilePage/profilePage";
import LoginStateManager from "./js/src/LoginStateManager";
import {ToastContainer} from "react-toastify";
import ConfigurationPage from "./configurationPage/configurationPage";
import BookingsStore from "./store/BookingsStore";
import ApartmentsStore from "./store/ApartmentsStore";
import FieldDescriptionsStore from "./store/FieldDescriptionsStore";
import UsersStore from "./store/UsersStore";
import ApartmentConfigsStore from "./store/ApartmentConfigsStore";
import AccountingPage from "./accounting/AccountingPage";
import ExpensesStore from "./store/ExpensesStore";
import DocumentGenerationStore from "./store/DocumentGenerationStore";
import DocumentsPage from "./documentsPage/DocumentsPage";
import CalendarPage from "./calendarPage/CalendarPage";
import BlockagesStore from "./store/BlockagesStore";
import BlockageDetailsPage from "./blockageDetailsPage/BlockageDetailsPage";
import MailAccountsStore from "./store/MailAccountsStore";
import StoresManager from "./store/StoresManager";
import ConfigurationStore from "./store/ConfigurationStore";
import OccurrenceRulesStore from "./store/OccurrenceRulesStore";
import OccurrencePage from "./occurrencePage/OccurrencePage";
import HolidayEventsStore from "./store/HolidayEventsStore";
import {observer} from "mobx-react";
import LoadingIndicator from "./LoadingIndicator";
import {gotoPage} from "./reactUtils";


export default observer(class App extends React.Component {


    storesManager = new StoresManager();

    constructor(props) {
        super(props);

        LoginStateManager.init();
        this.loginStateManager = LoginStateManager.getInstance();

        this.setTitle = this.setTitle.bind(this);
    }


    setTitle(title) {
        window.document.title = title;
    }

    render() {
        const loginInfoAvailable = this.loginStateManager.loginInfoAvailable;
        const isLoggedIn = this.loginStateManager.isLoggedIn;

        return (
            <React.Fragment>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <HashRouter>
                    {!loginInfoAvailable && <LoadingIndicator>Waiting for login info...</LoadingIndicator>}
                    {loginInfoAvailable && !isLoggedIn && <LoginPage stores={this.storesManager.stores}/>}
                    {loginInfoAvailable && isLoggedIn && <DrawerRoot stores={this.storesManager.stores}/>}
                </HashRouter>
            </React.Fragment>
        );
    }
})

class DrawerRoot extends React.Component {

    constructor(props) {
        super(props);
        this.registerStores();
    }

    registerStores() {
        const registerStore = (name, store) => this.props.stores.manager.registerStore(name, store);
        registerStore("apartmentsStore", new ApartmentsStore());
        registerStore("bookingsStore", new BookingsStore());
        registerStore("users", new UsersStore());
        registerStore("fieldDescriptions", new FieldDescriptionsStore());
        registerStore("apartmentConfigsStore", new ApartmentConfigsStore());
        registerStore("expenses", new ExpensesStore());
        registerStore("documentGenerationStore", new DocumentGenerationStore());
        registerStore("blockagesStore", new BlockagesStore());
        registerStore("mailAccountsStore", new MailAccountsStore());
        registerStore("occurrenceRulesStore", new OccurrenceRulesStore());
        registerStore("holidayEvents", new HolidayEventsStore());
    }

    componentDidMount() {
        //this.registerStores();
    }

    render() {
        let stores = this.props.stores;
        return (
            <ResponsiveDrawer>
                {
                    (drawer) =>
                        <Switch>
                            <Route exact path='/details' render={(props) => <BuchungDetailsPageContent {...props} drawer={drawer}/>}/>
                            <Route exact path='/' render={(props) => <ViewPage {...props} drawer={drawer} stores={stores} />}/>
                            <Route path='/calendar' render={(props) => <CalendarPage {...props} drawer={drawer} stores={stores} />}/>
                            <Route path='/details/:buchungId' render={(props) => <BuchungDetailsPageContent {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/blockage/:id' render={(props) => <BlockageDetailsPage {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/create' render={(props) => <ReservationCreationPage {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/statistics' render={(props) => <StatisticsPage {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/accounting' render={(props) => <AccountingPage {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/documents' render={props => <DocumentsPage {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/profile' render={(props) => <ProfilePage {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/configuration' render={(props) => <ConfigurationPage {...props} drawer={drawer} stores={stores}/>}/>
                            <Route path='/occurrences' render={(props) => <OccurrencePage {...props} drawer={drawer} stores={stores}/>}/>
                        </Switch>
                }
            </ResponsiveDrawer>
        );
    }
}