import * as React from "react";
import TextEditControl from "./baseControls/TextEditControl";
import {observer} from "mobx-react";
import EditStoreStaticField from "./EditStoreStaticField";

@observer
export default class CancellationDateArrivalDaysDifferenceDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={
                    (es) => {
                        if(es.getValue("cancellationDate") != null)
                            return es.getValue("daysBetweenCancellationAndArrival") + " Tage";
                        else
                            return "Stornierungsdatum ausfüllen";
                    }
                }
                title={"Differenz Stornierungsdatum - Anreise"}
            />
        );
    }
}