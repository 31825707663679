import {initialStoreObject} from "./store";
import SimpleCollectionStore from "./SimpleCollectionStore";

export default class BlockagesStore extends SimpleCollectionStore {
    init() {
        this.data = initialStoreObject;
    }

    doFetch(parameters, afterLoadCallback) {
        this.fetch(
            this.data,
            "api/blockage/get.php",
            {},
            afterLoadCallback
        );
    }

}