import {toast} from "react-toastify";
import {callCreateBuchungCmd} from "../js/src/commands";
import {gotoPage} from "../reactUtils";

export default class BookingCreationMemorizer {
    memorize(data, bookingId, callback) {
        callCreateBuchungCmd(
            data,
            (result) => {
                let json = JSON.parse(result);
                if(json.id > 0) {
                    gotoPage("/#/details/" + json.id);
                } else {
                    toast.error('Fehler beim speichern: ' + json.msg);
                }
                return Promise.resolve();
            }
        );
    }
}