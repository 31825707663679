import moment from "moment";
import {autorun} from "mobx";
import AutofillObject from "./AutofillObject";

export default class BlockageAutofillObject extends AutofillObject{

    doAutofill(modifiedField, constantsUpdated = false) {
        //Abreise
        if(modifiedField === "beginning") {
            if(this.getValue("end") == null) {
                let beginning = this.getValue("beginning");
                let beginningMoment = beginning !== null ? moment(beginning) : null;
                if(beginningMoment !== null)
                    this.setValue("end", beginningMoment.format("YYYY-MM-DD"));
            }
        }

    }

}