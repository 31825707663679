import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

export default class LoadingIndicator extends React.Component {
    render() {
        return (
            <Box width={1} align={"center"} my={4}>
                <CircularProgress />
            </Box>
        );
    }
}