import {initialStoreObject} from "./store";
import SimpleCollectionStore from "./SimpleCollectionStore";

export default class DocumentGenerationStore extends SimpleCollectionStore {
    init() {
        super.init();
        this.data = initialStoreObject;
    }

    doFetch(parameters, afterLoadCallback) {
        super.doFetch(parameters, afterLoadCallback);
        this.fetch(
            this.data,
            "db/providers/documentGenerationProvider.php",
            {},
            afterLoadCallback
        );
    }
}