import React from "react";
import SelectEditControl from "./baseControls/SelectEditControl";
import {observer} from "mobx-react";
import {compareAlphabetically} from "../JSStringUtils";

@observer
export default class ApartmentsSelectionControl extends React.Component {
    render() {
        let stores = this.props.stores;
        let apartmentsStore = stores.apartmentsStore;
        let apartments = apartmentsStore.allItems;
        let selectableValues = [];
        apartments.forEach(apartment => selectableValues.push([apartment.attributes.id, apartment.attributes.name]));
        selectableValues.sort((a,b) => compareAlphabetically(a[1], b[1]));
        return (
            <SelectEditControl {...this.props} selectableValues={selectableValues} />
        );
    }
}