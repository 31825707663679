import {initialStoreObject} from "./store";
import SimpleCollectionStore from "./SimpleCollectionStore";

export default class UsersStore extends SimpleCollectionStore {

    init() {
        super.init();
        this.data = initialStoreObject;
    }

    doFetch(parameters) {
        super.doFetch(parameters);
        this.fetch(
            this.data,
            "db/providers/usersProvider.php",
            {}
        );
    }

}