import React from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import {getUniqueKey} from "./reactUtils";
import ListSubheader from "@material-ui/core/ListSubheader";

export default class OccurrenceList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        if(this.props.occurrences != null)
            return (
                <Paper>
                    <List
                        subheader={
                            <ListSubheader>Ereignisse</ListSubheader>
                        }
                    >
                        {
                            this.props.occurrences.map(occurrence =>
                                <OccurrenceListItem key={getUniqueKey()} occurrence={occurrence}/>
                            )
                        }
                    </List>
                </Paper>
            );
        else
            return null;
    }

}

class OccurrenceListItem extends React.Component {
    render() {
        let occurrence = this.props.occurrence.attributes;
        return (
            <ListItem>
                <ListItemIcon>
                    <OccurrenceIcon type={occurrence.type}/>
                </ListItemIcon>
                <ListItemText>
                    {occurrence.content}
                </ListItemText>
            </ListItem>
        );
    }
}

class OccurrenceIcon extends React.Component {
    render() {
        let iconName = '';
        let iconColor = '';
        switch(this.props.type) {
            case "INFO":
                iconName = "info";
                iconColor = "#000000";
                break;
            case "JOB":
                iconName = "work";
                iconColor = "#000000";
                break;
            case "ALERT":
                iconName = "report";
                iconColor = "#f57c00";
                break;
            case "WARNING":
                iconName = "warning";
                iconColor = "#F44336";
                break;
            default:
                iconName = "info";
                iconColor = "#000000";
                break;
        }
        return (
            <Icon fontSize="large" style={{color: iconColor}}>{iconName}</Icon>
        );
    }
}