import {initialStoreObject} from "./store";
import SimpleCollectionStore from "./SimpleCollectionStore";
import {computedFn} from "mobx-utils";

export default class FieldDescriptionsStore extends SimpleCollectionStore {
    constructor() {
        super();
        this.data = initialStoreObject;
        this.fetch(
            this.data,
            "db/providers/fieldDescriptionsProvider.php",
            {}
        );
    }

    getModelByTableAndColumnName = computedFn(function getModelByTableAndColumnName(tableName, columnName) {
        return this.findByPredicate(element => element.attributes.tableName === tableName && element.attributes.columnName === columnName);
    });

}