import * as React from "react";
import TextEditControl from "./baseControls/TextEditControl";
import {observer} from "mobx-react";
import EditStoreStaticField from "./EditStoreStaticField";

@observer
export default class TravelDurationDisplayControl extends React.Component {
    render() {
        return (
            <EditStoreStaticField
                component={TextEditControl}
                {...this.props}
                valueGetter={(es) => es.getValue("numDays") + " Tage"}
                title={"Reisedauer"}
            />
        );
    }
}