import * as React from "react";
import {observer} from "mobx-react";
import MaterialTable from "material-table";
import MaterialTableGermanLocalization from "../localization/MaterialTableGermanLocalization";
import PageTitleManager from "../js/src/pageTitleManager";
import EditDocumentDialog from "./EditDocumentDialog";
import EditStore from "../store/EditStore";
import DocumentGeneratorMemorizer from "../memorizer/DocumentGeneratorMemorizer";

export default observer(class DocumentsPage extends React.Component {

    constructor(props) {
        super(props);
        if(!this.state) this.state = {
            editDocumentDialogOpen: false
        };

        this.editStore = new EditStore();
        this.editStore.simpleCollectionStore = this.props.stores.documentGenerationStore;
        this.editStore.memorizer = new DocumentGeneratorMemorizer();
    }

    componentDidMount() {
        this.props.drawer.setPageTitle("Dokumentengenerierung");
        PageTitleManager.getInstance().setTitle("Dokumentengenerierung");
        this.props.drawer.setToolbarItems(null);

        this.setState({
            editDocumentDialogOpen: false
        });

    }

    render() {
        let apartmentsStore = this.props.stores.apartmentsStore;
        let documents = this.props.stores.documentGenerationStore.allItems;
        let tableItems = documents.map(document => {
            let ret = document.attributes;
            let apartment = apartmentsStore.getModelById(document.attributes.apartmentFK);
            ret.apartmentName = apartment ? apartment.attributes.name : "";
            return ret;
        });

        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Apartment', field: 'apartmentName', type: 'string', defaultGroupOrder: 0},
                        { title: 'Dokument', field: 'name', type: 'string', defaultSort: "asc"}
                        ]}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Dokument bearbeiten',
                            onClick: (event, rowData) => {
                                let documentId = rowData.id;
                                this.editStore.modelId = documentId;
                                this.editStore.startEditing();
                                this.setState({
                                    editDocumentDialogOpen: true
                                });

                                let documentsStore = this.props.stores.documentGenerationStore;
                                let apartmentsStore = this.props.stores.apartmentsStore;
                                let pageTitle = "";
                                let document = documentsStore.getModelById(documentId);
                                pageTitle += apartmentsStore.getModelById(document?.attributes.apartmentFK)?.attributes.shortName ?? "";
                                pageTitle += " ";
                                pageTitle += document?.attributes.name ?? "";
                                PageTitleManager.getInstance().setTitleTemp(pageTitle);

                            }
                        }
                    ]}
                    options={{
                        grouping: false,
                        defaultExpanded: true,
                        paging: false
                    }}
                    data={tableItems}
                    title="Dokumente"
                    localization={MaterialTableGermanLocalization}
                />
                <EditDocumentDialog
                    onClose={() => { PageTitleManager.getInstance().resetTitle(); }}
                    stores={this.props.stores}
                    editStore={this.editStore}
                />
            </React.Fragment>
        );
    }
})