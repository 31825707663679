import ModelManager from "./modelManager";

export default class ConfigurationManager extends ModelManager {

    constructor(apartmentId) {
        super(
            "db/providers/apartmentConfigProvider.php",
            {
                apartment: apartmentId
            },
            null,
            null
        );

        this.setApartment = this.setApartment.bind(this);
    }

    setApartment(apartmentId, callback) {
        this.providerData.apartment = apartmentId;
        this.loadValues(callback);
    }

    getAsNameValuePair() {
        let ret = {};
        if(this.data != null) {
            this.data.forEach(element => ret[element.name] = element.value);
        }
        return ret;
    }

    getConfigsByApartment() {
        let apartmentId = this.providerData.apartment;
        return this.getData().filter(data => data.apartmentFK === apartmentId);
    }

    getValue(constantName) {
        if(this.data != null) {
            let findResult = this.data.find(element => element.name === constantName);
            return findResult != null ? findResult.value : null;
        }
        return null;
    }


}