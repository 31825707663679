import {computed} from "mobx";
import DynamicStore from "./DynamicStore";

export default class ConfigurationStore extends DynamicStore{
    constructor(initialData) {
        super(initialData);
    }

    @computed get data() {
        return this.dynamicModel;
    }
}