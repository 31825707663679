import * as React from "react";
import {observer} from "mobx-react";

@observer
export default class EditStoreInputField extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        let editStore = this.props.editStore;
        let stores = this.props.stores;
        let fieldDescriptionsStore = stores.fieldDescriptions;

        let tableName = this.props.tableName;
        let fieldName = this.props.fieldName;
        let fieldDescription = fieldDescriptionsStore.getModelByTableAndColumnName(tableName, fieldName);
        let title = fieldDescription != null ? fieldDescription.attributes.title : this.props.title != null ? this.props.title : fieldName;
        let tooltip = fieldDescription != null ? fieldDescription.attributes.tooltip : "";
        let TagName = this.props.component;

        let errorObject = editStore.getErrorObject(fieldName);
        let errorState = errorObject != null ? errorObject.state : false;
        let errorMsg = errorObject != null ? errorObject.msg : "";

        let permanentInputMode = this.props.permanentInputMode;
        let initialRegisterDefaultValue = permanentInputMode;

        return (
            <TagName
                title={title}
                value={editStore.getValue(fieldName)}
                onValueChange={this.handleChange}
                onEnter={() => editStore.stopEditingAndSave()}
                onEsc={() => editStore.stopEditingAndDiscard()}
                edit={editStore.inEdit}
                error={errorState}
                helperText={errorMsg}
                permanentInputMode={permanentInputMode}
                initialRegisterDefaultValue={initialRegisterDefaultValue}
                onInteraction={() => editStore.registerInteraction(fieldName)}
                tooltip={tooltip}
                {...this.props}
            />
        );
    }

    handleChange(value) {
        let editStore = this.props.editStore;
        let fieldName = this.props.fieldName;

        editStore.modifyField(fieldName, value);
    }

}