export default class PageTitleManager {
    static instance = new PageTitleManager();
    oldTitle = "";

    static getInstance() {
        return this.instance;
    }

    setTitle(title) {
        this.oldTitle = title;
        window.document.title = title;
    }

    setTitleTemp(title, saveCurrentAsOldTitle = false) {
        if(saveCurrentAsOldTitle)
            this.oldTitle = window.document.title;
        window.document.title = title;
    }

    resetTitle() {
        window.document.title = this.oldTitle;
    }

}