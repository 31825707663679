import * as React from "react";
import {observer} from "mobx-react";
import PageTitleManager from "../js/src/pageTitleManager";
import CalendarEventStore from "./CalendarEventStore";
import moment from "moment";
import {Calendar, momentLocalizer} from "react-big-calendar";
import CalendarDetailsDialog from "./CalendarDetailsDialog";
import ApartmentsSelectionControl from "../form/ApartmentsSelectionControl";
import ShowHolidaysSwitchControl from "../form/ShowHolidaysSwitchControl";
import ToolbarRefreshButton from "../ToolbarRefreshButton";

require("react-big-calendar/lib/css/react-big-calendar.css");

export default observer(class CalendarPage extends React.Component {

    constructor(props) {
        super(props);
        this.calendarEventStore = new CalendarEventStore(props.stores);

        this.eventPropGetter = this.eventPropGetter.bind(this);
        this.onSelectEvent = this.onSelectEvent.bind(this);
    }

    componentDidMount() {
        this.props.drawer.setPageTitle("Buchungskalender");
        PageTitleManager.getInstance().setTitle("Buchungskalender");

        this.props.drawer.setToolbarItems(<ToolbarRefreshButton stores={this.props.stores} />);
        this.setState({
            dialogBookingId: null,
            dialogBlockageId: null,
            dialogAnchor: null
        });
    }

    render() {
        const localizer = momentLocalizer(moment);
        let dialogBookingId = this.state ? this.state.dialogBookingId : null;
        let dialogBlockageId = this.state ? this.state.dialogBlockageId : null;

        return (
            <React.Fragment>
                    <ApartmentsSelectionControl
                        title={"Ferienwohnungen auswählen"}
                        stores={this.props.stores}
                        onValueChange={val => this.calendarEventStore.apartments = val}
                        value={this.calendarEventStore.apartments || []}
                        multiple
                        edit
                    />
                    <ShowHolidaysSwitchControl
                        calendarEventStore={this.calendarEventStore}
                    />
                    <Calendar
                        localizer={localizer}
                        events={this.calendarEventStore.events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ marginTop: "2em", minHeight: "600px" }}
                        views={["month"]}
                        toolbar={true}
                        eventPropGetter={this.eventPropGetter}
                        onSelectEvent={this.onSelectEvent}
                        popup
                    />
                <CalendarDetailsDialog
                    bookingId={dialogBookingId}
                    blockageId={dialogBlockageId}
                    stores={this.props.stores}
                    onClose={() => {
                        this.setState({dialogBookingId: null, dialogBlockageId: null});
                    }}
                    anchor={this.state ? this.state.dialogAnchor : null}
                />
            </React.Fragment>
        );
    }

    eventPropGetter(event, start, end, isSelected) {
        let color = event.color;
        return {
            style: {
                backgroundColor: color,
                backgroundImage: event.striped ? "repeating-linear-gradient(45deg, #878787 0px, #878787 10px, transparent 10px, transparent 20px, #878787 20px)" : ""
            }
        };
    }

    onSelectEvent(calEvent, event) {
        this.setState({
            dialogBookingId: calEvent.bookingId || null,
            dialogBlockageId: calEvent.blockageId || null,
            dialogAnchor: event.currentTarget
        });
    }

})