import * as React from "react";
import {observer} from "mobx-react";
import MaterialTable from "material-table";
import MaterialTableGermanLocalization from "../localization/MaterialTableGermanLocalization";
import PageTitleManager from "./../js/src/pageTitleManager";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import CreateExpenseDialog from "./CreateExpenseDialog";

@observer
export default class AccountingPage extends React.Component {

    constructor() {
        super();
        this.state = {
            createViewOpen: false
        };
    }

    componentDidMount() {
        this.props.drawer.setPageTitle("Buchhaltung");
        PageTitleManager.getInstance().setTitle("Buchhaltung");

        this.props.drawer.setToolbarItems(() => {
            return (
                <IconButton
                    onClick={() => {
                        this.setState({createViewOpen: true});
                    }}
                    style={{color: "white"}}
                >
                    <Icon>add</Icon>
                </IconButton>
            );
        });

        this.setState({
            createViewOpen: false
        });

    }

    render() {

        let expenses = this.props.stores.expenses.allItems;
        let tableItems = expenses.map(expense => {
            return expense.attributes;
        });

        return (
            <React.Fragment>
                <div>
                    <MaterialTable
                        columns={[
                            { title: 'Datum', field: 'date', type: 'date', defaultSort: "desc"},
                            { title: 'Betrag', field: 'amount', type: 'currency', currencySetting: {locale: "de-DE", currencyCode: "EUR"} },
                            { title: 'Name', field: 'name' },
                            { title: 'Beschreibung', field: 'description' },
                            { title: 'Investition', field: 'investment', type: 'boolean' }
                        ]}
                        data={tableItems}
                        title="Ausgaben"
                        localization={MaterialTableGermanLocalization}
                    />
                </div>

                <CreateExpenseDialog
                    open={this.state.createViewOpen}
                    onClose={() => this.setState({createViewOpen: false})}
                    stores={this.props.stores}
                />
            </React.Fragment>
        );
    }
}