import {CollectionManager} from "./collectionManager";

export default class AccessibleUsersManager extends CollectionManager {

    constructor() {
        super(
            "db/providers/accessibleUsersProvider.php",
            null,
            "",
            null
        );
    }

}