import React from "react";
import SelectEditControl from "./baseControls/SelectEditControl";
import {observer} from "mobx-react";
import {compareAlphabetically} from "../JSStringUtils";
import SwitchControl from "./baseControls/SwitchControl";
import ApartmentsSelectionControl from "./ApartmentsSelectionControl";

@observer
export default class ShowHolidaysSwitchControl extends React.Component {
    render() {
        let calendarEventStore = this.props.calendarEventStore;

        return (
            <SwitchControl
                title="Ferien und Feiertage anzeigen"
                onValueChange={val => calendarEventStore.showHolidays = val}
                value={calendarEventStore.showHolidays}
            />
        );
    }
}